import { writable } from "svelte/store";

export const snackbar = writable({ message: "", type: "success", isVisible: false });

export function showSnackbar(message, type = "success", duration = 3000) {
  snackbar.set({ message, type, isVisible: true });

  setTimeout(() => {
    snackbar.set({ message: "", type, isVisible: false });
  }, duration);
}
