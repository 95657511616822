<script>
  import QuillEditor from "../lib/components/QuillEditor.svelte";
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import { auth } from "../lib/stores/auth";
  import { get } from "svelte/store";
  export let params;
  let articleId;

  // Editor Logic
  const authState = get(auth);
  const editor = authState.isAuthenticated && authState.user.editor;
  let editInProgress = false;
  let successMessage = false;

  $: {
    articleId = params.articleId;
  }

  /**
   * @type {any[]}
   */
  let wiki_entry = {};

  let searchText = "";

  function handleSearch() {
    if (searchText) {
      push(`/lore/search/${searchText}/1`);
    }
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      handleSearch();
    }
  }

  function editArticle() {
    editInProgress = true;
  }

  function handleQuillClose(articleSaved) {
    editInProgress = false;
    if (articleSaved) {
      showSuccessMessage();
    }
  }

  function showSuccessMessage() {
    successMessage = true;
    setTimeout(() => {
      successMessage = false;
    }, 2000);
  }

  onMount(async () => {
    setTimeout(() => {
      successMessage = false;
    }, 0);
    try {
      const response = await fetch(`/api/wiki-entries/${articleId}`);
      if (response.ok) {
        const data = await response.json();
        wiki_entry = data;
      } else {
        console.error("failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  });
</script>

<slot>
  <div class="lore-page">
    <div class="lore-sidebar">
      <button on:click={() => push("/lore/category/general/1")}
        ><span class="tooltip">General</span><img
          src="media/images/GeneralBook.png"
          alt="Book 1"
        /></button
      >
      <button on:click={() => push("/lore/category/cosmology/1")}
        ><span class="tooltip">Cosmology</span><img
          src="media/images/CosmologyBook.png"
          alt="Book 1"
        /></button
      >
      <button on:click={() => push("/lore/category/realms/1")}
        ><span class="tooltip">Realms</span><img
          src="media/images/RealmsBook.png"
          alt="Book 1"
        /></button
      >
    </div>
    <div class="lore-content">
      <div class="row lore-sidebar-small" style="display: none;">
        <button on:click={() => push("/lore/category/general/1")}
          ><span class="tooltip">General</span><img
            src="media/images/GeneralBook.png"
            alt="Book 1"
          /></button
        >
        <button on:click={() => push("/lore/category/cosmology/1")}
          ><span class="tooltip">Cosmology</span><img
            src="media/images/CosmologyBook.png"
            alt="Book 1"
          /></button
        >
        <button on:click={() => push("/lore/category/realms/1")}
          ><span class="tooltip">Realms</span><img
            src="media/images/RealmsBook.png"
            alt="Book 1"
          /></button
        >
      </div>
      <div class="row lore-sidebar-small" style="display: none;">
        <button on:click={() => push("/lore/category/storylines/1")}
          ><span class="tooltip">Storylines</span><img
            src="media/images/StorylinesBook.png"
            alt="Book 1"
          /></button
        >
        <button on:click={() => push("/lore/category/items/1")}
          ><span class="tooltip">Items</span><img
            src="media/images/ItemsBook.png"
            alt="Book 1"
          /></button
        >
        <button on:click={() => push("/lore/category/bestiary/1")}
          ><span class="tooltip">Bestiary</span><img
            src="media/images/BestiaryBook.png"
            alt="Book 1"
          /></button
        >
      </div>
      <div class="search-container">
        <input
          type="text"
          placeholder="Search..."
          bind:value={searchText}
          on:keydown={handleKeyPress}
        />
        <button on:click={handleSearch} class="search-button">Go</button>
      </div>
      <div class="central-column">
        {#if editInProgress}
          <div class="quil-container">
            <QuillEditor
              bind:content={wiki_entry.content}
              onClose={handleQuillClose}
              id={articleId}
            />
          </div>
        {:else}
          <div class="col-left">
            <h1>
              {wiki_entry.title}
              {#if editor}
                <button class="pencil" on:click={() => editArticle()}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25Z"
                      fill="white"
                    />
                    <path
                      d="M20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L14.85 5.4L18.6 9.15L20.71 7.04Z"
                      fill="green"
                    />
                  </svg>
                </button>{/if}
            </h1>
            <p>
              {@html wiki_entry.content}
            </p>
          </div>
          <div class="col-right">
            {#if wiki_entry.image}
              <div class="image-container">
                <img src={wiki_entry.image} alt="Article Portrait" />
              </div>
            {/if}
            <div class="info-container">
              <div class="category-pip {wiki_entry.category}">
                {wiki_entry.category}
              </div>
              <!-- <div class="info-box">
                <p>Characteristics</p>
              </div> -->
            </div>
          </div>
        {/if}
      </div>
    </div>
    <div class="lore-sidebar">
      <button on:click={() => push("/lore/category/storylines/1")}
        ><span class="tooltip">Storylines</span><img
          src="media/images/StorylinesBook.png"
          alt="Book 1"
        /></button
      >
      <button on:click={() => push("/lore/category/items/1")}
        ><span class="tooltip">Items</span><img
          src="media/images/ItemsBook.png"
          alt="Book 1"
        /></button
      >
      <button on:click={() => push("/lore/category/bestiary/1")}
        ><span class="tooltip">Bestiary</span><img
          src="media/images/BestiaryBook.png"
          alt="Book 1"
        /></button
      >
    </div>
  </div>
  {#if successMessage}
    <div class="success-message active">Artical Successfully Saved!</div>
  {/if}
</slot>

<style>
  .lore-page {
    display: flex;
    justify-content: space-between;
    font-family: "Acme", sans-serif; /* Applied font globally */
  }

  .lore-sidebar {
    flex: 0.5;
    width: 10%;
    margin-top: 160px;
  }

  .success-message {
    background-color: #4caf50; /* Green */
    color: white;
    text-align: center;
    padding: 10px;
    position: fixed;
    top: 25%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%);
    z-index: 9999;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 1s ease;
  }

  .success-message.active {
    opacity: 1;
  }

  button {
    display: block;
    border: none;
    background: none;
    cursor: pointer;
    max-height: 400px;
    max-width: 250px;
    transition: transform 0.3s ease; /* Added transition for button hover effect */
  }

  button:hover {
    transform: scale(1.05); /* Slightly enlarge button on hover */
  }

  button img {
    max-height: 220%;
    max-width: 220%;
    margin-left: -55%;
  }

  .pencil {
    display: inline;
  }

  .lore-content {
    flex: 3;
    width: 80%;
    margin-top: 100px;
  }

  input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2); /* Subtle border for inputs */
    border-radius: 5px; /* Rounded corners for input */
    font-family: "Acme", sans-serif;
  }

  .search-button {
    border: none;
    background: #ff7a00;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;
    border-radius: 5px; /* Added rounded corners */
    transition: background 0.3s ease; /* Smooth transition on hover */
  }

  .search-button:hover {
    background: #ff6600;
  }

  .search-container {
    display: flex;
    border-radius: 25px;
    overflow: hidden;
    background-color: rgba(
      255,
      255,
      255,
      0.9
    ); /* Slightly less transparent for better visibility */
    margin-bottom: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }

  .search-container input {
    margin-bottom: 0px;
  }

  .central-column {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: rgba(215, 181, 148, 0.9); /* Base background color */
    border: 5px solid rgba(188, 151, 96, 1);
    background-image: url("/media/images/papertexture.jpeg"); /* Texture image URL */
    background-size: cover; /* Ensures the texture covers the entire area */
    background-position: center 10%;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
    position: relative; /* Positioning context for overlay */
    color: #333; /* Base text color */
  }

  .central-column::before {
    content: "";
    position: absolute; /* Overlay covers the entire column */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Darker semi-transparent overlay */
    border-radius: 15px; /* Match border radius */
    z-index: 0; /* Place behind text */
  }

  .col-left {
    width: 75%;
    text-align: center;
    position: relative; /* Positioning context for text */
    z-index: 1; /* Ensure text is above the overlay */
  }

  .col-left h1 {
    font-size: 28px; /* Increased font size for headings */
    margin-bottom: 20px;
    color: #fff; /* White text for headers */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Subtle shadow for headers */
  }

  .col-left p {
    font-size: 18px; /* Increased font size for paragraphs */
    text-align: left;
    color: #fff; /* White text for paragraphs */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Subtle shadow for paragraphs */
  }

  .col-right {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
  }

  .image-container img {
    max-width: 150px;
    border-radius: 10px;
  }

  .category-pip {
    background-color: rgb(110, 19, 75);
    color: white;
    border-radius: 10px;
    padding: 5px 10px;
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .cosmology {
    background-color: rgb(109, 4, 141);
  }

  .realms {
    background-color: rgb(196, 3, 3);
  }

  .storylines {
    background-color: green;
  }

  .items {
    background-color: rgb(0, 0, 119);
  }

  .bestiary {
    background-color: black;
  }

  .info-box {
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    padding: 10px;
    text-align: center;
  }

  .tooltip {
    visibility: hidden;
    width: 120px;
    margin-left: 20px;
    background-color: #1f0620;
    color: #fff;
    text-align: center;
    border-radius: 50px;
    padding: 5px;
    position: absolute;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s;
  }

  button:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  @media (max-width: 1050px) {
    /* Add responsive styles here if needed */
  }

  @media (max-width: 900px) {
    /* Add responsive styles here if needed */
  }

  @media (max-width: 768px) {
    /* Add responsive styles here if needed */
  }

  @media (max-width: 568px) {
    .central-column {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .col-left {
      width: 100%;
      text-align: center;
    }

    .image-container img {
      max-width: 300px;
      border-radius: 10px;
    }

    .category-pip {
      border-radius: 10px;
      width: 270px;
      padding: 5px 10px;
      text-align: center;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    .lore-sidebar {
      display: none !important;
    }

    .lore-column {
      margin: 0px;
      padding: 0px;
    }

    .img-background {
      display: none !important;
    }

    .lore-links {
      flex-direction: column;
    }

    .lore-sidebar-small {
      display: flex !important;
      flex-direction: row;
    }

    .search-container {
      margin-top: 10px;
    }
  }
</style>
