<div class="lore-text">
  This file contains all known information on the White Baron.
</div>

<div class="dossier">
  <div class="dossier-header">
    <h1>Dossier: White Baron</h1>

    <div class="portrait">
      <img
        src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167494/placeholder_lzsl1l.gif"
        alt="White Baron Portrait"
      />
    </div>
  </div>

  <div class="dossier-section">
    <h2>Profile</h2>
    <p>
      <strong>Name:</strong> The White Baron<br />
      <strong>Age:</strong> 38<br />
      <strong>Appearance:</strong> Tall, charming, and handsome with flowing blond
      hair, a golden voice, and a regal demeanor that commands attention and admiration.
    </p>
    <p>
      The White Baron is the public face of the Barons, using charm and charisma
      to hide ruthless business practices. As the dominant force in
      import/export industries and airship production, the White Baron controls
      vital trade routes and wields significant influence over commerce. Known
      for hosting extravagant galas and charity events, they maintain a façade
      of benevolence while engaging in exploitation and financial manipulation.
    </p>
  </div>

  <div class="dossier-section">
    <h2>Public Enterprises</h2>
    <p>
      Though publicly lauded, the White Baron’s businesses conceal sinister
      operations:
    </p>
    <ul>
      <li>
        <strong>Skyrise Airships:</strong> A pioneering airship production company,
        monopolizing airborne trade and transport with cutting-edge technology.
      </li>
      <li>
        <strong>Whitehall Imports:</strong> A vast import/export conglomerate leveraging
        foreign labor under exploitative conditions to maximize profits.
      </li>
      <li>
        <strong>Societal Opulence:</strong> Hosts of lavish galas, balls, and charity
        events that serve as opportunities for networking, manipulation, and securing
        political alliances.
      </li>
    </ul>
  </div>

  <div class="dossier-section">
    <h2>Criminal Activities</h2>
    <p>
      Beneath their polished exterior lies a trail of deceit and exploitation:
    </p>
    <ul>
      <li>
        Embezzled funds from charity events to finance private ventures,
        including secret airship projects.
      </li>
      <li>
        Exploited foreign workers in their import/export operations, using harsh
        conditions and deceptive contracts to maintain low costs.
      </li>
      <li>
        Manipulated trade routes and shipping logistics to undercut competitors
        and enforce monopolies.
      </li>
      <li>
        Leveraged their social events to collect intelligence and blackmail
        political and business rivals.
      </li>
    </ul>
  </div>

  <div class="dossier-section">
    <h2>Current Objectives</h2>
    <p>
      The following actions are critical to undermining the White Baron’s
      control:
    </p>
    <ul>
      <li>
        <strong>Uncover:</strong> Financial documents at a charity gala to prove
        embezzlement.<br />
      </li>
      <li>
        <strong>Sabotage:</strong> The production line of their airships to
        disrupt their shipping dominance.<br />
      </li>
      <li>
        <strong>Expose:</strong> The exploitation of foreign workers in their
        import/export network to incite investor outrage.<br />
      </li>
    </ul>
  </div>
</div>

<style>
  * {
    box-sizing: border-box;
  }

  .lore-text {
    padding: 30px;
    color: white;
    text-align: center;
    font-family: "Courier New", Courier, monospace;
  }

  .dossier {
    background-color: #111;
    padding: 20px;
    color: #fff;
    font-family: "Courier New", Courier, monospace;
    border: 1px solid #444;
    max-width: 800px;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  }

  .dossier-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #444;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .dossier-header h1 {
    font-size: 1.5em;
    color: #fff;
  }

  .portrait {
    width: 120px;
    height: 120px;
    border: 2px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
  }

  .portrait img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform: scale(1.1);
  }

  .dossier-section {
    margin-bottom: 20px;
  }

  .dossier-section h2 {
    color: #fff;
    font-size: 1.2em;
    border-bottom: 1px solid #444;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  .dossier-section p,
  .dossier-section ul {
    margin: 10px 0;
  }

  .dossier-section ul {
    list-style-type: none;
    padding-left: 0;
  }

  .dossier-section ul li::before {
    content: "• ";
    color: #fff;
  }

  @media (max-width: 768px) {
    .dossier-header {
      flex-direction: column;
      align-items: center;
    }
    .portrait {
      margin-top: 10px;
    }
  }
</style>
