<script>
  import EditQuestModal from "../lib/components/EditQuestModal.svelte";
  import NewQuestModal from "../lib/components/NewQuestModal.svelte";
  import { onMount } from "svelte";
  import { auth } from "../lib/stores/auth";
  import { get } from "svelte/store";
  let tab = "loreBackground";

  // Data Logic
  let event_quests = [];
  let character_quests = [];
  let side_quests = [];
  let completed_quests = [];

  // Accordion Logic
  let showEventQuests = true;
  let showCharacterQuests = false;
  let showSideQuests = false;
  let showCompletedQuests = false;

  // Editor Logic
  const authState = get(auth);
  const dm = authState.isAuthenticated && authState.user?.dm;
  const username = authState.user?.username;

  let editInProgress = false;
  let newInProgress = false;
  let selectedQuest = {};

  function toggleEventQuests() {
    showEventQuests = !showEventQuests;
  }

  function toggleCharacterQuests() {
    showCharacterQuests = !showCharacterQuests;
  }

  function toggleSideQuests() {
    showSideQuests = !showSideQuests;
  }

  function toggleCompletedQuests() {
    showCompletedQuests = !showCompletedQuests;
  }

  function changeImage(newSrc) {
    tab = newSrc;

    if (newSrc === "loreBackground" || newSrc === "questlogBackground") {
      document.querySelector(".page-container").style.height = "200%";
    } else {
      document.querySelector(".page-container").style.height = "95vh";
    }
  }

  async function fetchQuests() {
    try {
      const response = await fetch(`/api/quests`);
      if (response.ok) {
        const quest_data = await response.json();
        event_quests = quest_data.filter((quest) => quest.category === "event");
        character_quests = quest_data.filter(
          (quest) => quest.category === "character"
        );
        side_quests = quest_data.filter((quest) => quest.category === "side");
        completed_quests = quest_data.filter(
          (quest) => quest.category === "complete"
        );
      } else {
        console.error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  }

  function newQuest() {
    newInProgress = true;
  }

  function editQuest(quest) {
    selectedQuest = quest;
    editInProgress = true;
  }

  function closeEditModal() {
    editInProgress = false;
    newInProgress = false;
    selectedQuest = {};
  }

  onMount(() => {
    document.querySelector(".page-container").style.height = "200%";
    fetchQuests();
  });
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<slot>
  <div class="page-container">
    <div class="sidebar">
      <div class="circle-gap"></div>
      <button
        class="circle-button blue-border"
        on:click={() => changeImage("loreBackground")}
      >
        Lore
      </button>
      <button
        class="circle-button green-border"
        on:click={() => changeImage("media/images/Eres.png")}
      >
        Map
      </button>
      <button
        class="circle-button orange-border"
        on:click={() => changeImage("questlogBackground")}
      >
        Quest Log
      </button>
    </div>
    <div class="content-container">
      {#if tab === "media/images/Eres.png"}
        <img src={tab} alt="Eres" class="map-image" />
      {:else if tab === "loreBackground"}
        <div class="lore-text">
          <p class="disclaimer">
            Disclaimer: Welcome to our high magic fantasy tabletop RPG! We
            strive to create a fun and inclusive environment for everyone. If
            you have any specific phobias, triggers, or content concerns that
            the Dungeon Master should be aware of, please don't hesitate to
            reach out to me (Chris Mckenzie '@chrisalterradm') directly. We will
            do our best to accommodate your needs and inform you of any
            potential issues in advance to ensure a comfortable and enjoyable
            experience for all players.
          </p>
          <h3>Previously on Eres</h3>
          <p>
            Eres, once home to four powerful city-states collectively known as
            the Four Nations, it is now a war torn landscape ravaged by the
            armies of the divine. In the center of the Four Nations stands the
            small trading town of Akiba. A diplomatic hub that has quickly grown
            to be a burgeoning city-state in its own right and the last
            sanctuary of peace and security for those fleeing the destruction of
            their homes. It is here that the denizens of Eres made their stand
            against the onslaught of the War Gods.
          </p>
          <p>
            Now, after three years of war, the four Ragnar portals have closed,
            their guardians defeated, and the armies of the divine routed. With
            the crusades at an end, Eres finds itself in the eye of the storm
            and a moment of peace has swept the realm. Its people are eager to
            reclaim lost lands, unbury ancient birthrights, and resume the
            business of living.
          </p>
          <p>
            However, in the power vacuum of the war comes new threats. Undead
            have been reported in greater numbers within the deserts of Salarin,
            deadly plants and beasts have taken root in the forests around
            Highgarden, and cursed monstrosities have migrated south across the
            lands beyond Bloodraven. For territory to be reclaimed, trading
            routes re-established, and divine technologies salvaged, areas will
            need to be cleared of monsters. It's never been a more lucrative
            time to be an Adventurer for Hire!
          </p>
          <h4>Key Terms:</h4>
          <li>
            <b>Akiba.</b> The City of Heroes. Once the diplomatic and trading hub
            of the Four Nations. Akiba is now a thriving city-state home to the remnants
            of the fallen cities and towns. It is governed by the Council of Ten,
            a coalition made up of representatives from the major factions and organisations
            that support Akiba Society. Populations include a wide mix of races native
            to Eres, as well as exotic races from across the Realms.
          </li>
          <li>
            <b>Ragnar.</b> The Divine Plane of War, and home to the War Gods of the
            Mika Pantheon. The Ragnar Crusades were an invasion of reborn souls from
            the plane of War sent to ravage Eres for unknown reasons. The armines
            included legions of Blood Orcs, Moon Goblins, and other war-like races.
            As well as Ragnar Kaijus, colossal monsters that roam the lands of the
            divine plane.
          </li>
          <li>
            <b>Bloodraven.</b> The City of Mist. Once a thriving cobbled city of
            twisting alleyways and leaning tenements, Bloodraven was ruled over by
            Lord Black, an Ancient Black Dragon that took tithe from the population
            in return for protection over the Plains of Imanity, both from monsters
            crawling out of the Cursed Swamp, and from other other City-States seeking
            new resources, even from Dragonborn tribes of the northern Sunrise Expanse.
            The City fell during the Ragnar Crusade when an Ooze Kaiju broke from
            the city's center, enslaving any that came in contact with it, even the
            great and mightly Lord Black. Since the Ooze's defeat Lord Black has
            not been found. Populations include; Humans, Tieflings, and Goliaths,
            as well as Halflings further in the Plains.
          </li>
          <li>
            <b>Salarin.</b> The City of Magic. A bastion of flying islands, arcane
            winds, and ancient treasure, Salarin sits at the edge of the Salarin
            Desert and was ruled over by the Royal Fire Genasi Family when it was
            uncovered that the ruling Queen was in league with the Cult of Fire &
            Lightning and was responsible for the murder of the previous Queen, her
            sister, and the rest of the royal family. The City fell during the Ragnar
            Crusade when a flying Kaiju emerged over the city. In an emergency act,
            the Great Houses of Salarin evacuated the citizens of Salarin to several
            of the floating islands and teleported them north, setting them down
            on the banks of the Aesthma River south of Akiba, creating the outer
            Akiba district known as Magic's Fall, as well as the formation of Akiba's
            Arcane Academy. Populations include; Humans, Dragonborns, Kobolds, and
            Lizardfolks further in the Desert.
          </li>
          <li>
            <b>Highgarden & Undergarden.</b> The City of Elves. Before the war Highgarden
            maintained an isolationist policy, interacting and trading with other
            peoples on external territories only. Non-Elves were not permitted within
            the dual cities of Highgarden and Undergarden. The City fell during the
            Ragnar Crusade when a borrowing Kaiju tore through the twin cities, forcing
            the Elves to abandon their previously held stand on intimate cooperation
            with other races. The most notable addition to Akiba society is the introduction
            of the Iodine Syndicate, an Elvish Organisation composed of elite Medical
            Professionals from the Great Elven Houses. Their knowledge of biology
            and the arcane healing arts are said to rival even the power of the divine.
            Populations include; Elves (High, Dark, and Wood).
          </li>
          <li>
            <b>Steamforge.</b> The City of Steam. Home to the steampunk Dwarves and
            Gnomes, Steamforge is built within the interior of an active volcano,
            and utilises a comprehensive system of water pipes to fuel their vast
            metal industry. Ruled by the Royal Dwarven Families, Steamforge was the
            only city to not directly fall during the beginning of the Ragnar Crusade.
            Instead a Metal Kaiju appeared from the Scrapyard Pass and sieged the
            mountain city. While many families evauted to Akiba for safey, many stayed
            behind to continue outputing the precious resources needed to supply
            the defence forces of Akiba. Populations include; Dwarves and Gnomes.
          </li>
          <li>
            <b>The Murder Isles.</b> Home of the Kenku, the Murder Isles are a series
            of large towns and villages scattered across islands that are known for
            their famed Bardic Colleges and the schools of Hip Hop, Rap, and Rock
            and Roll. The Isles were unaffected by the Ragnar Crusade and maintained
            their distance during the three year war.
          </li>
          <li>
            <b>The Karndar Mountains.</b> Home to the Grey Cyberpunk Dwarves and
            the Kingdom of Karndar. Not a lot is known of the Karndar Mountains.
            The Karndar have kept a hostile distance from the southern lands, and
            have often been at war against Steamforge over the last several centuries.
            Before the Ragnar Crusade, a temporary peace was achieved when a group
            of adventurers in league with Steamforge discovered and offered a cure
            to Karndar for an arcane plague that had swept the kingdom. Nothing has
            been heard from the Kingdom since the beginning of the Crusade.
          </li>
          <li>
            <b>The Sunrise Expanse.</b> Not much is known of these distant lands,
            only that it is home to roaming tribes of Dragonborn and is said to have
            a City of Crystal, Lightray, that shines in the morning light. The land
            is home to roaming tribes of Dragonborn.
          </li>
          <li>
            <b>The Council of Ten.</b> The governing body of Akiba, the Council of
            Ten is composed of representatives from each of the major factions and
            coalition that operate the city and was formed during the Ragnar Crusade
            after a massive influx to the population.
          </li>
          <li>
            <b>The Dragonfruit Barons.</b> A large economic faction in Eres, the
            Dragonfruit Barons are an oligarchy of wealthly and powerful individuals
            that started out in the Dragonfruit trade, only to expand to several
            other industries. They currently hold the majority vote in Akiba's Economic
            Department.
          </li>
          <li>
            <b>B.S. Die Right.</b> (Otherwise known as Mr Brightside) is a mysterious
            and cryptic individual who only recently appeared in Eres and has been
            working with the Council of Ten on the Anchorage Station Project; A fortress
            like structure to facilitate anchored planar travel for adventurers and
            other powerful individuals. Brightside appears to be interested in expanding
            their economic interests into Eres, and has come into conflict with the
            existing powerholders, in particular the Dragonfruit Barons.
          </li>
          <li>
            <b>Gaian Royal Family.</b> Steamforge is governed by a coaliation of
            Dwarven Clans and their respective High Chiefs. Among the Chiefs, Chief
            Arcan is the current acting King and head of the Gaian Royal Family.
            A title that all other High Chiefs have refused to remove from him, no
            matter how much he protests. The King's daughter, Princess Nadia Arcan,
            is a well established and famed artificer who is spearheading the development
            of Eres' first Railway between Steamforge and Akiba.
          </li>
          <li>
            <b>Gibblet.</b> James to provide summary.
          </li>
          <li>
            <b>Lycaster</b> James to provide summary.
          </li>
        </div>
      {:else if tab === "questlogBackground"}
        <div class="quest-text">
          <h3>Quest Board</h3>

          {#if dm}
            <button
              on:click={() => newQuest()}
              class={editInProgress || newInProgress
                ? "edit-disabled"
                : "edit-button"}
              disabled={editInProgress || newInProgress}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-plus"
              >
                <path d="M12 5v14M5 12h14" />
              </svg></button
            >
          {/if}

          <div class="accordion">
            <h4 on:click={toggleEventQuests}>
              Event Quests[{event_quests.length}]
            </h4>
            {#if showEventQuests}
              {#each event_quests as quest}
                <div class="questcard">
                  <h4>
                    {quest.title}{#if dm && username === quest?.username}
                      <button
                        on:click={() => editQuest(quest)}
                        class={editInProgress || newInProgress
                          ? "edit-disabled"
                          : "edit-button"}
                        disabled={editInProgress || newInProgress}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "white"}
                          />
                          <path
                            d="M20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L14.85 5.4L18.6 9.15L20.71 7.04Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "green"}
                          />
                        </svg>
                      </button>{/if}
                  </h4>
                  <p><b>Game Master: </b>{quest?.username}</p>
                  <p><b>Sponsor: </b>{quest?.sponsor}</p>
                  <p><b>Summary: </b>{quest?.summary}</p>
                  <p><b>Reward: </b>{quest?.reward}</p>
                  <p>
                    <b>Gameplay Tags: </b>
                    {quest.gameplay_tags && quest.gameplay_tags.length > 0
                      ? quest.gameplay_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Location Tags: </b>
                    {quest.location_tags && quest.location_tags.length > 0
                      ? quest.location_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Character Tags: </b>
                    {quest.character_tags && quest.character_tags.length > 0
                      ? quest.character_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Theme Tags: </b>
                    {quest.theme_tags && quest.theme_tags.length > 0
                      ? quest.theme_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p><b>DM Inspiration: </b>{quest?.inspiration}</p>
                </div>
              {/each}
            {/if}
          </div>

          <div class="accordion">
            <h4 on:click={toggleCharacterQuests}>
              Character Quests [{character_quests.length}]
            </h4>
            {#if showCharacterQuests}
              {#each character_quests as quest}
                <div class="questcard">
                  <h4>
                    {quest.title}{#if dm && username === quest?.username}
                      <button
                        on:click={() => editQuest(quest)}
                        class={editInProgress || newInProgress
                          ? "edit-disabled"
                          : "edit-button"}
                        disabled={editInProgress || newInProgress}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "white"}
                          />
                          <path
                            d="M20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L14.85 5.4L18.6 9.15L20.71 7.04Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "green"}
                          />
                        </svg>
                      </button>{/if}
                  </h4>
                  <p><b>Game Master: </b>{quest?.username}</p>
                  <p><b>Sponsor: </b>{quest?.sponsor}</p>
                  <p><b>Summary: </b>{quest?.summary}</p>
                  <p><b>Reward: </b>{quest?.reward}</p>
                  <p>
                    <b>Gameplay Tags: </b>
                    {quest.gameplay_tags && quest.gameplay_tags.length > 0
                      ? quest.gameplay_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Location Tags: </b>
                    {quest.location_tags && quest.location_tags.length > 0
                      ? quest.location_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Character Tags: </b>
                    {quest.character_tags && quest.character_tags.length > 0
                      ? quest.character_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Theme Tags: </b>
                    {quest.theme_tags && quest.theme_tags.length > 0
                      ? quest.theme_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p><b>DM Inspiration: </b>{quest?.inspiration}</p>
                </div>
              {/each}
            {/if}
          </div>

          <div class="accordion">
            <h4 on:click={toggleSideQuests}>
              Side Quests [{side_quests.length}]
            </h4>
            {#if showSideQuests}
              {#each side_quests as quest}
                <div class="questcard">
                  <h4>
                    {quest.title}{#if dm && username === quest?.username}
                      <button
                        on:click={() => editQuest(quest)}
                        class={editInProgress || newInProgress
                          ? "edit-disabled"
                          : "edit-button"}
                        disabled={editInProgress || newInProgress}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "white"}
                          />
                          <path
                            d="M20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L14.85 5.4L18.6 9.15L20.71 7.04Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "green"}
                          />
                        </svg>
                      </button>{/if}
                  </h4>
                  <p><b>Game Master: </b>{quest?.username}</p>
                  <p><b>Sponsor: </b>{quest?.sponsor}</p>
                  <p><b>Summary: </b>{quest?.summary}</p>
                  <p><b>Reward: </b>{quest?.reward}</p>
                  <p>
                    <b>Gameplay Tags: </b>
                    {quest.gameplay_tags && quest.gameplay_tags.length > 0
                      ? quest.gameplay_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Location Tags: </b>
                    {quest.location_tags && quest.location_tags.length > 0
                      ? quest.location_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Character Tags: </b>
                    {quest.character_tags && quest.character_tags.length > 0
                      ? quest.character_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Theme Tags: </b>
                    {quest.theme_tags && quest.theme_tags.length > 0
                      ? quest.theme_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p><b>DM Inspiration: </b>{quest?.inspiration}</p>
                </div>
              {/each}
            {/if}
          </div>

          <div class="accordion">
            <h4 on:click={toggleCompletedQuests}>
              Completed Quests [{completed_quests.length}]
            </h4>
            {#if showCompletedQuests}
              {#each completed_quests as quest}
                <div class="questcard">
                  <h4>
                    [Completed!] <s>{quest.title}</s
                    >{#if dm && username === quest?.username}
                      <button
                        on:click={() => editQuest(quest)}
                        class={editInProgress || newInProgress
                          ? "edit-disabled"
                          : "edit-button"}
                        disabled={editInProgress || newInProgress}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "white"}
                          />
                          <path
                            d="M20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L14.85 5.4L18.6 9.15L20.71 7.04Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "green"}
                          />
                        </svg>
                      </button>{/if}
                  </h4>
                  <p><b>Game Master: </b>{quest?.username}</p>
                  <p><b>Sponsor: </b>{quest?.sponsor}</p>
                  <p><b>Summary: </b>{quest?.summary}</p>
                  <p><b>Reward: </b>{quest?.reward}</p>
                  <p style="margin-top:10px"><b>Outcome: </b>{quest.outcome}</p>
                  <p><b>Classified: </b>{quest.classified}</p>
                  <p>
                    <b>Gameplay Tags: </b>
                    {quest.gameplay_tags && quest.gameplay_tags.length > 0
                      ? quest.gameplay_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Location Tags: </b>
                    {quest.location_tags && quest.location_tags.length > 0
                      ? quest.location_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Character Tags: </b>
                    {quest.character_tags && quest.character_tags.length > 0
                      ? quest.character_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Theme Tags: </b>
                    {quest.theme_tags && quest.theme_tags.length > 0
                      ? quest.theme_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p><b>DM Inspiration: </b>{quest?.inspiration}</p>
                </div>
              {/each}
            {/if}
          </div>
        </div>
      {/if}
    </div>
  </div>
  {#if selectedQuest.id}
    <EditQuestModal
      show={editInProgress}
      quest={selectedQuest}
      onClose={closeEditModal}
    />
  {/if}
  {#if newInProgress}
    <NewQuestModal show={newInProgress} onClose={closeEditModal} />
  {/if}
</slot>

<style>
  * {
    box-sizing: border-box;
  }

  .page-container {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    padding: 15px;
    height: 95vh;
  }

  .sidebar {
    width: 12%;
    box-sizing: border-box;
    padding: 20px;
    border: 10px solid #632d06;
    border-right: none;
    border-radius: 10px 0 0 10px;
    background-color: #2e1713;
  }

  .circle-gap {
    margin-top: 20px;
  }

  .circle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    border-radius: 50%;
    background-color: #282d30;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
  }

  /* .yellow-border {
    border: 2px solid #f1c40f;
  } */

  .orange-border {
    border: 2px solid orange;
  }

  .green-border {
    border: 2px solid #0ff116;
  }

  .blue-border {
    border: 2px solid #0f9af1;
  }

  .disclaimer {
    margin-bottom: 50px;
    color: lightblue;
  }

  .content-container {
    width: 100%;
    border: 10px solid #632d06;
    border-radius: 0 10px 10px 0;
    background: rgba(0, 0, 0, 0.453);
  }

  .content-container img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .lore-text {
    padding: 50px;
    color: white;
    text-align: center;
  }

  .lore-text p {
    text-align: justify;
  }

  .lore-text h4 {
    text-align: left;
    margin-top: 50px;
  }

  .lore-text li {
    text-align: left;
    margin-left: 15px;
    margin-bottom: 25px;
    list-style-type: none;
  }

  .quest-text {
    padding: 50px;
    color: white;
    text-align: center;
  }

  .quest-text h3 {
    margin-bottom: 40px;
  }

  .quest-text h4 {
    text-align: left;
    text-decoration: underline;
  }

  .questcard {
    color: white;
    text-align: center;
    background-color: rgba(36, 0, 47, 0.495);
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    text-align: justify;
    margin-bottom: 20px;
  }

  .edit-button {
    border: none;
    cursor: pointer;
    padding: auto;
    margin-left: 4px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: rgba(36, 0, 47, 0.495);
  }

  .edit-disabled {
    border: none;
    padding: auto;
    margin-left: 4px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: rgba(36, 0, 47, 0.495);
  }

  .edit-button:hover {
    background-color: #073b20;
  }

  @media (max-width: 768px) {
    .page-container {
      flex-direction: column;
    }

    .sidebar {
      width: 100%;
      padding: 10px;
      display: flex;
      border: none;
      background-color: transparent;
      justify-content: space-around;
    }

    .circle-button {
      height: 50px;
    }

    .content-container {
      border: none;
      background: transparent;
    }

    .lore-text,
    .quest-text {
      padding: 20px;
    }

    .lore-text h4,
    .quest-text h4 {
      margin-top: 20px;
    }

    /* .lore-text li,
    .quest-text li {
      margin-bottom: 15px;
    } */

    .quest-text h3 {
      margin-bottom: 20px;
    }

    .questcard {
      padding: 10px;
      margin-bottom: 10px;
    }
  }
</style>
