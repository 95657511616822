import { writable } from "svelte/store";

const isClient = typeof window !== 'undefined';

const initializeAuth = () => {
    const storedToken = isClient ? localStorage.getItem('authToken') : null;
    const storedUserDataString = isClient ? localStorage.getItem('userData') : null;
    const storedUserData = storedUserDataString ? JSON.parse(storedUserDataString) : null;
    return {
        isAuthenticated: !!storedToken,
        token: storedToken || null, 
        user: storedUserData,
    };
};

export const auth = writable(initializeAuth())

export const setAuthToken = (token, refresh, userProfile) => {
    if (isClient) {
        const currentTime = new Date().getTime(); // Current timestamp in milliseconds
        const accessTokenExpiration = currentTime + (24 * 60 * 60 * 1000); // Access token expires in 1 day (24 hours)
        const refreshTokenExpiration = currentTime + (7 * 24 * 60 * 60 * 1000); // Refresh token expires in 7 days


        localStorage.setItem('authToken', token);
        localStorage.setItem('refreshToken', refresh);
        localStorage.setItem('accessTokenExpiration', accessTokenExpiration);
        localStorage.setItem('refreshTokenExpiration', refreshTokenExpiration);
        localStorage.setItem('userData', JSON.stringify(userProfile));
        auth.set({ isAuthenticated: true, token, user: userProfile });
    }
};

export const clearAuth = () => {
    auth.set({ isAuthenticated: false, token: null, user: null });
    if (isClient) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('accessTokenExpiration')
        localStorage.removeItem('refreshTokenExpiration')
        localStorage.removeItem('userData');
    }
};

export const getValidAccessToken = async () => {
    if (isClient) {
        const storedToken = localStorage.getItem('authToken');
        const storedRefreshToken = localStorage.getItem('refreshToken');
        const accessTokenExpiration = parseInt(localStorage.getItem('accessTokenExpiration'));
        const refreshTokenExpiration = parseInt(localStorage.getItem('refreshTokenExpiration'));
        const currentTime = new Date().getTime(); 

        if (storedToken && currentTime < accessTokenExpiration) {
            return storedToken;
        }

        if (storedRefreshToken && currentTime < refreshTokenExpiration) {
            try {
                const refreshedToken = await refreshAuthToken(storedRefreshToken);
                const newAccessTokenExpiration = currentTime + (24 * 60 * 60 * 1000); // Update access token expiration (1 day)
                
                localStorage.setItem('authToken', refreshedToken);
                localStorage.setItem('accessTokenExpiration', newAccessTokenExpiration);

                return refreshedToken; 
            } catch (error) {
                console.error("Failed to refresh access token:", error);
                clearAuth();
                window.location.href = '#/signin';
                return null;
            }
        }

        clearAuth();
        window.location.href = '/signin';
        return null;
    } else {
        return null;
    }
};


export const refreshAuthToken = async (refreshToken) => {
    try {
        const response = await fetch('/api/token/refresh', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refresh: refreshToken }),
        });

        if (!response.ok) {
            throw new Error('Failed to refresh access token');
        }

        const data = await response.json();
        const newAccessToken = data.access;
        return newAccessToken;
    } catch (error) {
        throw new Error('Failed to refresh access token: ' + error.message);
    }
};
