<script>
  import * as api from "../../api";
  import { selectedCharacter } from "../../stores/character";
  import { showSnackbar } from "../../stores/snackbar";

  let selectedRecipeId = null;
  let selectedRecipe = null;

  $: selectedRecipe = $selectedCharacter?.inventory?.recipes.find(
    (recipe) => recipe.id === selectedRecipeId
  );

  async function craftItem() {
    if (!selectedRecipe) return;

    try {
      const characterId = $selectedCharacter.id;
      const requiredIngredients =
        selectedRecipe?.item_details?.ingredients || [];

      const ingredientsToRemove = requiredIngredients
        .map(({ reagent_name, quantity }) => {
          const inventoryItem = $selectedCharacter.inventory.reagents.find(
            (i) => i.item_name === reagent_name
          );

          if (!inventoryItem) {
            console.warn(`Missing reagent: ${reagent_name}`);
            return null;
          }

          return { id: inventoryItem.id, quantity };
        })
        .filter(Boolean);

      if (ingredientsToRemove.length !== requiredIngredients.length) {
        showSnackbar(
          `Missing required ingredients for ${selectedRecipe.item_name}!`,
          "error"
        );
        return;
      }

      const updatedInventory = await api.removeItems(
        ingredientsToRemove,
        characterId
      );

      if (!updatedInventory) {
        showSnackbar(
          `Failed to craft ${selectedRecipe.item_name}: Missing ingredients.`,
          "error"
        );
        return;
      }

      const finalInventory = await api.addItem(
        [{ id: selectedRecipe.object_id, model: "Items", quantity: 1 }],
        characterId
      );

      if (!finalInventory) {
        showSnackbar(
          `Failed to craft ${selectedRecipe.item_name}: Error adding to inventory.`,
          "error"
        );
        return;
      }

      $selectedCharacter.inventory = finalInventory;
      showSnackbar(
        `Successfully crafted ${selectedRecipe.item_name}!`,
        "success"
      );
    } catch (error) {
      console.error("Error crafting item:", error);
      showSnackbar(`Error crafting ${selectedRecipe.item_name}.`, "error");
    }
  }

  function hasEnough(reagent, requiredQty) {
    return reagent ? reagent.quantity >= requiredQty : false;
  }

  function getInventoryCount(reagent) {
    return reagent ? reagent.quantity : 0;
  }
</script>

<div class="crafting-container">
  <h2>Crafting</h2>

  {#if $selectedCharacter?.inventory?.recipes?.length > 0}
    <label for="recipe">Select a Recipe</label>
    <select id="recipe" class="dropdown" bind:value={selectedRecipeId}>
      <option value="" disabled selected>Choose a recipe</option>
      {#each $selectedCharacter?.inventory?.recipes as recipe}
        <option value={recipe.id}>{recipe.item_name}</option>
      {/each}
    </select>

    {#if selectedRecipe}
      <div class="ingredients-list">
        <h3>Required Ingredients:</h3>
        {#each selectedRecipe?.item_details?.ingredients as { reagent_name, quantity }}
          <div class="ingredient">
            <span>{reagent_name}:</span>
            <span
              class="inventory-count"
              style="color: {hasEnough(
                $selectedCharacter?.inventory?.reagents?.find(
                  (i) => i.item_name === reagent_name
                ),
                quantity
              )
                ? 'white'
                : 'red'}"
            >
              {getInventoryCount(
                $selectedCharacter?.inventory?.reagents?.find(
                  (i) => i.item_name === reagent_name
                )
              )}/{quantity}
            </span>
          </div>
        {/each}
      </div>

      <button
        class="craft-button"
        on:click={craftItem}
        disabled={!selectedRecipe?.item_details?.ingredients.every((ing) =>
          hasEnough(
            $selectedCharacter?.inventory?.reagents?.find(
              (i) => i.item_name === ing.reagent_name
            ),
            ing.quantity
          )
        )}
      >
        Craft {selectedRecipe?.item_name}
      </button>
    {/if}
  {:else}
    <p class="no-recipes">No Recipes Available for this Character</p>
  {/if}
</div>

<style>
  .crafting-container {
    text-align: center;
    padding: 20px;
    color: white;
    border-radius: 10px;
    width: 400px;
  }

  .dropdown {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }

  .ingredients-list {
    margin-top: 10px;
  }

  .ingredient {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    font-size: 1rem;
  }

  .craft-button {
    margin-top: 20px;
    padding: 10px 20px;
    background: #4caf50;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.1rem;
    border-radius: 5px;
  }

  .craft-button:disabled {
    background: #ccc;
    cursor: not-allowed;
  }

  .no-recipes {
    font-size: 1.2rem;
    color: #ff6666;
    margin-top: 20px;
  }
</style>
