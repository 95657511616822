<script>
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import { getValidAccessToken } from "../lib/stores/auth";
  import { auth } from "../lib/stores/auth";
  import { get } from "svelte/store";

  let authState;
  let characters = [];
  let selectedCharacter = null;

  // Lists
  let cultureList = [];
  let heritageList = [];
  let trainingList = [];
  let alignmentList = [];

  onMount(async () => {
    authState = get(auth);
    if (!authState.isAuthenticated) {
      push("/signin");
    } else {
      try {
        await fetchCultures();
        await fetchHeritages();
        await fetchTrainings();
        await fetchAlignments();
        characters = await fetchUserCharacters();
      } catch (error) {
        console.error("Error fetching characters:", error);
      }
    }
  });

  // Fetch cultures
  async function fetchCultures() {
    const response = await fetch("/api/cultures");
    const data = await response.json();
    cultureList = data;
  }

  // Fetch heritages
  async function fetchHeritages() {
    const response = await fetch("/api/heritages");
    const data = await response.json();
    heritageList = data;
  }

  // Fetch trainings
  async function fetchTrainings() {
    const response = await fetch("/api/trainings");
    const data = await response.json();
    trainingList = data;
  }

  // Fetch alignments
  async function fetchAlignments() {
    const response = await fetch("/api/alignments");
    const data = await response.json();
    alignmentList = data;
  }

  async function fetchUserCharacters() {
    const token = await getValidAccessToken();
    const response = await fetch(`/api/characters`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  }

  function getCultureNameById(id) {
    const culture = cultureList.find((item) => item.id === id);
    return culture ? culture.name : "Unknown Culture";
  }

  function getHeritageNameById(id) {
    const heritage = heritageList.find((item) => item.id === id);
    return heritage ? heritage.name : "Unknown Heritage";
  }

  function getTrainingNameById(id) {
    const training = trainingList.find((item) => item.id === id);
    return training ? training.name : "Unknown Training";
  }

  function getAlignmentNameById(id) {
    const alignment = alignmentList.find((item) => item.id === id);
    return alignment ? alignment.name : "Unknown Alignment";
  }

  function openCharacterModal(character) {
    selectedCharacter = character;
  }

  function closeCharacterModal() {
    selectedCharacter = null;
  }
</script>

<!-- Adventurer's License Section -->
<slot>
  <div class="account-page">
    <section class="license-container" aria-labelledby="license-heading">
      <h2 id="license-heading" class="license-header">Adventurer's License</h2>
      <div class="profile-info-grid">
        <label for="username">Username:</label>
        <p id="username">{authState?.user ? authState.user.username : ""}</p>

        <label for="email">Email:</label>
        <p id="email">{authState?.user ? authState.user.email : ""}</p>

        <label for="class">Class:</label>
        <p id="class">Adventurer</p>
      </div>
    </section>

    <!-- Characters Section -->
    <section class="character-list" aria-labelledby="character-heading">
      <h2 id="character-heading">Your Characters</h2>
      {#if characters.length > 0}
        <div class="character-grid">
          {#each characters as character (character.id)}
            <div class="character-card">
              <button
                on:click={() => openCharacterModal(character)}
                aria-label={`View details for ${character.name}`}
              >
                <img
                  src={character.image
                    ? character.image
                    : "/media/images/placeholder.png"}
                  alt={`Image of ${character.name}`}
                />
                <div class="badge-info">
                  <h3>{character.name}</h3>
                  <p>
                    {getHeritageNameById(character.heritage_1)} - {getHeritageNameById(
                      character.heritage_2
                    )}
                  </p>
                  <p>{getCultureNameById(character.culture)}</p>

                  <p>
                    {getTrainingNameById(character.training)} [{getAlignmentNameById(
                      character.alignment
                    )}]
                  </p>
                  <div class="character-stats">
                    <p class="stat-label">Level</p>
                    <p class="stat-value">{character.level}</p>
                    <p class="stat-label">EXP</p>
                    <p class="stat-value">{character.experience}</p>
                  </div>
                </div>
              </button>
            </div>
          {/each}
        </div>
      {:else}
        <p>No characters created yet.</p>
      {/if}

      {#if selectedCharacter}
        <div
          class="modal-overlay"
          role="dialog"
          aria-labelledby="modal-heading"
          on:click={closeCharacterModal}
          on:keydown={(event) =>
            event.key === "Escape" && closeCharacterModal()}
        >
          <div
            class="modal-content"
            on:click|stopPropagation
            on:keydown|stopPropagation
          >
            <div class="modal-header">
              <img
                class="portrait"
                src={selectedCharacter.image
                  ? selectedCharacter.image
                  : "/media/images/placeholder.png"}
                alt={`Portrait of ${selectedCharacter.name}`}
              />
              <h2 id="modal-heading" class="modal-heading">
                {selectedCharacter.name}
              </h2>
            </div>

            <div class="character-attributes">
              <p class="label"><strong>Culture:</strong></p>
              <p>{getCultureNameById(selectedCharacter.culture)}</p>

              <p class="label"><strong>Heritage:</strong></p>
              <p>
                {getHeritageNameById(selectedCharacter.heritage_1)} - {getHeritageNameById(
                  selectedCharacter.heritage_2
                )}
              </p>

              <p class="label"><strong>Training:</strong></p>
              <p>{getTrainingNameById(selectedCharacter.training)}</p>

              <p class="label"><strong>Alignment:</strong></p>
              <p>{getAlignmentNameById(selectedCharacter.alignment)}</p>

              <p class="label"><strong>Level:</strong></p>
              <p>{selectedCharacter.level}</p>

              <p class="label"><strong>EXP:</strong></p>
              <p>{selectedCharacter.experience}</p>

              <p class="label"><strong>Description:</strong></p>
              <p>
                {selectedCharacter.description
                  ? selectedCharacter.description
                  : ""}
              </p>
            </div>

            <!-- Inventory Section -->
            {#if selectedCharacter.inventory && selectedCharacter.inventory.length > 0}
              <div class="inventory-section">
                <h3>Inventory</h3>
                <div class="inventory-grid">
                  {#each selectedCharacter.inventory as item}
                    <div class="inventory-item">
                      <h4>{item.name}</h4>
                      <p><strong>Description:</strong> {item.description}</p>
                      <p><strong>Effect:</strong> {item.effect}</p>
                      <p>
                        <strong>Attunement:</strong>
                        {item.attunement ? "Required" : "Not Required"}
                      </p>
                    </div>
                  {/each}
                </div>
              </div>
            {/if}

            <button
              class="close-button"
              on:click={closeCharacterModal}
              on:keydown={(event) =>
                event.key === "Enter" && closeCharacterModal()}
              aria-label="Close character details"
            >
              Close
            </button>
          </div>
        </div>
      {/if}
    </section>
  </div>
</slot>

<style>
  .account-page {
    display: flex;
    min-height: 80vh;
  }

  .character-card {
    position: relative;
    width: 100%;
    max-width: 300px; /* or any size needed */
    overflow: hidden;
    background-color: #396c7d;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    transition:
      transform 0.2s ease,
      box-shadow 0.2s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    text-align: center;
    border: none;
  }

  .character-card button {
    cursor: pointer;
  }

  .character-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }

  .character-card img {
    max-width: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .character-stats {
    display: grid;
    grid-template-columns: 1fr auto 1fr auto; /* Aligns label-value pairs */
    align-items: center;
    padding: 8px 16px;
    background-color: #f4f4f4;
    border-top: 1px solid #ddd; /* Separates stats from the main card */
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); /* Embossed effect */
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .stat-label {
    color: #555;
    font-weight: bold;
    font-size: 0.85rem;
    margin: 0;
    text-align: left;
  }

  .stat-value {
    color: #222;
    font-size: 0.85rem;
    margin: 0;
    text-align: right;
    padding-left: 10px;
  }

  .character-card > :not(.character-stats) {
    padding-bottom: 8px;
  }

  .profile-info-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem 1rem;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px;
  }

  .profile-info-grid label {
    font-weight: bold;
    color: #333;
  }

  .profile-info-grid p {
    margin: 0;
    color: #555;
  }

  .license-header {
    font-size: 1.8rem;
    text-align: center;
    color: #333;
  }

  .license-container {
    margin-top: 50px;
    margin-left: 50px;
    width: 450px;
    height: 225px;
    padding: 15px;
    background-color: rgba(215, 181, 148, 0.9);
    border: 5px solid #bc9760;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    background-image: url("/media/images/papertexture.jpeg");
    background-size: cover;
    background-position: center;
  }

  label {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }

  p {
    margin: 5px 0;
    font-size: 14px;
    color: #444;
  }

  .character-list {
    margin-top: 50px;
    margin-left: 50px;
    text-align: center;
  }

  .character-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }

  .badge-info h3 {
    margin: 5px 0;
    font-size: 18px;
    color: #333;
  }

  .badge-info p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
  }

  /* Modal Overlay Styling */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .modal-content {
    background: #fff;
    width: 90%;
    max-width: 600px;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow-y: auto;
    max-height: 80vh;
  }

  .modal-header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }

  .portrait {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
  }

  .modal-heading {
    font-size: 1.5rem;
    color: #333;
    margin: 0;
  }

  .character-attributes {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px 15px;
    font-size: 0.95rem;
    color: #444;
    line-height: 1.5;
  }

  .label {
    font-weight: bold;
    color: #666;
  }

  /* Inventory Section */
  .inventory-section {
    margin-top: 20px;
  }

  .inventory-section h3 {
    font-size: 1.25rem;
    color: #333;
    margin-bottom: 10px;
  }

  .inventory-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .inventory-item {
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .inventory-item h4 {
    font-size: 1.1rem;
    margin: 0 0 8px 0;
    color: #222;
  }

  .inventory-item p {
    font-size: 0.9rem;
    margin: 4px 0;
    color: #555;
  }

  .close-button {
    background-color: #d9534f;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 1rem;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
    transition: background-color 0.2s ease;
  }

  .close-button:hover {
    background-color: #c9302c;
  }
</style>
