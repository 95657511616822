<script>
  import { auth, setAuthToken } from "../lib/stores/auth";
  import { get } from "svelte/store";
  import { push, link } from "svelte-spa-router";

  let username = "";
  let password = "";
  let rememberMe = false;
  let errorMessage = "";

  async function handleLogin() {
    const response = await fetch("/api/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      const token = data.access;
      const refresh = data.refresh;
      const userProfile = await getUserProfile(token);
      setAuthToken(token, refresh, userProfile);
      get(auth);
      push(`/account`);
    } else {
      errorMessage = "Login failed. Please check your credentials.";
    }
  }

  /**
   * @param {any} token
   */
  async function getUserProfile(token) {
    try {
      const response = await fetch("/api/profile", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const userProfileData = await response.json();
        return userProfileData;
      } else {
        console.error("Failed to fetch user profile data");
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }
</script>

<div class="login-container">
  <div class="login-box">
    <h2>Sign In</h2>
    {#if errorMessage}
      <p class="error-message">{errorMessage}</p>
    {/if}
    <div class="input-container">
      <label for="username">Username</label>
      <input
        id="username"
        type="text"
        name="username"
        autocomplete="username"
        bind:value={username}
        required
      />
    </div>
    <div class="input-container">
      <label for="password">Password</label>
      <input
        id="password"
        type="password"
        name="password"
        autocomplete="current-password"
        bind:value={password}
        required
      />
    </div>
    <button class="login-button" on:click={handleLogin}>Sign In</button>
    <div class="not-member">
      Not a member? <a href="/signup" use:link>Sign Up</a>
    </div>
  </div>
</div>

<style>
  /* Main container */
  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: 10px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  /* Login box styling */
  .login-box {
    color: black;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
  }

  .login-box h2 {
    font-size: 28px;
    font-family: "Acme", sans-serif;
  }

  /* Input field styling */
  .input-container {
    margin-top: 20px;
    text-align: left;
  }

  .input-container label {
    display: block;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .input-container input {
    width: 100%;
    padding: 12px;
    margin-top: 8px;
    border: 3px solid #000;
    border-radius: 30px;
    background-color: #f3f2f2;
    font-family: "Acme", sans-serif;
    font-size: 16px;
  }

  /* Error message styling */
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }

  /* Button styling */
  .login-button {
    width: 100%;
    background-color: #0078d4;
    color: white;
    padding: 16px 20px;
    border: none;
    border-radius: 50px;
    margin-top: 30px;
    cursor: pointer;
    font-family: "Acme", sans-serif;
    font-size: 16px;
    font-weight: bold;
  }

  .login-button:hover {
    background-color: #005daf;
  }

  /* "Not a member" text */
  .not-member {
    text-align: center;
    margin-top: 15px;
  }

  .not-member a {
    text-decoration: none;
    color: #0078d4;
  }

  /* Mobile responsiveness */
  @media (max-width: 480px) {
    .login-box h2 {
      font-size: 24px;
    }

    .input-container input {
      padding: 14px;
      font-size: 14px;
    }

    .login-button {
      font-size: 16px;
      padding: 14px;
    }

    .error-message {
      font-size: 12px;
    }

    .not-member {
      font-size: 14px;
    }
  }
</style>
