<script>
  import { onMount } from "svelte";
  import { writable } from "svelte/store";
  import { getValidAccessToken } from "../stores/auth";

  export let character;
  export let cultureList;
  export let heritageList;
  export let trainingList;
  export let alignmentList;
  export let onClose;

  let isPublic = writable(character.public);
  let isEditMode = writable(false);
  let editableCharacter = writable({ ...character });

  async function togglePublic() {
    const updatedPublic = !character.public;
    isPublic.set(updatedPublic);

    try {
      const token = await getValidAccessToken();
      const response = await fetch(`/api/characters/${character.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ public: updatedPublic }),
      });

      if (!response.ok) {
        throw new Error("Failed to update visibility");
      }
      character.public = updatedPublic;
    } catch (error) {
      console.error(error);
      isPublic.set(character.public);
    }
  }

  async function saveCharacterChange() {
    const updatedCharacter = { ...$editableCharacter };

    try {
      const token = await getValidAccessToken();
      const response = await fetch(`/api/characters/${character.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedCharacter),
      });

      if (!response.ok) {
        throw new Error("Failed to save changes");
      }
      Object.assign(character, updatedCharacter);
      isEditMode.set(false);
    } catch (error) {
      console.error(error);
    }
  }

  function discardChanges() {
    editableCharacter.set({ ...character });
    isEditMode.set(false);
  }

  function getCultureNameById(id) {
    const culture = cultureList.find((item) => item.id === id);
    return culture ? culture.name : "Unknown Culture";
  }

  function getHeritageNameById(id) {
    const heritage = heritageList.find((item) => item.id === id);
    return heritage ? heritage.name : "Unknown Heritage";
  }

  function getTrainingNameById(id) {
    const training = trainingList.find((item) => item.id === id);
    return training ? training.name : "Unknown Training";
  }

  function getAlignmentNameById(id) {
    const alignment = alignmentList.find((item) => item.id === id);
    return alignment ? alignment.name : "Unknown Alignment";
  }
</script>

<div
  class="modal-overlay"
  role="dialog"
  aria-labelledby="modal-heading"
  on:click={onClose}
  on:keydown={(event) => event.key === "Escape" && onClose()}
>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="modal-content" on:click|stopPropagation>
    <button
      class="close-button"
      on:click={onClose}
      aria-label="Close character details"
    >
      ✕
    </button>

    <div class="modal-header">
      <img
        class="portrait"
        src={character.image || "/media/images/placeholder.png"}
        alt={`Portrait of ${character.name}`}
      />
      <div class="header-details">
        <h2 id="modal-heading">{character.name}</h2>
        <p class="subtitle">
          Level {character.level} • {getTrainingNameById(character.training)}
        </p>
        <button class="toggle-public" on:click={togglePublic}>
          {#if $isPublic}
            Set to Private
          {:else}
            Set to Public
          {/if}
        </button>
        <button
          class="edit-button"
          on:click={() => isEditMode.set(!$isEditMode)}
        >
          {#if $isEditMode}
            Cancel Edit
          {:else}
            Edit
          {/if}
        </button>
        {#if $isPublic}
          <a
            href={`#/character/${character.id}`}
            target="_blank"
            class="public-link"
          >
            View Public Page
          </a>
        {/if}
      </div>
    </div>

    <div class="character-sheet">
      <!-- Main Section -->
      <section class="main-section">
        <!-- Character Details -->
        <div class="character-details">
          <h3>Character Details</h3>
          <div class="details-grid">
            <p class="label">Culture:</p>
            <p>{getCultureNameById(character.culture)}</p>

            <p class="label">Heritage:</p>
            <p>
              {getHeritageNameById(character.heritage_1)} / {getHeritageNameById(
                character.heritage_2
              )}
            </p>

            <p class="label">Alignment:</p>
            <p>{getAlignmentNameById(character.alignment)}</p>

            <p class="label">EXP:</p>
            <p>{character.experience}</p>
          </div>
        </div>

        <!-- Primary Stats -->
        <div class="primary-stats">
          <h3>Primary Stats</h3>
          <div class="details-grid">
            <p class="label">Body:</p>
            <p>{character.body}</p>

            <p class="label">Mind:</p>
            <p>{character.mind}</p>

            <p class="label">Soul:</p>
            <p>{character.soul}</p>

            <p class="label">Luck:</p>
            <p>{character.luck}</p>
          </div>
        </div>
      </section>

      <!-- Lore Section -->
      <section class="lore-section">
        <h3>Backstory & Traits</h3>
        <div class="lore-content">
          {#if $isEditMode}
            <label for="backstory"><strong>Backstory:</strong></label>
            <textarea
              id="backstory"
              bind:value={$editableCharacter.backstory}
              placeholder="Enter backstory..."
            ></textarea>

            <label for="description"><strong>Description:</strong></label>
            <textarea
              id="description"
              bind:value={$editableCharacter.description}
              placeholder="Enter description..."
            ></textarea>

            <label for="traits"><strong>Traits:</strong></label>
            <textarea
              id="traits"
              bind:value={$editableCharacter.traits}
              placeholder="Enter traits..."
            ></textarea>
          {:else}
            <p>
              <strong>Backstory:</strong>
              {character.backstory || "No backstory provided."}
            </p>
            <p>
              <strong>Description:</strong>
              {character.description || "No description provided."}
            </p>
            <p>
              <strong>Traits:</strong>
              {character.traits || "No traits provided."}
            </p>
          {/if}
        </div>
      </section>

      <!-- Inventory Section -->
      {#if character.inventory && character.inventory.length > 0}
        <section class="inventory-section">
          <h3>Inventory</h3>
          <div class="inventory-grid">
            {#each $editableCharacter.inventory as item, index}
              <div class="inventory-item">
                {#if $isEditMode}
                  <label for={`name-${index}`}><strong>Name:</strong></label>
                  <input
                    id={`name-${index}`}
                    type="text"
                    bind:value={item.name}
                    placeholder="Item name"
                  />

                  <label for={`description-${index}`}
                    ><strong>Description:</strong></label
                  >
                  <textarea
                    id={`description-${index}`}
                    bind:value={item.description}
                    placeholder="Item description"
                  ></textarea>

                  <label for={`effect-${index}`}><strong>Effect:</strong></label
                  >
                  <input
                    id={`effect-${index}`}
                    type="text"
                    bind:value={item.effect}
                    placeholder="Item effect"
                  />

                  <label for={`attunement-${index}`}
                    ><strong>Attunement:</strong></label
                  >
                  <input
                    id={`attunement-${index}`}
                    type="checkbox"
                    bind:checked={item.attunement}
                  />
                {:else}
                  <h4>{item.name}</h4>
                  <p><strong>Description:</strong> {item.description}</p>
                  <p><strong>Effect:</strong> {item.effect}</p>
                  <p>
                    <strong>Attunement:</strong>
                    {item.attunement ? "Required" : "Not Required"}
                  </p>
                {/if}
              </div>
            {/each}
          </div>
        </section>
      {/if}

      <!-- Save/Discard Buttons (only visible in edit mode) -->
      {#if $isEditMode}
        <div class="edit-buttons">
          <button class="discard-button" on:click={discardChanges}
            >Discard</button
          >
          <button class="save-button" on:click={saveCharacterChange}
            >Save</button
          >
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  /* Modal Overlay */
  .modal-overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.8); /* Semi-transparent dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  /* Modal Content */
  .modal-content {
    width: 90%;
    max-width: 700px;
    background: #fdf4e3; /* Matches card background */
    border: 5px solid rgb(107, 80, 0); /* Same border as card */
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    position: relative;
    font-family: "Lora", serif;
    color: #333; /* Standard dark text color */
    overflow-y: auto;
    max-height: 90vh;
  }

  /* Close Button */
  .close-button {
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: rgb(107, 80, 0);
    cursor: pointer;
  }

  .close-button:hover {
    color: #6b5000;
  }

  /* Modal Header */
  .modal-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .portrait {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 16px;
    border: 3px solid rgb(107, 80, 0);
  }

  /* Main Section Layout */
  .main-section {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }

  .character-details,
  .primary-stats {
    flex: 1; /* Each section takes equal space */
  }

  .details-grid {
    display: grid;
    grid-template-columns: auto 1fr; /* Label and value are side-by-side */
    gap: 8px 12px;
  }

  h3 {
    font-size: 1.2rem;
    color: rgb(107, 80, 0);
    border-bottom: 2px solid #e9e4d2;
    margin-bottom: 12px;
    padding-bottom: 4px;
  }

  textarea,
  input[type="text"] {
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    margin-bottom: 12px;
    font-size: 0.9rem;
    font-family: "Lora", serif;
    border: 1px solid rgb(107, 80, 0);
    border-radius: 4px;
    background: #fdf4e3;
    color: #333;
  }

  textarea {
    resize: vertical; /* Allow vertical resizing */
    min-height: 80px;
  }

  input[type="checkbox"] {
    margin-left: 8px;
  }

  label {
    font-weight: bold;
    color: rgb(107, 80, 0);
    margin-bottom: 4px;
    display: block;
  }

  .label {
    font-weight: bold;
    color: #6b5000;
    font-size: 0.9rem;
  }

  .details-grid p {
    margin: 0;
    font-size: 0.9rem;
    color: #333;
  }

  .character-details,
  .primary-stats {
    flex: 1; /* Each section takes equal space */
  }

  .character-details {
    padding-right: 8px;
  }

  .primary-stats {
    padding-left: 8px;
  }

  .details-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px 12px;
    margin-bottom: 16px;
  }

  .subtitle {
    font-size: 1rem;
    color: #555;
    margin-top: 4px;
  }

  .public-link {
    color: rgb(107, 80, 0);
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease;
    margin-left: 10px;
  }

  .public-link:hover {
    color: #6b5000;
  }

  /* Section Headers */
  .character-sheet h3 {
    font-size: 1.2rem;
    color: rgb(107, 80, 0);
    border-bottom: 2px solid #e9e4d2;
    margin-bottom: 8px;
    padding-bottom: 4px;
  }

  /* Details Grid */
  .details-grid {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px 12px;
    margin-bottom: 16px;
  }

  .label {
    font-weight: bold;
    color: #6b5000;
    font-size: 0.9rem;
  }

  /* Lore Section */
  .lore-content p {
    margin-bottom: 8px;
    font-size: 0.9rem;
    color: #555;
  }

  .lore-content strong {
    color: #6b5000;
  }

  /* Inventory Section */
  .inventory-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  .inventory-item {
    background: #e9e4d2;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid rgb(107, 80, 0);
  }

  .inventory-item h4 {
    font-size: 1rem;
    color: rgb(107, 80, 0);
    margin-bottom: 4px;
  }

  .inventory-item p {
    font-size: 0.85rem;
    color: #333;
    margin: 4px 0;
  }

  /* General Left Alignment */
  .character-sheet,
  .modal-header,
  .lore-content,
  .inventory-grid {
    text-align: left;
  }
</style>
