<script>
  let mindMap = {
    ideas: [
      { id: "gold", title: "Downfall of the Barons", x: 675, y: 350 },
      { id: "red", title: "Red Baron", x: 540, y: 260 },
      {
        id: "red-1",
        title: "Investigate illegal arms deals with enemy states.",
        x: 520,
        y: 120,
      },
      {
        id: "red-2",
        title: "Disrupt his recruitment tournaments for a private army.",
        x: 330,
        y: 120,
      },
      {
        id: "red-3",
        title: "Expose worker exploitation in his weapon factories.",
        x: 350,
        y: 250,
      },
      { id: "blue", title: "Blue Baron", x: 780, y: 250 },
      {
        id: "blue-1",
        title: "Break his alliance with A'bal, a powerful media mogul.",
        x: 700,
        y: 130,
      },
      {
        id: "blue-2",
        title: "Steal secret files exposing his blackmail operations.",
        x: 890,
        y: 100,
      },
      {
        id: "blue-3",
        title: "Dismantle his spy network spreading false evidence.",
        x: 980,
        y: 210,
      },
      {
        id: "blue-4",
        title:
          "Uncover what the Blue Baron and A'bal are Searching for in Salarin.",
        x: 700,
        y: 30,
      },
      { id: "green", title: "Green Baron", x: 675, y: 530 },
      {
        id: "green-2",
        title: "Shut down underground gambling dens funding his operations.",
        x: 580,
        y: 590,
      },
      {
        id: "green-3",
        title:
          "Find out who controls the drug trade after the Green Baron's disappearance and Investigate a dangerous new drug spreading in the city.",
        x: 750,
        y: 610,
      },
      {
        id: "green-4",
        title:
          "Uncover Vanessa Green's role in her father's resurrection, or lack off.",
        x: 940,
        y: 620,
      },
      { id: "white", title: "White Baron", x: 880, y: 350 },
      {
        id: "white-1",
        title:
          "Infiltrate a charity gala to undercover any of the White Baron's secrets.",
        x: 880,
        y: 420,
      },
      {
        id: "white-2",
        title: "Sabotage airship production to weaken his shipping empire. ",
        x: 1100,
        y: 300,
      },
      {
        id: "white-3",
        title: "Prevent the White Baron from being elected City President.",
        x: 1100,
        y: 410,
      },
      {
        id: "white-4",
        title:
          "Gain Evidence of the White Baron's alliance with Mr Brightside.",
        x: 1080,
        y: 500,
      },
      { id: "black", title: "Black Baron", x: 480, y: 390 },
      {
        id: "black-1",
        title: "Investigate his bank’s ties to organized crime.",
        x: 480,
        y: 480,
      },
      {
        id: "black-2",
        title: "Sabotage the launch of a highly addictive game causing debt.",
        x: 290,
        y: 370,
      },
      {
        id: "black-3",
        title: "Enter a secret chess tournament to gather intel on his allies.",
        x: 290,
        y: 510,
      },
    ],

    connections: [
      { from: "red", to: "red-1" },
      { from: "blue", to: "blue-1" },
      { from: "green", to: "green-1" },
      { from: "white", to: "white-1" },
      { from: "black", to: "black-1" },
      { from: "red", to: "red-2" },
      { from: "blue", to: "blue-2" },
      { from: "green", to: "green-2" },
      { from: "white", to: "white-2" },
      { from: "black", to: "black-2" },
      { from: "red", to: "red-3" },
      { from: "blue", to: "blue-3" },
      { from: "black", to: "black-3" },
      { from: "green", to: "green-3" },
      { from: "white", to: "white-3" },
      { from: "green-3", to: "green-4" },
      { from: "white-1", to: "white-4" },
      { from: "blue-1", to: "blue-4" },
      { from: "gold", to: "blue" },
      { from: "gold", to: "green" },
      { from: "gold", to: "red" },
      { from: "gold", to: "white" },
      { from: "gold", to: "black" },
    ],
  };

  let svgWidth = 1200;
  let svgHeight = 600;

  function findIdeaById(id) {
    return mindMap.ideas.find((idea) => idea.id === id);
  }

  function getColorFromId(id) {
    if (id.startsWith("red")) return "red";
    if (id.startsWith("blue")) return "blue";
    if (id.startsWith("green")) return "green";
    if (id.startsWith("white")) return "white";
    if (id.startsWith("black")) return "#555"; // Slightly grey black
    return "#FFD700"; // Default color (white)
  }

  const wrapText = (text, maxWidth) => {
    const words = text.split(" ");
    let lines = [];
    let currentLine = "";

    for (const word of words) {
      const testLine = currentLine ? `${currentLine} ${word}` : word;
      if (testLine.length > maxWidth) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine = testLine;
      }
    }

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines;
  };

  let candidates = [
    { name: "Old Marily", percentage: 15, change: 2, color: "#3498db" }, // Blue
    {
      name: "Thomas Bramblewick",
      percentage: 8,
      change: -10,
      color: "#e74c3c",
    }, // Red
    { name: "Lorenza", percentage: 13, change: 4, color: "#9b59b6" }, // Purple
    { name: "Francine", percentage: 4, change: -10, color: "#2ecc71" }, // Green
    { name: "The White Baron", percentage: 27, change: -7, color: "#f1c40f" }, // Yellow
    { name: "Zenith Silvanius", percentage: 15, change: 8, color: "#FFD1DC" }, // Pastel Pink
    { name: "Sir Jeff", percentage: 8, change: 7, color: "#CB99C9" }, // Pastel Violet
    { name: "Barreak Urizana", percentage: 10, change: 9, color: "#228B22" }, // Forest Green
  ];
</script>

<section class="page-container">
  <div class="storyline-intro">
    <h1>Alterra 2025: The Fall of the Barons</h1>
    <p>
      The campaign storyline, "The Fall of the Barons," centers on the five
      Dragonfruit Barons, corrupt capitalists who wield immense power in
      society. Each Baron’s influence is tied to a specific type of dragonfruit
      and a major industry. These Barons have built their fortunes through
      unethical means, including hired killings, intimidation, and illegal
      dealings, leaving society to suffer under their influence.
      <br /><br />
      Taking them down requires more than just defeating them in battle; the players
      must dismantle the very foundations of their power—destroying their businesses
      and cutting off their wealth and resources. Only then can the Barons truly
      be stopped from reclaiming their positions.
    </p>
    <br />
    <h2>The Realm of Eres</h2>
    <p>
      Eres, once home to four powerful city-states, is now a war-torn landscape.
      Akiba, a small trading town, has grown into a city-state and stands as the
      last sanctuary of peace. After three years of war, divine armies have been
      routed, but new threats are emerging, including undead, deadly plants, and
      monstrous creatures. Adventurers are needed to clear these threats and
      reclaim lost territories. But while dangers lurk outside the city of
      Akiba, corruption spreads at the city's core as power is concentrating in
      the hands of the elite.
    </p>
    <br />

    <h2>Meet The Dragonfruit Barons</h2>
    <br />

    <h3>The Red Baron</h3>
    <p>
      Controls weapons manufacturing, including swords, crossbows, armored
      Warhorses, and fighting competitions. His business thrives on conflict and
      chaos. The Red Baron is said to be a gluttonous large man (Human) with a
      quick and violent temper who is constantly followed by several abused
      servants.
    </p>

    <br />
    <h3>The Blue Baron</h3>
    <p>
      Specializes in information brokering, auction houses, and high-end item
      dealing. A master of secrets and leverage, their business trades in the
      power of knowing what others don’t. The Blue Baron is said to be a sharp
      hawk-faced man (Half-Elf) with beady eyes and rectangular spectables. His
      intelligence is matched only by his envy and insecurity.
    </p>
    <br />

    <h3>The Green Baron</h3>
    <p>
      Oversees shady enterprises like the alcohol industry, gambling dens, and
      the illicit drug trade. Their underhanded dealings keep society hooked on
      vices. The Green Baron is a sleezy weasle of a man (Human) known for his
      uncomfortable leering gaze and slicked back hair.
    </p>
    <br />

    <h3>The White Baron</h3>
    <p>
      Dominates import/export industries and funds investment into newly
      developed airships. Their wealth supports opulent events like galas,
      balls, and charity auctions. The face of the group, the White Baron is
      said to be tall, charming, and handsome with a golden voice and a lush of
      blond locks said to be both the desire and envy of maidens across the
      realm.
    </p>
    <br />

    <h3>The Black Baron</h3>
    <p>
      Invests in high-stakes banking and the strategy gaming industry, producing
      games like Shadowfey Chess and organizing exclusive competitions. The
      Black Baron is the least well known among the oligarch thanks to his
      reclusive nature. Rumours suggests that he is often consulted in the
      matter of War Game tatics by several high ranking commanders.
    </p>
  </div>

  <h3>The Path to Victory! (Narrative Objectives)</h3>
  <div class="mind-map-container">
    <svg
      viewBox="0 0 ${svgWidth} ${svgHeight}"
      preserveAspectRatio="xMidYMid meet"
      style="width: 100%; height: 100%;"
    >
      {#each mindMap.connections as { from, to }}
        {#if findIdeaById(from) && findIdeaById(to)}
          <line
            x1={findIdeaById(from).x + 75}
            y1={findIdeaById(from).y +
              (wrapText(findIdeaById(from).title, 40).length * 20 + 20) / 2}
            x2={findIdeaById(to).x + 75}
            y2={findIdeaById(to).y +
              (wrapText(findIdeaById(to).title, 40).length * 20 + 20) / 2}
            stroke={getColorFromId(from)}
            stroke-width="2"
          />
        {/if}
      {/each}

      {#each mindMap.ideas as { id, title, x, y }}
        <g transform={`translate(${x}, ${y})`} class="idea-box">
          <rect
            width="150"
            height={wrapText(title, 30).length * 20 + 20}
            rx="10"
            ry="10"
            fill="#444"
            stroke={getColorFromId(id)}
            stroke-width="2"
          />

          <!-- Render wrapped lines of text -->
          {#each wrapText(title, 25) as line, index}
            <text
              x="75"
              y={index * 15 + 25}
              text-anchor="middle"
              font-size="12"
              fill="white"
            >
              {line}
            </text>
          {/each}
        </g>
      {/each}
    </svg>
  </div>

  <div class="card">
    <h2 class="title">
      📊 Akiba Election Poll Estimates (30,000 denizens polled)
    </h2>

    {#each candidates as candidate}
      <div class="candidate">
        <div class="name">
          {candidate.name} ({candidate.percentage}%)
          <span
            class="change"
            style="color: {candidate.change > 0 ? 'green' : 'red'}"
          >
            {candidate.change > 0
              ? `🔼 +${candidate.change}`
              : `🔽 ${candidate.change}`}
          </span>
        </div>
        <div class="bar-container">
          <div
            class="bar"
            style="
            width: {candidate.percentage}%;
            background-color: {candidate.color};
            transition: width 0.5s ease-in-out;
          "
          ></div>
        </div>
      </div>
    {/each}
  </div>
</section>

<style>
  * {
    box-sizing: border-box;
  }

  * {
    box-sizing: border-box;
  }

  .storyline-intro {
    padding: 20px;
    color: white;
    background-color: #2b2b2b;
    margin-bottom: 20px;
    border-radius: 10px;
  }

  h1,
  h2,
  h3 {
    color: #ffcc00;
  }

  .mind-map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    width: 100%;
    background-color: #2c2f33;
    color: #ffffff;
    overflow: hidden;
  }

  .card {
    margin: 20px auto;
    padding: 20px;
    background: rgba(255, 255, 255, 0.756);
    border-radius: 10px;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
  }

  .candidate {
    margin-bottom: 10px;
  }

  .name {
    font-size: 0.9rem;
    font-weight: 600;
    color: #555;
    margin-bottom: 4px;
  }

  .change {
    margin-left: 10px;
    font-size: 12px;
  }

  .bar-container {
    width: 100%;
    background: #eee;
    height: 25px;
    border-radius: 5px;
    overflow: hidden;
  }

  .bar {
    height: 100%;
    transition: width 0.5s ease-in-out;
  }

  @media (max-width: 768px) {
    .storyline-intro {
      padding: 10px;
    }
  }
</style>
