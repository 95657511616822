<script>
  import { selectedCharacter } from "../../stores/character";
  export let characterList = [];
</script>

<div class="character-header">
  {#if characterList && characterList.length > 0}
    <h3>Select Character:</h3>
    <select
      id="selectedCharacter"
      class="dropdown"
      bind:value={$selectedCharacter}
    >
      {#each characterList as character}
        <option value={character}>{character.name}</option>
      {/each}
    </select>
    <label for="selectedCharacter">
      Now shopping for {$selectedCharacter?.name}
    </label>
    <label for="selectedCharacterGold">
      Available Gold: {$selectedCharacter?.inventory?.currency.find(
        (e) => e.item_name == "Gold"
      )?.quantity || 0}
    </label>
  {/if}
</div>

<style>
  .character-header {
    display: flex;
    width: 80%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 16px;
  }

  .character-header h3,
  .character-header label,
  .character-header select {
    max-width: 250px;
    padding: 10px;
    margin: 10px;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    margin-bottom: 0px;
  }
</style>
