<script>
  let mapImage = "media/images/TheShatteredAltsphereExtra.png";
  let redDotClassName;
  let tooltipClassName;

  function changeImage(newSrc) {
    mapImage = newSrc;
    if (newSrc === "loreBackground" || newSrc === "questlogBackground") {
      document.querySelector(".page-container").style.height = "200%";
    } else if (newSrc === "media/images/DawnRiser.png") {
      document.querySelector(".page-container").style.height = "100%";
    } else {
      document.querySelector(".page-container").style.height = "95vh";
    }
    reprocessDots();
  }

  function placeRedDot(x, y, className, tooltipText, tooltipClasSName) {
    const imageWidth = 1100;
    const imageHeight = 618;

    const mapContainer = document.querySelector(".dot-container");

    const percentageX = (x / imageWidth) * 100;
    const percentageY = (y / imageHeight) * 100;

    const redDot = document.createElement("div");
    redDot.className = className;

    redDot.style.left = `${percentageX}%`;
    redDot.style.top = `${percentageY}%`;

    const tooltip = document.createElement("div");
    tooltip.className = tooltipClasSName;
    tooltip.textContent = tooltipText;

    redDot.appendChild(tooltip);

    redDot.addEventListener("click", () => {
      if (redDot.style.backgroundColor === "green") {
        redDot.style.backgroundColor = "red";
      } else {
        redDot.style.backgroundColor = "green";
      }
    });

    mapContainer.appendChild(redDot);
  }

  function reprocessDots() {
    const container = document.querySelector(".dot-container");
    if (container) {
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }
    }
    if (mapImage == "media/images/TheShatteredAltsphereExtra.png") {
      placeRedDot(
        500,
        500,
        redDotClassName,
        "Click to change colour",
        tooltipClassName
      );
      placeRedDot(
        600,
        23,
        redDotClassName,
        "Click to change colour",
        tooltipClassName
      );
      placeRedDot(
        80,
        500,
        redDotClassName,
        "Click to change colour",
        tooltipClassName
      );
    }
  }

  document.addEventListener("DOMContentLoaded", () => {
    redDotClassName = document.querySelector(".red-dot").className;
    tooltipClassName = document.querySelector(".tooltip").className;
    reprocessDots();
  });
</script>

<slot>
  <div class="page-container">
    <div class="sidebar">
      <div class="circle-gap"></div>
      <button
        class="circle-button blue-border"
        on:click={() => changeImage("loreBackground")}
      >
        Lore
      </button>
      <button
        class="circle-button green-border"
        on:click={() => changeImage("media/images/DawnRiser.png")}
      >
        Dawn Riser
      </button>
      <button
        class="circle-button yellow-border"
        on:click={() =>
          changeImage("media/images/TheShatteredAltsphereExtra.png")}
      >
        TSA
      </button>
      <!-- <button
        class="circle-button orange-border"
        on:click={() => changeImage("media/images/SalvosEmpty.png")}
      >
        Salvos
      </button> -->
      <button
        class="circle-button red-border"
        on:click={() => changeImage("media/images/Salvos.png")}
      >
        Salvos
      </button>
      <button
        class="circle-button orange-border"
        on:click={() => changeImage("questlogBackground")}
      >
        Quest Log
      </button>
    </div>
    <div class="map-container">
      <div class="image-container">
        {#if mapImage === "media/images/DawnRiser.png"}
          <img src={mapImage} alt="Dawn Riser" class="background-image" />
        {:else if mapImage === "media/images/TheShatteredAltsphereExtra.png"}
          <img src={mapImage} alt="TSA" class="background-image" />
        {:else if mapImage === "media/images/Salvos.png"}
          <img src={mapImage} alt="Salvos" class="background-image" />
        {:else if mapImage === "loreBackground"}
          <div class="lore-text">
            <h3>Previously on The Shattered Altsphere</h3>
            <p>Text Block 1</p>
            <p>Test Block 2</p>
            <p>Test Block 3</p>
            <h4>Key Terms:</h4>
            <li>
              <b>Term 1.</b> Key Term 1
            </li>
            <li>
              <b>Term 2.</b> Key Term 2
            </li>
            <li>
              <b>Term 3.</b> Key Term 3
            </li>
            <li>
              <b>Term 4.</b> Key Term 4
            </li>
            <li>
              <b>Term 5.</b> Key Term 5
            </li>
          </div>
        {:else if mapImage === "questlogBackground"}
          <div class="quest-text">
            <h3>Quest Board</h3>
            <div class="questcard">
              <h4>That Quest</h4>
              <p>
                <b>For:</b> Who the quest is for
              </p>
              <p>
                <b>Summary:</b> Summary of the quest relevant details
              </p>
              <p>
                <b>Reward:</b> What the expected/hoped outcome is
              </p>
            </div>
            <div class="questcard">
              <h4>That Quest</h4>
              <p>
                <b>For:</b> Who the quest is for
              </p>
              <p>
                <b>Summary:</b> Summary of the quest relevant details
              </p>
              <p>
                <b>Reward:</b> What the expected/hoped outcome is
              </p>
            </div>
            <div class="questcard">
              <h4>That Quest</h4>
              <p>
                <b>For:</b> Who the quest is for
              </p>
              <p>
                <b>Summary:</b> Summary of the quest relevant details
              </p>
              <p>
                <b>Reward:</b> What the expected/hoped outcome is
              </p>
            </div>
          </div>
        {/if}
      </div>
      <div class="dot-container">
        <div class="red-dot">
          <div class="tooltip">Shh! I'm not here...</div>
        </div>
      </div>
    </div>
  </div>
</slot>

<style>
  * {
    box-sizing: border-box;
  }

  .page-container {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    padding: 15px;
    height: 95vh;
  }

  .sidebar {
    width: 12%;
    box-sizing: border-box;
    padding: 20px;
    border: 10px solid #632d06;
    border-right: none;
    border-radius: 10px 0 0 10px;
    background-color: #2e1713;
  }

  .circle-gap {
    margin-top: 5px;
  }

  .circle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90px;
    border-radius: 50%;
    background-color: #282d30;
    color: #ffffff;
    text-align: center;
    border: none;
    cursor: pointer;
    margin-top: 5px;
  }

  .yellow-border {
    border: 2px solid #f1c40f;
  }

  .green-border {
    border: 2px solid #0ff116;
  }

  .orange-border {
    border: 2px solid #f1690f;
  }

  .red-border {
    border: 2px solid #f10f0f;
  }

  .blue-border {
    border: 2px solid #0f9af1;
  }

  .map-container {
    position: relative;
    width: 100%;
    height: 100%;
    border: 10px solid #632d06;
    border-radius: 0 10px 10px 0;
    background: rgba(0, 0, 0, 0.453);
    z-index: 0;
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .red-dot {
    position: absolute;
    left: -10%;
    top: -10%;
    width: 25px;
    height: 25px;
    background-color: red;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    cursor: pointer;
  }

  .tooltip {
    position: absolute;
    color: white;
    padding: 5px;
    border-radius: 5px;
    visibility: hidden;
    bottom: 75%;
    left: 100%;
    min-width: 250px;
    /* transform: translateX(-50%); */
    transition: visibility 0.2s ease-in-out;
    text-shadow: 2px 2px 4px black;
  }

  .red-dot:hover .tooltip {
    visibility: visible;
  }

  .lore-text {
    padding: 50px;
    color: white;
    text-align: center;
  }

  .lore-text p {
    text-align: justify;
  }

  .lore-text h4 {
    text-align: left;
    margin-top: 50px;
  }

  .lore-text li {
    text-align: left;
    margin-left: 15px;
    margin-bottom: 25px;
    list-style-type: none;
  }

  .quest-text {
    padding: 50px;
    color: white;
    text-align: center;
  }

  .quest-text h3 {
    margin-bottom: 40px;
  }

  .questcard {
    color: white;
    text-align: center;
    background-color: rgba(36, 0, 47, 0.495);
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    text-align: justify;
    margin-bottom: 20px;
  }
</style>
