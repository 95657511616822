<script>
  import { auth, clearAuth } from "../stores/auth";

  $: authState = $auth;

  const logout = () => {
    clearAuth();
    location.reload();
  };

  let showDropdown = false;
  function toggleDropdown() {
    showDropdown = !showDropdown;
  }
  function closeDropdown() {
    showDropdown = false;
  }

  let showSidePanel = false;
  function toggleSidePanel() {
    showSidePanel = !showSidePanel;
  }

  // Close the side panel when a link is clicked
  function closeSidePanelOnLinkClick() {
    showSidePanel = false;
  }
</script>

<div class="navbar">
  <div class="logo">
    <a href="/">
      <img
        class="logo"
        src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167507/AlterraLogo_nxjmqn.jpg"
        alt="Logo"
      />
    </a>
  </div>

  <div class="links">
    <a href="/#/streams" on:click={closeSidePanelOnLinkClick}>Streams</a>
    <a href="/#/stories/latest/1" on:click={closeSidePanelOnLinkClick}
      >Stories</a
    >
    <a href="/#/games" on:click={closeSidePanelOnLinkClick}>Games</a>
    <a href="/#/news/latest/1" on:click={closeSidePanelOnLinkClick}>News</a>
    <a href="/#/lore/latest/1" on:click={closeSidePanelOnLinkClick}>Lore</a>

    <div
      class="dropdown"
      role="menu"
      tabindex="0"
      on:mouseenter={toggleDropdown}
      on:mouseleave={closeDropdown}
    >
      <button class="dropdown-button"> External </button>
      {#if showDropdown}
        <div
          class="dropdown-content"
          role="menuitem"
          tabindex="0"
          on:mouseenter={() => {
            showDropdown = true;
          }}
          on:mouseleave={closeDropdown}
        >
          <a
            href="https://www.twitch.tv/worldofalterra"
            on:click={closeSidePanelOnLinkClick}>Twitch</a
          >
          <a
            href="https://www.youtube.com/@worldofalterra"
            on:click={closeSidePanelOnLinkClick}>YouTube</a
          >
          <a
            href="https://www.buymeacoffee.com/worldofalterra"
            on:click={closeSidePanelOnLinkClick}>BuyUsACoffee</a
          >
        </div>
      {/if}
    </div>
  </div>

  {#if authState?.isAuthenticated && authState?.user && authState.user.editor}
    <div class="new-lore">
      <a href="/#/lore/new">Create Lore</a>
    </div>
  {/if}

  {#if authState?.isAuthenticated && authState?.user}
    <div class="signup user-actions">
      <a href="/" on:click={logout}>Sign Out</a>
    </div>
    <div class="signin user-actions">
      <a href="/#/account" class="border">{authState.user.username}</a>
    </div>
  {:else}
    <div class="signup user-actions">
      <a href="/#/signup">Sign Up</a>
    </div>
    <div class="signin user-actions">
      <a href="/#/signin" class="border">Sign In</a>
    </div>
  {/if}

  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="menu-icon" on:click={toggleSidePanel}>☰</div>

  {#if showSidePanel}
    <div class="side-panel open">
      <!-- svelte-ignore a11y-no-static-element-interactions -->
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <div class="close-icon" on:click={toggleSidePanel}>&times;</div>
      {#if authState?.isAuthenticated && authState?.user}
        <a href="/" on:click={logout}>Sign Out</a>
        <a href="/#/account" on:click={closeSidePanelOnLinkClick}
          >{authState.user.username}</a
        >
      {:else}
        <a href="/#/signup" on:click={closeSidePanelOnLinkClick}>Sign Up</a>
        <a href="/#/signin" on:click={closeSidePanelOnLinkClick}>Sign In</a>
      {/if}
      <a href="/#/streams" on:click={closeSidePanelOnLinkClick}>Streams</a>
      <a href="/#/stories/latest/1" on:click={closeSidePanelOnLinkClick}
        >Stories</a
      >
      <a href="/#/games" on:click={closeSidePanelOnLinkClick}>Games</a>
      <a href="/#/news/latest/1" on:click={closeSidePanelOnLinkClick}>News</a>
      <a href="/#/lore/latest/1" on:click={closeSidePanelOnLinkClick}>Lore</a>
      ___
      <a
        href="https://www.twitch.tv/worldofalterra"
        on:click={closeSidePanelOnLinkClick}>Twitch</a
      >
      <a
        href="https://www.youtube.com/@worldofalterra"
        on:click={closeSidePanelOnLinkClick}>YouTube</a
      >
      <a href="/BuyUsACoffee" on:click={closeSidePanelOnLinkClick}
        >BuyUsACoffee</a
      >
    </div>
  {/if}
</div>

<style>
  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: #1f0620;
    display: flex;
    align-items: center;
    padding: 0 10px;
    z-index: 1000;
    border-bottom: 6px solid #7c005a;
    font-size: 110%;
    font-family: "Acme", sans-serif;
  }

  .navbar a:hover {
    transform: scale(1.3);
    transition: transform 0.1s ease;
  }

  .logo {
    width: 100px;
    height: 100px;
    margin-left: 10px;
    margin-top: 15px;
    position: relative;
    border-radius: 100%;
  }

  .logo img {
    border: 3px solid #7c005a;
  }

  .border {
    border: 6px solid #7c005a;
  }

  .links {
    display: flex;
    align-items: center;
    margin-left: 50px;
  }

  .links a {
    text-decoration: none;
    color: white;
    margin-right: 40px;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    margin-bottom: -10px;
  }

  .dropdown-button {
    background-color: #7c005a;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #1f0620;
    padding: 10px;
    border: 1px solid #7c005a;
    border-top: none;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-content a {
    color: white;
    text-decoration: none;
    display: block;
    padding: 5px 0;
  }

  .dropdown-content a:hover {
    margin-left: 12px;
  }

  .new-lore {
    margin-left: 40px;
  }

  .new-lore a {
    text-decoration: none;
    color: white;
  }

  .signup {
    margin-left: auto;
  }

  .signup a {
    text-decoration: none;
    color: white;
  }

  .signin {
    margin-left: 40px;
    margin-right: 0px;
  }

  .signin a {
    text-decoration: none;
    color: white;
    border: 2px solid #7c005a;
    border-radius: 20px;
    padding: 5px 15px;
    margin-right: 50px;
  }

  .menu-icon {
    display: none;
    font-size: 1.5em;
    cursor: pointer;
    margin-right: 40px;
    color: white;
  }

  .side-panel {
    position: fixed;
    top: 0;
    right: -100%;
    max-width: 60%;
    height: 100%;
    background-color: #1f0620;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .side-panel.open {
    right: 0;
  }

  .close-icon {
    font-size: 1.7em;
    margin-right: 10px;
    margin-top: -5px;
    cursor: pointer;
    align-self: flex-end;
    color: white;
  }

  @media (max-width: 768px) {
    .menu-icon {
      display: block;
      margin-left: auto;
    }

    .links {
      display: none;
    }

    .user-actions {
      display: none;
    }

    .side-panel {
      width: 100%;
    }

    .side-panel a {
      color: white;
      text-decoration: none;
      margin-bottom: 20px;
    }
  }
</style>
