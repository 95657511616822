<script>
  let mapImage =
    "https://res.cloudinary.com/dw39fffyv/image/upload/v1742167514/Phantasia_wnneqf.png";

  function changeImage(newSrc) {
    mapImage = newSrc;

    if (newSrc === "loreBackground" || newSrc === "questlogBackground") {
      document.querySelector(".page-container").style.height = "200%";
    } else {
      document.querySelector(".page-container").style.height = "95vh";
    }
  }
</script>

<slot>
  <div class="page-container">
    <div class="sidebar">
      <div class="circle-gap"></div>
      <button
        class="circle-button blue-border"
        on:click={() => changeImage("loreBackground")}
      >
        Lore
      </button>
      <button
        class="circle-button green-border"
        on:click={() =>
          changeImage(
            "https://res.cloudinary.com/dw39fffyv/image/upload/v1742167514/Phantasia_wnneqf.png"
          )}
      >
        Phantasia
      </button>
    </div>
    <div class="content-container">
      {#if mapImage === "https://res.cloudinary.com/dw39fffyv/image/upload/v1742167514/Phantasia_wnneqf.png"}
        <img src={mapImage} alt="Phantasia" class="map-image" />
      {:else if mapImage === "loreBackground"}
        <div class="lore-text">
          <p class="disclaimer">
            Disclaimer: Welcome to our high magic fantasy tabletop RPG! We
            strive to create a fun and inclusive environment for everyone. If
            you have any specific phobias, triggers, or content concerns that
            the Dungeon Master should be aware of, please don't hesitate to
            reach out to me (Chris Mckenzie '@chrisalterradm') directly. We will
            do our best to accommodate your needs and inform you of any
            potential issues in advance to ensure a comfortable and enjoyable
            experience for all players.
          </p>
          <h3>Previously on Phantasia</h3>
          <p>
            Phantasia, home and first dwelling place of the Elves, is a
            fey-soaked land, a vibrant tapestry of ecology, species, and
            machiavellian schemes. Ruled over by the sixteen Archfey, Phantasia
            is currently amidst a Civil War between the Summer and Winter
            Courts. But to tell that story, we must first recount the tale of
            the House of Epoch, the first of the Elven Houses.
          </p>

          <p>
            The House of Epoch emerged in the Third Age, a collection of the
            first elves ever created by the God Epoch, each an Eladrin Elf
            capable of changing their form to any elven subrace. The eldest was
            the Queen of Night and Magic, a fierce and intelligent spellcaster.
            Second was the Queen of Air and Darkness, a cold and remorseless
            strategist. Third was the Prince of Frost, a creative and
            mischievous scoundrel, and last, but not least, was the Princess of
            Winter, a resilient and determined survivalist. Together, the House
            of Epoch ruled the northern and western lands of Phantasia,
            eventually establishing the Winter Court, rivaling King Oberon's
            Summer Court to the East and South. For an Age, the two courts kept
            a wary watch on each other, each building and preparing for betrayal
            or invasion, tension growing stronger with each passing day—a
            tension felt even in the most distant reaches of the continent.
          </p>

          <p>
            At the start of the Fourth Age, it was decided that the two Houses
            would unite in marriage to prevent the growing conflict before it
            could erupt, bringing permanent peace to the land. The Princess of
            Winter would marry the Summer King, coming to be known as Titania,
            the Queen-consort of Summer.
          </p>

          <p>The peace did not last.</p>

          <p>
            After centuries of marriage, the relationship between Titania and
            Oberon began to decay. Oberon's wandering eye and insatiable
            appetite could not be satisfied by the beautiful Eladrin, leading to
            a cycle of deceit, discovery, and punishment. On one occasion, even
            Oberon's most trusted ally, the River King, suffered when he
            discovered his favorite consort, a young River Elf named Aqualis,
            whispering secrets into the Summer King's ear. Enraged by the
            betrayal, the River King cursed the young elf, transforming her into
            the first Sea Hag and banishing her to the Endless Blue, never to
            set foot where fresh water dwelled again.
          </p>

          <p>
            But the ultimate betrayal came when Titania discovered her husband's
            affair with her own eldest sister, the Queen of Night and Magic.
            Enraged and betrayed, Titania pledged to have her revenge once and
            for all.
          </p>

          <p>
            For a year, she plotted, gathering the adventurers she needed to
            enact her plan: five powerful heroes from across the realms, each
            named for the Chromatic Star that granted them access to Titan's
            hoarded power. The Summer Queen's Five Stars were ready. Under cover
            of night, they infiltrated Oberon's chamber, bringing the Elder Fey
            to his knees, allowing Titania to strike the final blow and consume
            her husband. Now in possession of Oberon's Right To Rule, the Summer
            Queen ascended to the throne and turned her attention to the North.
            Her vengeance would not be sated until she had her sister's head.
          </p>

          <p>
            The Civil War raged for 100 years until the forces of Summer pushed
            into the Winter Court, where Titania and her Five Stars brought the
            Queen of Night and Magic to her knees. The once Princess of Winter
            drove her blade into her sister's heart, determined that she would
            never revive. The Summer Queen banished the body of the Queen of
            Night and Magic where it would never be found by another living
            soul. Thus, the first Fey Civil War came to an end. With nothing
            left to gain from resisting, the Queen of Air and Darkness ascended
            to the throne of the Winter Court, and Titania, finally satisfied,
            made peace between the lands.
          </p>

          <p>
            For another two Ages, peace reigned. Until the start of the Seventh
            Age, when a young Merida Blackstar threw herself into a pyre at the
            Summer Festival and perished.
          </p>

          <p>
            Finding herself in the Afterfey, a realm beyond time, life, and
            conventional travel, Blackstar was guided to the imprisoned body of
            the Queen of Night and Magic. Making a pact with the powerful
            spellcaster, Blackstar's second Soul returned to its rightful body.
            The Queen of Night and Magic was revived, and together, the two
            Elves, with aid from a creative and mischievous brother, returned to
            Phantasia.
          </p>

          <p>
            The Second Civil War has begun, and this time, a disagreement
            between sisters won't be confined solely within Phantasia's borders.
            Not when other forces across the planes could exploit the war for
            their own purposes.
          </p>
          <h4>Key Terms:</h4>
          <li>
            <b>The House of Epoch.</b> TBD
          </li>
        </div>
      {/if}
    </div>
  </div>
</slot>

<style>
  * {
    box-sizing: border-box;
  }

  .page-container {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    padding: 15px;
    height: 95vh;
  }

  .sidebar {
    width: 12%;
    box-sizing: border-box;
    padding: 20px;
    border: 10px solid #632d06;
    border-right: none;
    border-radius: 10px 0 0 10px;
    background-color: #2e1713;
  }

  .circle-gap {
    margin-top: 20px;
  }

  .circle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    border-radius: 50%;
    background-color: #282d30;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
  }

  /* .yellow-border {
    border: 2px solid #f1c40f;
  } */

  /* .orange-border {
    border: 2px solid orange;
  } */

  .green-border {
    border: 2px solid #0ff116;
  }

  .blue-border {
    border: 2px solid #0f9af1;
  }

  .disclaimer {
    margin-bottom: 50px;
    color: lightblue;
  }

  .content-container {
    width: 100%;
    border: 10px solid #632d06;
    border-radius: 0 10px 10px 0;
    background: rgba(0, 0, 0, 0.453);
  }

  .content-container img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .lore-text {
    padding: 50px;
    color: white;
    text-align: center;
  }

  .lore-text p {
    text-align: justify;
  }

  .lore-text h4 {
    text-align: left;
    margin-top: 50px;
  }

  .lore-text li {
    text-align: left;
    margin-left: 15px;
    margin-bottom: 25px;
    list-style-type: none;
  }
  /* 
  .quest-text {
    padding: 50px;
    color: white;
    text-align: center;
  }

  .quest-text h3 {
    margin-bottom: 40px;
  }

  .quest-text h4 {
    text-align: left;
    text-decoration: underline;
  }

  .questcard {
    color: white;
    text-align: center;
    background-color: rgba(36, 0, 47, 0.495);
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    text-align: justify;
    margin-bottom: 20px;
  }

  .edit-button {
    border: none;
    cursor: pointer;
    padding: auto;
    margin-left: 4px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: rgba(36, 0, 47, 0.495);
  }

  .edit-disabled {
    border: none;
    padding: auto;
    margin-left: 4px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: rgba(36, 0, 47, 0.495);
  }

  .edit-button:hover {
    background-color: #073b20;
  } */

  @media (max-width: 768px) {
    .page-container {
      flex-direction: column;
    }

    .sidebar {
      width: 100%;
      padding: 10px;
      display: flex;
      border: none;
      background-color: transparent;
      justify-content: space-around;
    }

    .circle-button {
      height: 50px;
    }

    .content-container {
      border: none;
      background: transparent;
    }

    .lore-text {
      padding: 20px;
    }
    /*  .quest-text h4  */

    .lore-text h4 {
      margin-top: 20px;
    }
    /* .quest-text h4  */

    /* .lore-text li,
    .quest-text li {
      margin-bottom: 15px;
    } */

    /* .quest-text h3 {
      margin-bottom: 20px;
    }

    .questcard {
      padding: 10px;
      margin-bottom: 10px;
    } */
  }
</style>
