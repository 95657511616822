<script>
  import { onMount } from "svelte";
  export let params;

  let articleId;

  $: {
    articleId = params.articleId;
  }

  let news_article = {};

  function formatDate(dateString) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }

  onMount(async () => {
    try {
      const response = await fetch(`/api/news-articles/${articleId}`);
      if (response.ok) {
        const data = await response.json();
        news_article = data;
      } else {
        console.error("failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  });
</script>

<slot>
  <div class="colored-rectangle">
    <div class="article-category-container {news_article.category}">
      <h1 class="article-category">{news_article.category}</h1>
    </div>
    <h2 class="article-header">{news_article.title}</h2>
    <p class="article-date">{formatDate(news_article.published_date)}</p>
    <img
      src={news_article.thumbnail}
      alt="ArticleImage"
      class="article-image"
    />
    <p class="article-text">{@html news_article.content}</p>
  </div></slot
>

<style>
  .colored-rectangle {
    width: 75%;
    margin: 100px auto;
    padding: 30px; /* Increased padding for better text spacing */
    background-color: rgba(
      215,
      181,
      148,
      0.9
    ); /* Slight transparency for blending */
    border: 5px solid rgba(188, 151, 96, 1); /* Thinner, solid border for contrast */
    min-height: 80%;
    border-radius: 15px;
    font-family: "Acme", sans-serif;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7); /* Darker shadow for depth */
    background-image: url("/media/images/papertexture.jpeg"); /* Texture image URL */
    background-size: cover; /* Ensures the texture covers the entire area */
    background-position: center 10%; /* Moves the texture slightly upwards */
    position: relative; /* Needed for the overlay */
  }

  .colored-rectangle::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(
      215,
      181,
      148,
      0.365
    ); /* Semi-transparent overlay color */
    border-radius: 15px; /* Match border radius */
    z-index: 0; /* Place behind text */
  }

  .article-header,
  .article-date,
  .article-category-container,
  .article-category,
  .article-image,
  .article-text {
    position: relative; /* Place text above overlay */
    z-index: 1; /* Ensure text is above the overlay */
  }

  .article-category-container {
    background-color: #ff7a00; /* Bright orange for category */
    color: #fff; /* White text */
    border-radius: 8px; /* Rounded corners */
    text-align: center;
    padding: 5px; /* Increased padding for better spacing */
    max-width: 80%; /* Increased max width */
    margin: 10px auto; /* Centered with margin */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Shadow for depth */
    position: relative; /* Place text above overlay */
    z-index: 1; /* Ensure text is above the overlay */
  }

  .article-category {
    font-size: 18px; /* Increased font size for visibility */
    text-transform: uppercase;
    font-weight: bold; /* Added bold for emphasis */
  }

  .world {
    background-color: rgb(102, 87, 0);
  }

  .lore {
    background-color: rgb(1, 41, 114);
  }

  .games {
    background-color: rgb(0, 71, 0);
  }

  .streams {
    background-color: rgb(91, 91, 91);
  }

  .novels {
    background-color: purple;
  }

  .personal {
    background-color: rgb(123, 0, 0);
  }

  .article-header {
    text-align: center;
    font-size: 42px; /* Increased font size for better visibility */
    margin-top: 50px; /* Spacing at the top */
    margin-bottom: 5px; /* Spacing at the bottom */
    color: #e0b0ff; /* Light purple for contrast */
    font-weight: bold; /* Make the header bold */
    text-shadow:
      2px 2px 4px rgba(0, 0, 0, 0.8),
      1px 1px 2px rgba(162, 0, 255, 0.6); /* Shadow for depth */
  }

  .article-date {
    text-align: center;
    font-size: 18px; /* Increased font size for better visibility */
    color: #e0b0ff; /* Light purple for the date */
    margin-top: 10px; /* Increased margin for better spacing */
    font-weight: 500; /* Medium weight for clarity */
    text-shadow:
      1px 1px 3px rgba(0, 0, 0, 0.5),
      1px 1px 2px rgba(162, 0, 255, 0.4); /* Shadow for contrast */
  }

  .article-image {
    width: 80%; /* Responsive width */
    margin: 20px auto; /* Centered with margin */
    max-height: 300px; /* Maximum height for images */
  }

  .article-text {
    font-size: 16px; /* Font size for text */
    margin-top: 10px; /* Spacing at the top */
    color: #333; /* Dark grey for better readability */
  }

  @media (max-width: 768px) {
    .colored-rectangle {
      width: 90%; /* Responsive width on smaller screens */
    }

    .article-header {
      font-size: 20px; /* Responsive font size */
      margin-top: 20px; /* Adjusted margin */
    }

    .article-date {
      font-size: 12px; /* Responsive font size */
    }

    .article-text {
      font-size: 14px; /* Responsive font size */
    }

    .article-image {
      width: 100%; /* Full width on small screens */
      margin: 10px auto; /* Centered with margin */
    }
  }
</style>
