<script>
  import { onMount } from "svelte";
  import { getValidAccessToken } from "../lib/stores/auth";
  import { auth } from "../lib/stores/auth";
  import { push } from "svelte-spa-router";
  import { get } from "svelte/store";

  export let params;

  let characterId;
  $: {
    characterId = params.characterId;
  }
  let authState;
  let character = null;
  let error = null;
  let cultureList = [];
  let heritageList = [];
  let trainingList = [];
  let alignmentList = [];

  onMount(async () => {
    authState = get(auth);
    if (!authState.isAuthenticated) {
      push("/signin"); // Redirect if the user isn't authenticated
    } else {
      try {
        // Fetch character details by ID
        await fetchCultures();
        await fetchHeritages();
        await fetchTrainings();
        await fetchAlignments();
        character = await fetchCharacterById(characterId);
      } catch (err) {
        console.error("Error fetching character:", err);
        error = "Could not load character data.";
      }
    }
  });

  // Fetch cultures
  async function fetchCultures() {
    const response = await fetch("/api/cultures");
    const data = await response.json();
    cultureList = data;
  }

  // Fetch heritages
  async function fetchHeritages() {
    const response = await fetch("/api/heritages");
    const data = await response.json();
    heritageList = data;
  }

  // Fetch trainings
  async function fetchTrainings() {
    const response = await fetch("/api/trainings");
    const data = await response.json();
    trainingList = data;
  }

  // Fetch alignments
  async function fetchAlignments() {
    const response = await fetch("/api/alignments");
    const data = await response.json();
    alignmentList = data;
  }

  async function fetchCharacterById(characterId) {
    const token = await getValidAccessToken();
    const response = await fetch(`/api/characters/${characterId}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch character data");
    }

    return await response.json();
  }

  function getCultureNameById(id) {
    const culture = cultureList.find((item) => item.id === id);
    return culture ? culture.name : "Unknown Culture";
  }

  function getHeritageNameById(id) {
    const heritage = heritageList.find((item) => item.id === id);
    return heritage ? heritage.name : "Unknown Heritage";
  }

  function getTrainingNameById(id) {
    const training = trainingList.find((item) => item.id === id);
    return training ? training.name : "Unknown Training";
  }

  function getAlignmentNameById(id) {
    const alignment = alignmentList.find((item) => item.id === id);
    return alignment ? alignment.name : "Unknown Alignment";
  }
</script>

<div class="character-sheet-container">
  {#if error}
    <p>{error}</p>
  {:else if !character}
    <p>Loading character...</p>
  {:else if !character.public}
    <div class="private-message">
      <p>This Character is set to Private</p>
    </div>
  {:else}
    <div class="character-modal-content">
      <h1>{character.name}'s Character Sheet</h1>

      <div class="character-section">
        <h2>Basic Info</h2>
        <div class="basic-info-row">
          <div class="info-item">
            <p class="label">Culture:</p>
            <p>{getCultureNameById(character.culture)}</p>
          </div>
          <div class="info-item">
            <p class="label">Heritage:</p>
            <p>
              {getHeritageNameById(character.heritage_1)} / {getHeritageNameById(
                character.heritage_2
              )}
            </p>
          </div>
          <div class="info-item">
            <p class="label">Training:</p>
            <p>{getTrainingNameById(character.training)}</p>
          </div>
          <div class="info-item">
            <p class="label">Alignment:</p>
            <p>{getAlignmentNameById(character.alignment)}</p>
          </div>
        </div>
      </div>

      <div class="character-section">
        <h2>Attributes</h2>
        <div class="attributes-row">
          <p><strong>Body:</strong> {character.body}</p>
          <p><strong>Mind:</strong> {character.mind}</p>
          <p><strong>Soul:</strong> {character.soul}</p>
          <p><strong>Luck:</strong> {character.luck}</p>
        </div>
      </div>

      <div class="character-section">
        <h2>Experience</h2>
        <p><strong>Experience Points:</strong> {character.experience}</p>
      </div>

      <div class="character-section">
        <h2>Information</h2>
        <div class="left-aligned-info">
          <div class="description-item">
            <p class="title"><strong>Backstory:</strong></p>
            <p>{character.backstory || "No backstory provided."}</p>
          </div>
          <div class="description-item">
            <p class="title"><strong>Description:</strong></p>
            <p>{character.description || "No description provided."}</p>
          </div>
          <div class="description-item">
            <p class="title"><strong>Traits:</strong></p>
            <p>{character.traits || "No traits provided."}</p>
          </div>
          <div class="description-item">
            <p class="title"><strong>Notes:</strong></p>
            <p>{character.notes || "No notes provided."}</p>
          </div>
        </div>
      </div>

      <div class="character-section">
        <h2>Inventory</h2>
        {#if character.inventory.length > 0}
          <ul>
            {#each character.inventory as item}
              <li>{item.name}</li>
            {/each}
          </ul>
        {:else}
          <p>No items in inventory.</p>
        {/if}
      </div>
    </div>
  {/if}
</div>

<style>
  /* General Styles */
  .character-sheet-container {
    max-width: 800px;
    margin: 100px auto;
    padding: 20px;
    background: #fdf4e3; /* Lighter background to match the modal content */
    border: 5px solid rgb(107, 80, 0); /* Matching border color */
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Subtle shadow for elevation */
    font-family: "Lora", serif;
    color: #333;
  }

  h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;
    color: rgb(107, 80, 0);
  }
  .character-section {
    margin-bottom: 30px;
  }

  .left-aligned-info {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 15px; /* Space between each item */
  }

  .basic-info-row {
    display: flex;
    justify-content: start; /* Distributes space evenly between items */
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    gap: 40px; /* Adds space between items */
  }

  .info-item {
    display: flex;
    flex-direction: column; /* Stack the label on top of the value */
    align-items: center; /* Center the text horizontally */
  }

  .info-item p {
    margin: 0; /* Removes default margin from paragraphs */
  }

  .label {
    font-weight: bold; /* Makes the label bold */
    margin-bottom: 5px; /* Adds some space between the label and the value */
  }

  .attributes-row {
    display: flex;
    justify-content: start; /* Distributes space evenly between items */
    flex-wrap: wrap; /* Allows wrapping on smaller screens */
    gap: 20px; /* Adds space between items */
  }

  .attributes-row p {
    margin: 0; /* Removes default margin from paragraphs */
  }

  p {
    font-size: 1rem;
    margin: 5px 0;
  }

  /* Private Message Styles */
  .private-message {
    text-align: center;
    padding: 20px;
    background-color: #f8d7da; /* Soft red background */
    color: #721c24; /* Red text */
    border-radius: 5px;
    font-size: 1.2rem;
  }

  /* Character Section Styles */
  h2 {
    font-size: 1.4rem;
    color: rgb(107, 80, 0);
    margin-bottom: 10px;
    border-bottom: 2px solid #e9e4d2; /* Subtle border for section separation */
    padding-bottom: 4px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  ul li {
    background: #e4e4e4;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    font-size: 0.9rem;
  }

  /* Character Details Layout */
  .character-modal-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fdf4e3; /* Background matching modal content */
    border: 5px solid rgb(107, 80, 0); /* Same border as card */
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); /* Shadow effect */
  }
</style>
