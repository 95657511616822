<div class="lore-text">
  This file contains all known information on the Black Baron.
</div>

<div class="dossier">
  <div class="dossier-header">
    <h1>Dossier: Black Baron</h1>

    <div class="portrait">
      <img
        src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167494/placeholder_lzsl1l.gif"
        alt="Black Baron Portrait"
      />
    </div>
  </div>

  <div class="dossier-section">
    <h2>Profile</h2>
    <p>
      <strong>Name:</strong> The Black Baron<br />
      <strong>Age:</strong> 48<br />
      <strong>Appearance:</strong> Reserved yet imposing, always clad in tasteful
      but unmistakably opulent attire. Often seen with a finely crafted chess set
      and a calculating expression.
    </p>
    <p>
      The Black Baron is the most enigmatic of the Barons, shunning the public
      spotlight in favor of private dealings and intellectual pursuits. A master
      of strategy, their influence spans high-stakes banking and the gaming
      industry, where they produce exclusive games and tournaments. Commanders
      and strategists across the realm seek the Black Baron's counsel, lending
      them a subtle but formidable presence in matters of power.
    </p>
  </div>

  <div class="dossier-section">
    <h2>Public Enterprises</h2>
    <p>
      The Black Baron operates through refined yet shadowy ventures, including:
    </p>
    <ul>
      <li>
        <strong>Obsidian Trust:</strong> An elite banking institution specializing
        in wealth management for the powerful and secretive.
      </li>
      <li>
        <strong>Shadowfey Games:</strong> A leading producer of strategy games such
        as Shadowfey Chess, catering to aristocrats and intellectuals.
      </li>
      <li>
        <strong>The Grandmaster Circuit:</strong> An exclusive series of high-stakes
        strategy competitions attracting the brightest minds and deepest pockets.
      </li>
    </ul>
  </div>

  <div class="dossier-section">
    <h2>Criminal Activities</h2>
    <p>
      Though shrouded in secrecy, the Black Baron's dealings suggest a darker
      side:
    </p>
    <ul>
      <li>
        Manipulated financial markets through insider information and coercive
        loans.
      </li>
      <li>
        Used banking power to blackmail clients into political and military
        compliance.
      </li>
      <li>
        Orchestrated the sabotage of rival game producers to monopolize the
        strategy gaming industry.
      </li>
      <li>
        Hosted clandestine war game simulations for training mercenaries and
        rogue commanders.
      </li>
    </ul>
  </div>

  <div class="dossier-section">
    <h2>Current Objectives</h2>
    <p>Suggested actions to undermine the Black Baron’s influence:</p>
    <ul>
      <li>
        <strong>Expose:</strong> Hidden clauses in Obsidian Trust's contracts
        that trap clients in financial servitude.<br />
      </li>
      <li>
        <strong>Sabotage:</strong> A major Shadowfey Chess tournament to disrupt
        their control of the gaming industry.<br />
      </li>
      <li>
        <strong>Uncover:</strong> Evidence linking the Black Baron to
        clandestine war simulations used by mercenary groups.<br />
      </li>
    </ul>
  </div>
</div>

<style>
  * {
    box-sizing: border-box;
  }

  .lore-text {
    padding: 30px;
    color: white;
    text-align: center;
    font-family: "Courier New", Courier, monospace;
  }

  .dossier {
    background-color: #000;
    padding: 20px;
    color: #fff;
    font-family: "Courier New", Courier, monospace;
    border: 1px solid #555;
    max-width: 800px;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
  }

  .dossier-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #555;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .dossier-header h1 {
    font-size: 1.5em;
    color: #aaa;
  }

  .portrait {
    width: 120px;
    height: 120px;
    border: 2px solid #aaa;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
  }

  .portrait img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform: scale(1.1);
  }

  .dossier-section {
    margin-bottom: 20px;
  }

  .dossier-section h2 {
    color: #aaa;
    font-size: 1.2em;
    border-bottom: 1px solid #555;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  .dossier-section p,
  .dossier-section ul {
    margin: 10px 0;
  }

  .dossier-section ul {
    list-style-type: none;
    padding-left: 0;
  }

  .dossier-section ul li::before {
    content: "• ";
    color: #aaa;
  }

  @media (max-width: 768px) {
    .dossier-header {
      flex-direction: column;
      align-items: center;
    }
    .portrait {
      margin-top: 10px;
    }
  }
</style>
