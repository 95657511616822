
import Home from "./routes/Home.svelte";
import Account from "./routes/Account.svelte";
import Games from "./routes/Games.svelte";
import LoreArticle from "./routes/LoreArticle.svelte";
import LoreCategory from "./routes/LoreCategory.svelte";
import LoreLatest from "./routes/LoreLatest.svelte";
import LoreSearch from "./routes/LoreSearch.svelte";
import NewsArticle from "./routes/NewsArticle.svelte"
import NewsLatest from "./routes/NewsLatest.svelte";
import NewsSearch from "./routes/NewsSearch.svelte";
import StoriesLatest from "./routes/StoriesLatest.svelte";
import StoriesSearch from "./routes/StoriesSearch.svelte";
import StoriesArticle from "./routes/StoriesArticle.svelte";
import PasswordReset from "./routes/PasswordReset.svelte";
import Signin from "./routes/Signin.svelte";
import Signup from "./routes/Signup.svelte";
import StreamArchive from "./routes/SteamArchive.svelte";
import StreamVideo from "./routes/StreamVideo.svelte";
import Stream from "./routes/Stream.svelte";
import TheShatteredAltsphere from "./routes/TheShatteredAltsphere.svelte";
import Eres from "./routes/Eres.svelte";
import Geni from "./routes/Geni.svelte";
import Amfore from "./routes/Amfore.svelte"
import Phantasia from "./routes/Phantasia.svelte";
import AlterraDawnRiser from "./routes/AlterraDawnRiser.svelte"
import CharacterCreation from "./routes/CharacterCreation.svelte"
import CraftStation from "./routes/CraftStation.svelte"
import CharacterPage from "./routes/CharacterPage.svelte"

export const routes = {
  "/": Home,
  "/account": Account,
  "/games": Games,
  "/games/alterra-dawn-riser": AlterraDawnRiser,
  "/lore/article/:articleId": LoreArticle,
  "/lore/category/:category/:page": LoreCategory,
  "/lore/latest/:page": LoreLatest,
  "/lore/search/:query/:page": LoreSearch,
  "/news/article/:articleId": NewsArticle,
  "/news/latest/:page": NewsLatest,
  "/news/search/:query/:page": NewsSearch,
  "/stories/latest/:page": StoriesLatest,
  "/stories/search/:query/:page": StoriesSearch,
  "/stories/story/:storyId": StoriesArticle,
  "/password-reset": PasswordReset,
  "/signin": Signin,
  "/signup": Signup,
  "/streams/archive/:page": StreamArchive,
  "/streams/video/:slug": StreamVideo,
  "/streams": Stream,
  "/the-shattered-altsphere": TheShatteredAltsphere,
  "/eres": Eres,
  "/geni": Geni,
  "/phantasia": Phantasia,
  "/amfore": Amfore,
  "/character-creation": CharacterCreation,
  "/craft-station": CraftStation,
  "/character/:characterId": CharacterPage,
};
