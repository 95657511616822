<script>
  let mapImage = "media/images/Geni.png";

  function changeImage(newSrc) {
    mapImage = newSrc;

    if (newSrc === "loreBackground" || newSrc === "questlogBackground") {
      document.querySelector(".page-container").style.height = "200%";
    } else {
      document.querySelector(".page-container").style.height = "95vh";
    }
  }
</script>

<slot>
  <div class="page-container">
    <div class="sidebar">
      <div class="circle-gap"></div>
      <button
        class="circle-button blue-border"
        on:click={() => changeImage("loreBackground")}
      >
        Lore
      </button>
      <button
        class="circle-button green-border"
        on:click={() => changeImage("media/images/Geni.png")}
      >
        Geni
      </button>
    </div>
    <div class="content-container">
      {#if mapImage === "media/images/Geni.png"}
        <img src={mapImage} alt="Geni" class="map-image" />
      {:else if mapImage === "loreBackground"}
        <div class="lore-text">
          <h3>Previously on Geni</h3>
          <p>
            Geni, home to the elemental maelstrom and surging primal energies,
            this continent consists of the Genasi mainland, and four outer
            islands; home to settlements of Aarakocra, Kenku, Minotaurs,
            Centaurs, Tortles, and Usagi communities. With a diverse range of
            natural and extra-planar hazards, the people of this land are no
            strangers to conflict, but have found ways to live their lives and
            fight back against the neverending incursions of the imprisoned
            Primordials and apocalyptic cults that seek the Elemental Princes
            return.
          </p>
          <p>
            What history remains carved into the lower pillars of Avem, or
            hidden deep in the archives of the Chelona temples, warn of a cycle
            of destruction. When the barriers to the Elemental Chaos are at
            their weakest and the influence of the Primordials can do more than
            whisper. It is prophesied that in the darkest night, when all seems
            lost, when the fires of creations sweep the land, and the waters of
            life drown the cities, that seven heroes will rise once more,
            bestowed with the divine relics of the nature pantheon, these heroes
            will push back the apocalypse, and save not only the realm, but all
            of Alterra from the return of the Primordials. Should this cycle
            ever be broken, it may spell doom for us all.
          </p>
          <h4>Key Terms:</h4>
          <li>
            <b>The Elemental Maelstrom.</b> One of two barriers believed to exist
            in Alterra. The maelstrom is thought to be the gateway to the elemental
            chaos, a divine prison where the gods locked away the Primordials, an
            elder race that once roamed the land destroying all that they touched.
          </li>
          <li>
            <b>Avem.</b> The Island of Spires. Home to the Aarakocra and smaller
            population of Kenku, Avem is an island of rocky spires, and carved towers.
            The island is famous for its long and detailed history, a history that
            is primarily carved into the very rock of the island itself.
          </li>
          <li>
            <b>Bovuus.</b> The Island of Sweeping Meadows. Home of the Minotaurs
            and Centaurs, Bovussus is an island of open land and hanging groves with
            tree tops that blankets the sky yet rarely touch the ground.
          </li>
          <li>
            <b>Chelona.</b> The Island of Tranquil Pools. Home of the Tortles, Chelona
            is an island of beautiful scenery; tall hills, still ponds, and a diverse
            ecosystem of fruits, plants, and critters.
          </li>
          <li>
            <b>Lepus.</b> The Island of Roaming Winds. Home to the Usagi, the island
            has a porous mountain range at its center and as a result of this hollow
            structure it is frequently besieged by strong winds and travelling tornadoes.
          </li>
          <li>
            <b>The Geni Defence Force.</b> A loose coalition of the eight great mainland
            cities. This organisation is an effort to coordinate and share information
            and resources in the battle against elemental incursions and extra-planar
            threats.
          </li>
          <li>
            <b>Tarew.</b> The City of Water. A bustling hub of commerce, Tarew borders
            the inner maelstrom mountains and is designed with buildings both above
            and below the waterline of its riverway streets. Making it an ideal home
            for both water dwelling races such as Sea Elves, Triton, and Tortles,
            and non-water dwelling races. The city is ruled by the ten Lords of Tarew,
            a council whose identities are kept secret to all but the highest ranked
            nobles and administrators.
          </li>
          <li>
            <b>Rief.</b> The City of Fire. In the north-east of the mainland, Rief
            is the mining capital of the continent. The city's Iron Hooves minotaurs
            live underground and oversee a vast community of workshops and dig sites,
            whose goods and resources are directed up to a city of industry and innovation.
            As a benefit of mining so close to the maelstrom barrier Rief has the
            largest export of magical ores and metals anyway in the realm. While
            the city itself is ruled over by a hereditary marchion, the true power
            is held by the Council of Four Stones, an oligarchy of mining families
            that determine the production quotas for the Iron Hooves.
          </li>
          <li>
            <b>Ria.</b> The City of Air. A vertical city reaching for the sky and
            the astral sea beyond, Ria is known for its population of writers and
            monasteries, and is renowned as the capital of relaxation and spiritual
            pursuits.
          </li>
          <li>
            <b>Hetar.</b> The City of Earth. A large farming city, and the central
            trading hub of the east. Hetar is known for its skilled chefs and vast
            food culture.
          </li>
          <li>
            <b>Maagm.</b> The City of Magma. Maagm is constructed primarily of thick
            glass, and is known for its fine mechanical workshops and clockwork creatures.
            As well as its famous hotsprings and saunas.
          </li>
          <li>
            <b>Eci.</b> The City of Ice. The southern port city, Eci is known for
            its fish trade and High Sea Races, as well as its seafaring academy.
          </li>
          <li>
            <b>Mesok.</b> The City of Smoke. Home of the largest black market and
            string of gambling dens, Mesok is also famous for its theatres, grand
            festivals, and mysterious history. More than one ghost story can trace
            its origins to Mesok.
          </li>
          <li>
            <b>Armsh.</b> The City of Marsh. A cultural mixing pot, Armsh is the
            Jewel of the East, with vast crystal buildings and lush marchland nature.
          </li>
        </div>
      {/if}
    </div>
  </div>
</slot>

<style>
  * {
    box-sizing: border-box;
  }

  .page-container {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    padding: 15px;
    height: 95vh;
  }

  .sidebar {
    width: 12%;
    box-sizing: border-box;
    padding: 20px;
    border: 10px solid #632d06;
    border-right: none;
    border-radius: 10px 0 0 10px;
    background-color: #2e1713;
  }

  .circle-gap {
    margin-top: 20px;
  }

  .circle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    border-radius: 50%;
    background-color: #282d30;
    color: #ffffff;
    text-align: center;
    border: none;
    cursor: pointer;
    margin-top: 20px;
  }

  /* .yellow-border {
    border: 2px solid #f1c40f;
  }

  .orange-border {
    border: 2px solid orange;
  } */

  .green-border {
    border: 2px solid #0ff116;
  }

  .blue-border {
    border: 2px solid #0f9af1;
  }

  .content-container {
    width: 100%;
    height: 100%;
    border: 10px solid #632d06;
    border-radius: 0 10px 10px 0;
    background: rgba(0, 0, 0, 0.453);
  }

  .content-container img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .lore-text {
    padding: 50px;
    color: white;
    text-align: center;
  }

  .lore-text p {
    text-align: justify;
  }

  .lore-text h4 {
    text-align: left;
    margin-top: 50px;
  }

  .lore-text li {
    text-align: left;
    margin-left: 15px;
    margin-bottom: 25px;
    list-style-type: none;
  }
  /* 
  .quest-text {
    padding: 50px;
    color: white;
    text-align: center;
  }

  .quest-text h3 {
    margin-bottom: 40px;
  }

  .quest-text h4 {
    text-align: left;
    text-decoration: underline;
  } */

  /* .questcard {
    color: white;
    text-align: center;
    background-color: rgba(36, 0, 47, 0.495);
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    text-align: justify;
    margin-bottom: 20px;
  } */

  @media (max-width: 768px) {
    .page-container {
      flex-direction: column;
    }

    .sidebar {
      width: 100%;
      padding: 10px;
      display: flex;
      border: none;
      background-color: transparent;
      justify-content: space-around;
    }

    .circle-button {
      height: 50px;
    }

    .content-container {
      border: none;
      background: transparent;
    }

    /* .lore-text,
    .quest-text {
      padding: 20px;
    }

    .lore-text h4,
    .quest-text h4 {
      margin-top: 20px;
    } */
    /* 
    .lore-text li,
    .quest-text li {
      margin-bottom: 15px;
    }

    .quest-text h3 {
      margin-bottom: 20px;
    }

    .questcard {
      padding: 10px;
      margin-bottom: 10px;
    } */
  }
</style>
