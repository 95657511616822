<script>
</script>

<footer class="footer">
  <div class="footer-content">
    <div class="social-links"></div>
    <div class="additional-info">
      <p>&copy; 2020 World of Alterra</p>
      <p>Email: info@worldofalterra.com</p>
    </div>
  </div>
</footer>

<style>
  .footer {
    color: #fff;
    padding: 20px 0;
    text-align: center;
    border-bottom: 12px solid #7c005a;
  }

  footer::after {
    content: "";
    position: fixed;
    bottom: -10%;
    left: 0;
    width: 100%;
    height: 150px;
    background-color: #ffd600;
    border-radius: 100%;
    mix-blend-mode: color-dodge;
    filter: blur(50px);
    opacity: 20%;
    z-index: 0;
    pointer-events: none;
  }

  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .social-links {
    margin-bottom: 20px;
  }

  /* .social-link {
    color: #fff;
    text-decoration: none;
    margin: 0 10px;
  }

  .social-link:hover {
    text-decoration: underline;
  } */

  .additional-info {
    font-size: 14px;
  }
</style>
