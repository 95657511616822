<script>
  import { afterUpdate, onMount } from "svelte";
  import { push, link } from "svelte-spa-router";
  export let params;

  let currentQuery;
  let currentPage;

  $: {
    currentQuery = params.query;
    currentPage = Number(params.page);
  }
  let oldQuery = params.query;
  let oldPage = Number(params.page);
  /**
   * @type {any[]}
   */
  let articles = [];
  let featuredArticles = [];
  let totalPages;

  async function fetchData() {
    try {
      const response = await fetch(
        `/api/wiki-entries?search=${currentQuery}&page=${currentPage}`
      );
      if (response.ok) {
        const articles_data = await response.json();
        articles = articles_data.results;
        totalPages = Math.ceil(articles_data.count / 9);
      } else {
        console.error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
    try {
      const response = await fetch(`/api/wiki-entries-featured`);
      if (response.ok) {
        const featured_articles_data = await response.json();
        featuredArticles = featured_articles_data.results || [];
      } else {
        console.error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  }

  function prevPage() {
    if (currentPage > 1) {
      goToPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < totalPages) {
      goToPage(currentPage + 1);
    }
  }

  function goToPage(newPage) {
    push(`/lore/search/${currentQuery}/${newPage}`);
  }

  let searchText = "";

  function handleSearch() {
    if (searchText) {
      push(`/lore/search/${searchText}/1`);
    }
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      handleSearch();
    }
  }

  onMount(() => {
    fetchData();
  });

  afterUpdate(() => {
    if (oldPage != currentPage) {
      oldPage = currentPage;
      fetchData();
    }
    if (oldQuery != currentQuery) {
      oldQuery = currentQuery;
      fetchData();
    }
  });
</script>

<slot>
  <div class="lore-page">
    <div class="lore-sidebar">
      <button on:click={() => push("/lore/category/general/1")}
        ><span class="tooltip">General</span><img
          src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167513/GeneralBook_diuxm8.png"
          alt="Book 1"
        /></button
      >
      <button on:click={() => push("/lore/category/cosmology/1")}
        ><span class="tooltip">Cosmology</span><img
          src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167513/CosmologyBook_m5qhyb.png"
          alt="Book 1"
        /></button
      >
      <button on:click={() => push("/lore/category/realms/1")}
        ><span class="tooltip">Realms</span><img
          src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167513/RealmsBook_pmjkf3.png"
          alt="Book 1"
        /></button
      >
    </div>
    <div class="lore-content">
      <div class="row lore-sidebar-small" style="display: none;">
        <button on:click={() => push("/lore/category/general/1")}
          ><span class="tooltip">General</span><img
            src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167513/GeneralBook_diuxm8.png"
            alt="Book 1"
          /></button
        >
        <button on:click={() => push("/lore/category/cosmology/1")}
          ><span class="tooltip">Cosmology</span><img
            src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167513/CosmologyBook_m5qhyb.png"
            alt="Book 1"
          /></button
        >
        <button on:click={() => push("/lore/category/realms/1")}
          ><span class="tooltip">Realms</span><img
            src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167513/RealmsBook_pmjkf3.png"
            alt="Book 1"
          /></button
        >
      </div>
      <div class="row lore-sidebar-small" style="display: none;">
        <button on:click={() => push("/lore/category/storylines/1")}
          ><span class="tooltip">Storylines</span><img
            src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167513/StorylinesBook_enkoak.png"
            alt="Book 1"
          /></button
        >
        <button on:click={() => push("/lore/category/items/1")}
          ><span class="tooltip">Items</span><img
            src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167513/ItemsBook_gkluhv.png"
            alt="Book 1"
          /></button
        >
        <button on:click={() => push("/lore/category/bestiary/1")}
          ><span class="tooltip">Bestiary</span><img
            src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167513/BestiaryBook_dxfirw.png"
            alt="Book 1"
          /></button
        >
      </div>
      <div class="featured-container">
        {#each featuredArticles as featuredArticle, i}
          {#if i < 5}
            <div class="badge">
              <a href="/lore/article/{featuredArticle.slug}" use:link>
                {featuredArticle.title}
              </a>
            </div>
          {/if}
        {/each}
      </div>
      <div class="search-container">
        <input
          type="text"
          placeholder="Search..."
          bind:value={searchText}
          on:keydown={handleKeyPress}
        />
        <button on:click={handleSearch} class="search-button">Go</button>
      </div>
      <div class="image-container">
        <img
          class="img-background"
          src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167513/OpenBook_h9lzuw.png"
          alt="Background Book"
        />
        <div class="lore-links">
          <div class="lore-column">
            {#each articles as article, i}
              {#if i < 5}
                <div class="left-link">
                  <a href="/lore/article/{article.slug}" use:link
                    >{article.title}</a
                  >
                </div>
              {/if}
            {/each}
          </div>
          <div class="lore-column">
            {#each articles as article, i}
              {#if i >= 5}
                <div class="right-link">
                  <a href="/lore/article/{article.slug}" use:link
                    >{article.title}</a
                  >
                </div>
              {/if}
            {/each}
          </div>
        </div>
      </div>
      <div class="pagination">
        {#if currentPage > 1}
          <button class="prev-page page-button" on:click={prevPage}
            >Previous</button
          >
        {/if}
        {#if currentPage > 2}
          <button class="page-button" on:click={() => goToPage(1)}>1</button>
        {/if}
        {#if currentPage > 3}
          <span class="ellipsis">...</span>
        {/if}

        {#if currentPage > 1}
          <button class="page-button" on:click={() => goToPage(currentPage - 1)}
            >{currentPage - 1}</button
          >
        {/if}

        <button class="current-button">{currentPage}</button>

        {#if currentPage < totalPages}
          <button class="page-button" on:click={() => goToPage(currentPage + 1)}
            >{currentPage + 1}</button
          >
        {/if}

        {#if currentPage < totalPages - 2}
          <span class="ellipsis">...</span>
        {/if}
        {#if currentPage < totalPages - 1}
          <button class="page-button" on:click={() => goToPage(totalPages)}
            >{totalPages}</button
          >
        {/if}
        {#if currentPage < totalPages}
          <button class="next-page page-button" on:click={nextPage}>Next</button
          >
        {/if}
      </div>
    </div>
    <div class="lore-sidebar">
      <button on:click={() => push("/lore/category/storylines/1")}
        ><span class="tooltip">Storylines</span><img
          src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167513/StorylinesBook_enkoak.png"
          alt="Book 1"
        /></button
      >
      <button on:click={() => push("/lore/category/items/1")}
        ><span class="tooltip">Items</span><img
          src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167513/ItemsBook_gkluhv.png"
          alt="Book 1"
        /></button
      >
      <button on:click={() => push("/lore/category/bestiary/1")}
        ><span class="tooltip">Bestiary</span><img
          src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167513/BestiaryBook_dxfirw.png"
          alt="Book 1"
        /></button
      >
    </div>
  </div>
</slot>

<style>
  .lore-page {
    display: flex;
    justify-content: space-between;
  }

  .lore-sidebar {
    flex: 0.5;
    width: 10%;
    margin-top: 160px;
  }

  .featured-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }

  .badge {
    background-color: blueviolet;
    color: #ffffff;
    padding: 8px 12px;
    border-radius: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 500;
    text-decoration: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease;
  }

  .badge a {
    color: inherit;
    text-decoration: none;
  }

  .badge:hover {
    background-color: #c567e7; /* Darker shade on hover */
  }

  button {
    display: block;
    border: none;
    background: none;
    cursor: pointer;
    max-height: 400px;
    max-width: 250px;
    transition: transform 0.3s ease;
  }

  button:hover {
    transform: scale(1.05); /* Slightly enlarge button on hover */
  }

  button img {
    max-height: 220%;
    max-width: 220%;
    margin-left: -55%;
  }

  .lore-content {
    flex: 3;
    width: 80%;
    margin-top: 100px;
  }

  input[type="text"] {
    width: 100%;
    padding: 10px;
  }

  .search-button {
    border: none;
    background: #ff7a00;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    outline: none;
  }

  .search-container {
    display: flex;
    border-radius: 25px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.8);
    margin-bottom: 20px;
  }

  .search-container input {
    margin-bottom: 0px;
  }

  .search-container button {
    margin-bottom: 0px;
  }

  .search-button:hover {
    background: #ff6600;
  }

  .image-container {
    position: relative;
    width: 95%;
    height: 500px;
    margin-left: 2.5%;
  }

  .image-container img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .lore-links {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    border-radius: 10px;
  }

  .lore-column {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .lore-column a {
    display: block;
    margin-bottom: 25px;
    font-family: "Acme", sans-serif;
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: #000;
  }

  .lore-column a:hover {
    text-decoration: underline;
  }

  .left-link {
    margin-left: 10px;
  }

  .right-link {
    margin-right: 10px;
  }

  .pagination {
    display: flex;
    margin-top: 50px;
    text-align: center;
    justify-content: center;
  }

  .page-button {
    padding: 10px 20px;
    margin: 0 7px;
    background-color: white;
    color: #000;
    border: none;
    border-radius: 15px;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;
    cursor: pointer;
  }

  .current-button {
    padding: 10px 20px;
    margin: 0 7px;
    background-color: blueviolet;
    color: #fff;
    border: 1px;
    border-radius: 15px;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;
    cursor: pointer;
  }

  .pagination button:hover {
    background-color: black;
    color: white;
  }

  .ellipsis {
    color: white;
  }

  .tooltip {
    visibility: hidden;
    width: 120px;
    margin-left: 20px;
    background-color: #1f0620;
    color: #fff;
    text-align: center;
    border-radius: 50px;
    padding: 5px;
    position: absolute;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s;
  }

  button:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  @media (max-width: 1050px) {
    .lore-column a {
      font-size: 22px;
    }
  }

  @media (max-width: 900px) {
    .lore-column a {
      font-size: 20px;
    }
  }

  @media (max-width: 768px) {
    .lore-column a {
      font-size: 18px;
    }
  }

  @media (max-width: 568px) {
    .lore-sidebar {
      display: none !important;
    }
    .lore-column {
      margin: 0px;
      padding: 0px;
    }
    .lore-column a {
      font-size: 16px;
      justify-content: center !important;
    }
    .img-background {
      display: none !important;
    }
    .lore-links {
      flex-direction: column;
    }
    .lore-column a {
      color: white;
    }
    .lore-sidebar-small {
      display: flex !important;
      flex-direction: row;
    }
    .search-container {
      margin-top: 10px;
    }
  }
</style>
