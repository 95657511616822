<script>
</script>

<slot>
	<div class="novels-page">
		<div class="novels-text">
			<h1>Password Reset Has Been Temporarily Disabled</h1>
		</div>
		<div class="coming-soon-text">
			<h2>We Thank You For Your Patience!</h2>
		</div>
	</div>
</slot>

<style>
	.novels-page {
		height: 80vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.novels-text h1,
	.coming-soon-text h2 {
		font-size: 36px;
		text-align: center;
		margin: 0;
		color: white;
		font-family: 'Acme', sans-serif;
	}

	.coming-soon-text {
		margin-top: 20px;
	}
</style>
