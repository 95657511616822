<script>
  import CaftingRules from "../CraftStation/CaftingRules.svelte";
  import ReagentsTab from "../CraftStation/ReagentsTab.svelte";
  import RecipesTab from "../CraftStation/RecipesTab.svelte";
  import CraftingTab from "../CraftStation/CraftingTab.svelte";

  export let reagentsList = [];
  export let recipesList = [];

  let activeTab = "recipes";
  function switchTab(newTab) {
    activeTab = newTab;
  }
</script>

<div class="character-creation-container">
  <div class="tab-buttons">
    <button
      class={activeTab === "reagents" ? "active" : ""}
      on:click={() => switchTab("reagents")}
    >
      🧪 Reagents
    </button>
    <button
      class={activeTab === "recipes" ? "active" : ""}
      on:click={() => switchTab("recipes")}
    >
      📖 Recipes
    </button>
    <button
      class={activeTab === "rules" ? "active" : ""}
      on:click={() => switchTab("rules")}
    >
      📜 Rules
    </button>
    <button
      class={activeTab === "craft" ? "active" : ""}
      on:click={() => switchTab("craft")}
    >
      🛠️ Craft
    </button>
  </div>

  <div class="tab-content">
    {#if activeTab === "reagents"}
      <ReagentsTab {reagentsList} />
    {/if}

    {#if activeTab === "recipes"}
      <RecipesTab {recipesList} />
    {/if}

    {#if activeTab === "rules"}
      <CaftingRules />
    {/if}

    {#if activeTab == "craft"}
      <CraftingTab />
    {/if}
  </div>
</div>

<style>
  .character-creation-container {
    width: 90%;
    font-family: "Arial", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    background-color: #2a123f;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  }

  .tab-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 40px;
    background-color: #2a123f;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 0 10px 10px 10px;
  }

  .tab-buttons {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .tab-buttons button {
    background-color: #3d1752;
    color: #ffcc70;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition:
      background-color 0.3s,
      color 0.3s;
    flex: 1;
    text-align: center;
  }

  .tab-buttons button.active {
    background-color: #ffcc70;
    color: #2a123f;
  }

  .tab-buttons button:hover {
    background-color: #572366;
  }
</style>
