<script>
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import { getValidAccessToken } from "../lib/stores/auth";

  export let characterId;
  let authState;
  let character = null;
  let error = null;

  onMount(async () => {
    authState = get(auth);
    if (!authState.isAuthenticated) {
      push("/signin"); // Redirect if the user isn't authenticated
    } else {
      try {
        // Fetch character details by ID
        character = await fetchCharacterById(characterId);
      } catch (err) {
        console.error("Error fetching character:", err);
        error = "Could not load character data.";
      }
    }
  });

  async function fetchCharacterById(characterId) {
    const token = await getValidAccessToken();
    const response = await fetch(`/api/characters/${characterId}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch character data");
    }

    return await response.json();
  }
</script>

<div class="character-sheet-container">
  {#if error}
    <p>{error}</p>
  {:else if !character}
    <p>Loading character...</p>
  {:else}
    <h1>{character.name}'s Character Sheet</h1>

    <div class="character-section">
      <h2>Basic Info</h2>
      <p><strong>Culture:</strong> {character.culture}</p>
      <p><strong>Heritage 1:</strong> {character.heritage_1}</p>
      <p><strong>Heritage 2:</strong> {character.heritage_2}</p>
      <p><strong>Training:</strong> {character.training}</p>
      <p><strong>Alignment:</strong> {character.alignment}</p>
    </div>

    <div class="character-section">
      <h2>Attributes</h2>
      <p><strong>Strength:</strong> {character.strength}</p>
      <p><strong>Dexterity:</strong> {character.dexterity}</p>
      <p><strong>Constitution:</strong> {character.constitution}</p>
      <p><strong>Intelligence:</strong> {character.intelligence}</p>
      <p><strong>Wisdom:</strong> {character.wisdom}</p>
      <p><strong>Charisma:</strong> {character.charisma}</p>
    </div>

    <div class="character-section">
      <h2>Experience</h2>
      <p><strong>Experience Points:</strong> {character.experience}</p>
    </div>

    <div class="character-section">
      <h2>Inventory</h2>
      {#if character.inventory.length > 0}
        <ul>
          {#each character.inventory as item}
            <li>{item.name}</li>
          {/each}
        </ul>
      {:else}
        <p>No items in inventory.</p>
      {/if}
    </div>
  {/if}
</div>

<style>
  .character-sheet-container {
    max-width: 800px;
    margin: 100px auto;
    padding: 20px;
    background-color: #f9f7f1;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  .character-section {
    margin-bottom: 30px;
  }

  p {
    font-size: 18px;
    margin: 5px 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  ul li {
    background: #e4e4e4;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
  }

  h2 {
    margin-bottom: 10px;
  }
</style>
