<script>
  import Navbar from "../lib/components/Navbar.svelte";
  import Footer from "../lib/components/Footer.svelte";
</script>

<!-- Layout structure -->
<div class="layout">
  <Navbar />
  <div class="content">
    <slot />
  </div>
  <Footer />
</div>

<style>
  /* Base layout styling */
  .layout {
    background-color: #210742;
    margin: 0 auto;
    padding: 0 20px;
    background-attachment: fixed;
    background-size: cover;
    width: 90%;
    min-width: 320px;
    overflow-x: hidden;
    color: #ffffff;
    line-height: 1.6; /* Increased line height for better text spacing */
  }

  /* Background styling for visual elements */
  .layout::before,
  .layout::after {
    content: "";
    position: fixed;
    width: 60vw;
    height: 80vw;
    border-radius: 50%;
    mix-blend-mode: color-dodge;
    filter: blur(60px); /* Reduced blur for improved clarity */
    pointer-events: none;
    z-index: 0;
  }

  /* Media query for small screens */
  @media (max-width: 768px) {
    .layout::before,
    .layout::after {
      filter: blur(40px); /* Further reduced blur for small screens */
    }
  }

  /* Content wrapper for readability */
  .content {
    width: 100%; /* Responsive content width */
    margin: auto;
    padding: 60px 30px; /* Increased padding for more space around content */
    background: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
</style>
