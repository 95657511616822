<script>
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import { auth } from "../lib/stores/auth";
  import { get } from "svelte/store";
  import * as api from "../lib/api";
  import { selectedCharacter, setCharacter } from "../lib/stores/character";
  import Snackbar from "../lib/components/Snackbar.svelte";
  import { showSnackbar } from "../lib/stores/snackbar";

  let authState;
  let itemsList = [];
  let characterList = [];

  async function fetchItems() {
    const data = await api.fetchItems();
    itemsList = data.filter((e) => e.in_shop == true);
  }

  async function fetchUserCharacters() {
    const data = await api.fetchUserCharacters();
    characterList = data;
  }

  onMount(async () => {
    authState = get(auth);
    if (!authState.isAuthenticated) {
      push("/signin");
    } else {
      await Promise.all([fetchItems(), fetchUserCharacters()]);
      setCharacter(characterList[0]);
    }
  });

  async function buyItem(item) {
    try {
      const characterId = $selectedCharacter.id;
      const goldItem = $selectedCharacter.inventory.currency.find(
        (e) => e.item_name === "Gold"
      );

      if (!goldItem || goldItem.quantity < item.cost) {
        showSnackbar(`Not enough Gold to buy ${item.name}!`, "error");
        return;
      }

      const updatedInventory = await api.removeItems(
        [{ id: goldItem.id, quantity: item.cost }],
        characterId
      );

      if (!updatedInventory) {
        showSnackbar(`Failed to buy ${item.name}: Insufficient Gold.`, "error");
        return;
      }

      const finalInventory = await api.addItem(
        [{ id: reagent.id, model: "Items", quantity: 1 }],
        characterId
      );

      if (!finalInventory) {
        showSnackbar(
          `Failed to buy ${item.name}: Error adding to inventory.`,
          "error"
        );
        return;
      }

      $selectedCharacter.inventory = finalInventory;
      showSnackbar(`Successfully Bought ${item.name}!`, "success");
    } catch (error) {
      console.error("Error purchasing item:", error);
      showSnackbar(`Error purchasing ${item.name}.`, "error");
    }
  }
</script>

<slot>
  <div class="page-container">
    <div class="page-header">
      <h1>
        WELCOME {authState?.user?.username.toUpperCase()} TO THE AUCTION HOUSE
      </h1>
      <h3>
        Unfortunately, we are currently closed for renovations. Please try back
        in a couple weeks.
      </h3>
    </div>
    <div class="character-header">
      {#if characterList && characterList.length > 0}
        <h3>Select Character:</h3>
        <select
          id="selectedCharacter"
          class="dropdown"
          bind:value={$selectedCharacter}
        >
          {#each characterList as character}
            <option value={character}>{character.name}</option>
          {/each}
        </select>
        <label for="selectedCharacter"
          >Now bidding for {$selectedCharacter?.name}</label
        >
        <label for="selectedCharacterGold"
          >Available Gold: {$selectedCharacter?.inventory?.currency?.find(
            (e) => e.item_name == "Gold"
          )?.quantity || 0}</label
        >
      {/if}
    </div>

    <div class="character-creation-container">
      <div class="tab-content">
        <div class="items-container">
          {#each itemsList.filter((r) => r.in_auction_house == true) as items}
            <div class="item-card">
              <img
                src={items?.image
                  ? items.image
                  : "/media/images/placeholder.png"}
                alt={items.name}
                class="item-image"
              />
              <span class="item-name">{items.name}</span>
              <p>Current Bid: {items.cost}</p>
            </div>
          {/each}
        </div>
      </div>
    </div>
    <Snackbar />
  </div>
</slot>

<style>
  * {
    box-sizing: border-box;
  }

  .page-container {
    justify-content: center;
    justify-items: center;
    align-items: flex-start;
    padding-top: 80px;
    color: #e0e0ff;
  }

  .page-header {
    justify-content: center;
    justify-items: center;
    align-items: flex-start;
  }

  .character-header {
    display: flex;
    width: 80%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 16px;
  }

  .character-header h3,
  .character-header label,
  .character-header select {
    max-width: 250px;
    padding: 10px;
    margin: 10px;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    margin-bottom: 0px;
  }

  /* Character Creation Container */
  .character-creation-container {
    width: 90%;
    font-family: "Arial", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    background-color: #2a123f;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  }

  .tab-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 40px;
    background-color: #2a123f;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 0 10px 10px 10px;
  }

  /* items & Crafting items */
  .items-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 15px;
    width: 100%;
    z-index: 0;
  }

  .item-card,
  .item-card {
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    background-color: #3d1752;
    border-radius: 8px;
    font-size: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition:
      transform 0.2s,
      box-shadow 0.2s;
  }

  .item-card:hover,
  .item-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
  }

  .item-image,
  .item-image {
    width: 60px;
    height: 60px;
    margin-bottom: 5px;
    border-radius: 50%;
    border: 2px solid #ffcc70;
  }

  .item-card img,
  .item-card img {
    width: 100%;
    height: 100%;
    margin-left: auto;
    border: 4px solid #ffcc70;
    border-radius: 10px;
    background-color: #2a123f;
    pointer-events: none;
    z-index: 0;
  }

  .item-name,
  .item-name {
    color: #ffcc70;
    font-weight: bold;
    margin-top: 5px;
  }

  label {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
  }

  .dropdown {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }

  /* Tooltip Styling */
  /* .tooltip {
    position: absolute;
    padding: 15px;
    background-color: #2a123f;
    border: 2px solid #ffcc70;
    border-radius: 8px;
    color: #e0e0ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    z-index: 100;
    text-align: center;
    width: 700px;
    word-wrap: break-word;
  } */

  h3 {
    color: #ffcc70;
  }

  p {
    color: #e0e0ff;
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }

  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
</style>
