<script>
</script>

<div class="games-container">
  <slot>
    <iframe
      title="Alterra: Dawn Riser Explorer Game"
      frameborder="0"
      src="https://itch.io/embed-upload/9889771?color=333333"
      allowfullscreen=""
      width="1240"
      height="620"
      ><a href="https://creashio.itch.io/alterra-dawn-riser-explorer"
        >Play Alterra: Dawn Riser Explorer on itch.io</a
      ></iframe
    >
  </slot>
</div>

<style>
  .games-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    padding: 15px;
    height: 95vh;
  }

  iframe {
    width: 80vw;
    height: 80vh;
    border: none;
  }
</style>
