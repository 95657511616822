<script>
  import { onMount } from "svelte";
  import { link } from "svelte-spa-router";

  /**
   * @type {any[]}
   */
  let wiki_entries = [];
  let stream_data = {};
  /**
   * @type {any[]}
   */
  let videos = [];
  let video1 = {};
  let video2 = {};
  let video3 = {};

  let carousel_count = 0;
  let left_arrow_active = false;
  let right_arrow_active = true;

  function handleLeftArrowClick() {
    if (left_arrow_active) {
      carousel_count -= 1;
      if (carousel_count === 0) {
        left_arrow_active = false;
      }
      if (!right_arrow_active) {
        right_arrow_active = true;
      }

      if (videos) {
        video1 = videos[carousel_count + 1];
        video2 = videos[carousel_count + 2];
        video3 = videos[carousel_count + 3];
      }
    }
  }

  function handleRightArrowClick() {
    if (right_arrow_active) {
      carousel_count += 1;
      if (carousel_count === videos.length - 4) {
        right_arrow_active = false;
      }
      if (!left_arrow_active) {
        left_arrow_active = true;
      }

      if (videos) {
        video1 = videos[carousel_count + 1];
        video2 = videos[carousel_count + 2];
        video3 = videos[carousel_count + 3];
      }
    }
  }

  onMount(async () => {
    try {
      const response = await fetch("/api/video-featured");
      if (response.ok) {
        const featured_data = await response.json();
        stream_data = featured_data[0];
        wiki_entries = featured_data[0].wiki_entries;
      } else {
        console.error("failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }

    try {
      const response = await fetch("/api/video?page=1");
      if (response.ok) {
        const video_data = await response.json();
        videos = video_data.results;
        video1 = videos[1];
        video2 = videos[2];
        video3 = videos[3];
      } else {
        console.error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  });
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore missing-declaration -->
<!-- svelte-ignore a11y-missing-content -->
<!-- svelte-ignore a11y-missing-attribute -->
<slot>
  <div class="streams-page">
    <div class="video-container-small" style="display: none">
      <div class="video-box">
        <iframe
          width="100%"
          height="100%"
          src={stream_data.embed_link}
          frameborder="0"
          allowfullscreen
        />
      </div>
      <div class="video-description">
        <h3>{stream_data.title} - {stream_data.episode_name}</h3>
        <p>{stream_data.content}</p>
      </div>
    </div>
    <div class="video-section">
      <div class="circle-container">
        <div class="first-circle-column">
          {#each wiki_entries as entry, index}
            {#if index % 2 === 0}
              <a href={`/lore/article/${entry.slug}`} use:link>
                <div class="circle">
                  <span class="tooltip">{entry.title}</span>
                  <img src={entry.image} alt="Character Portrait" />
                </div>
              </a>
            {/if}
          {/each}
        </div>
        <div class="second-circle-column">
          {#each wiki_entries as entry, index}
            {#if index % 2 !== 0}
              <a href={`/lore/article/${entry.slug}`} use:link>
                <div class="circle">
                  <span class="tooltip">{entry.title}</span>
                  <img src={entry.image} alt="Character Portrait" />
                </div>
              </a>
            {/if}
          {/each}
        </div>
      </div>
      <div class="video-container">
        <div class="video-box">
          <iframe
            width="100%"
            height="100%"
            src={stream_data.embed_link}
            frameborder="0"
            allowfullscreen
          />
        </div>
        <div class="video-description">
          <h3>{stream_data.title} - {stream_data.episode_name}</h3>
          <p>{stream_data.content}</p>
        </div>
      </div>
    </div>
    <div class="archive-link">
      <a href="/streams/archive/1" use:link>All Videos</a>
    </div>
    <div class="carousel-section">
      <div class="carousel">
        <div
          class="circle left-arrow"
          class:inactive={!left_arrow_active}
          on:click={handleLeftArrowClick}
        >
          <svg
            xmlns="https://www.w3.org/2000/svg"
            width="64"
            height="64"
            fill="currentColor"
            class="bi bi-arrow-left-circle"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
            />
          </svg>
        </div>
        <div class="carousel-item">
          {#if video1}
            <div class="link-container">
              <iframe
                width="100%"
                height="100%"
                src={video1.embed_link}
                frameborder="0"
              />
              <a
                href={`/streams/video/${video1.slug}`}
                class="overlay-link"
                use:link
              />
            </div>
          {/if}
        </div>
        <div class="carousel-item">
          {#if video2}
            <div class="link-container">
              <iframe
                width="100%"
                height="100%"
                src={video2.embed_link}
                frameborder="0"
              />
              <a
                href={`/streams/video/${video2.slug}`}
                class="overlay-link"
                use:link
              />
            </div>
          {/if}
        </div>
        <div class="carousel-item">
          {#if video3}
            <div class="link-container">
              <iframe
                width="100%"
                height="100%"
                src={video3.embed_link}
                frameborder="0"
              />
              <a
                href={`/streams/video/${video3.slug}`}
                class="overlay-link"
                use:link
              />
            </div>
          {/if}
        </div>
        <div
          class="circle right-arrow"
          class:inactive={!right_arrow_active}
          on:click={handleRightArrowClick}
        >
          <svg
            xmlns="https://www.w3.org/2000/svg"
            width="64"
            height="64"
            fill="currentColor"
            class="bi bi-arrow-right-circle"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</slot>

<style>
  .streams-page {
    width: 85%;
    margin: 0 auto;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
  }

  .video-section {
    flex: 1;
    padding: 20px;
    display: flex;
  }

  .circle-container {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
  }

  .first-circle-column,
  .second-circle-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .first-circle-column {
    margin-top: 50px;
  }

  .circle {
    width: 70px;
    height: 70px;
    background-color: rgba(
      0,
      255,
      106,
      0.5
    ); /* Slightly adjusted for better visibility */
    border-radius: 50%;
    margin-bottom: 15px;
    cursor: pointer;
    position: relative; /* Ensure tooltip positioning works correctly */
  }

  .circle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%; /* Corrected 100% to 50% */
  }

  .tooltip {
    visibility: hidden;
    width: 120px;
    background-color: #1f0620;
    color: #fff;
    text-align: center;
    border-radius: 50px;
    padding: 5px;
    position: absolute;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s;
    bottom: 80%; /* Adjusted tooltip position */
    left: 50%;
    transform: translateX(-50%);
  }

  .circle:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .video-container {
    flex: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 30px;
  }

  .video-box {
    background-color: #000;
    border: 10px solid rgba(192, 192, 192, 1);
    height: 480px;
    width: 100%;
  }

  .video-description {
    color: white;
    margin: 10px 0; /* Simplified margin */
    background-color: rgba(255, 255, 255, 0.1);
    border: 10px solid rgba(255, 255, 255, 0);
    min-height: 150px;
    min-width: 100%;
    padding: 10px; /* Added padding for better spacing */
  }

  .video-description h3 {
    margin: 0; /* Reset margin */
  }

  .video-description p {
    margin: 5px 0 0 5px; /* Adjusted for consistent spacing */
    text-align: left;
  }

  .archive-link a {
    font-family: "Acme", sans-serif;
    text-decoration: none;
    color: white;
    margin-left: 100px;
    font-size: 1.5rem;
  }

  .carousel-section {
    flex: 3;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .carousel {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    transition: transform 0.5s ease;
  }

  .carousel-item {
    background-color: #000;
    color: #fff;
    width: 100%;
    height: 150px;
    margin: 0 2px;
    border: 2px solid rgba(192, 192, 192, 1);
  }

  .link-container {
    position: relative;
  }

  .carousel-item iframe {
    pointer-events: none;
  }

  .overlay-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: transparent;
  }

  .left-arrow,
  .right-arrow {
    margin-top: 40px;
    width: 100px;
    height: 70px;
    background-color: transparent; /* More consistent with design */
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #fff;
  }

  .left-arrow.inactive,
  .right-arrow.inactive {
    opacity: 0.6;
    cursor: not-allowed;
    color: #777;
  }

  .left-arrow {
    margin-right: 15px;
  }

  .right-arrow {
    margin-left: 15px;
  }

  @media (max-width: 768px) {
    .video-container {
      display: none; /* Hide video on small screens */
    }
    .video-container-small {
      display: block !important;
      min-width: 100%;
    }
    .circle-container {
      display: block;
      justify-content: space-between;
      margin: 0 auto; /* Centered on small screens */
    }
    .first-circle-column,
    .second-circle-column {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;
    }
  }
</style>
