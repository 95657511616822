<slot>
  <div class="page-container">
    <div class="games-container">
      <div class="pdf-games">
        <h2>Adventure Modules</h2>
        <div class="map-page">
          <iframe
            title="Alterra: Guildhall Trials Adventure"
            frameborder="0"
            src="https://itch.io/embed/2569218"
            width="552"
            height="167"
          >
            <a
              href="https://creashio.itch.io/alterra-5e-adventure-the-adventurers-guildhall-trial"
            >
              Alterra 5e Adventure: The Adventurers Guildhall Trial by Creashio
            </a>
          </iframe>
        </div>
      </div>

      <div class="map-explorer-games">
        <h2>Map Explorers</h2>
        <div class="map-page">
          <iframe
            title="Alterra: Dawn Riser Explorer Game"
            frameborder="0"
            src="https://itch.io/embed/2571786"
            width="552"
            height="167"
          >
            <a href="https://creashio.itch.io/alterra-dawn-riser-explorer">
              Alterra: Dawn Riser Explorer by Creashio
            </a>
          </iframe>
        </div>
      </div>

      <div class="coming-soon">
        <h2>Pixel RPGs</h2>
        <div class="coming-soon-content">
          <p>Coming Soon</p>
        </div>
      </div>

      <div class="coming-soon">
        <h2>A:TCG</h2>
        <div class="coming-soon-content">
          <p>Coming Soon</p>
        </div>
      </div>
    </div>
    <div class="games-container-full">
      <div class="">
        <h2>Browser Games</h2>
        <div class="">
          <p>Coming Soon</p>
        </div>
      </div>
    </div>
  </div>
</slot>

<style>
  .page-container {
    min-height: 60vh;
  }

  .games-container {
    display: flex;
    justify-content: space-between; /* Space between the sections */
    align-items: flex-start; /* Align items at the start */
    gap: 20px; /* Space between sections */
    color: #fff; /* Keeping the text color white for contrast */
    margin-top: 120px; /* Space above the container */
    padding: 0 20px; /* Padding for better spacing on smaller screens */
    box-sizing: border-box; /* Ensure padding is included in width */
    min-height: 20vh;
  }

  .games-container-full {
    display: flex;
    color: #fff; /* Keeping the text color white for contrast */
    margin-top: 50px; /* Space above the container */
    padding: 0 20px; /* Padding for better spacing on smaller screens */
    box-sizing: border-box; /* Ensure padding is included in width */
  }

  .pdf-games,
  .map-explorer-games,
  .coming-soon {
    flex: 1; /* Allow sections to grow equally */
    max-width: 400px; /* Limit max-width for better spacing */
    padding: 20px; /* Padding for the games sections */
    background-color: rgba(
      34,
      34,
      34,
      0.8
    ); /* Slightly dark background for contrast */
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5); /* Shadow for depth */
    text-align: center; /* Center text in sections */
  }

  .map-page {
    margin-top: 20px; /* Space between the title and iframe */
  }

  .map-page iframe {
    width: 100%; /* Full width for iframe */
    height: auto;
    border: 2px solid rgba(255, 255, 255, 0.5); /* Soft border for iframes */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); /* Shadow for iframes */
  }

  .coming-soon-content {
    margin-top: 20px; /* Space above the coming soon text */
    font-size: 20px; /* Font size for the coming soon text */
    color: #e0b0ff; /* Light purple for contrast */
  }

  h2 {
    font-size: 28px; /* Increased font size for better readability */
    text-align: center; /* Center align headings */
    margin: 20px 0; /* More margin for spacing */
    color: #e0b0ff; /* Light purple for contrast */
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6); /* Added shadow for depth */
  }

  @media (max-width: 768px) {
    .games-container {
      flex-direction: column; /* Stack sections on smaller screens */
      align-items: center; /* Center align items */
      padding: 0 10px; /* Reduce padding on smaller screens */
    }

    .pdf-games,
    .map-explorer-games,
    .coming-soon {
      max-width: 90%; /* Allow full width on mobile */
      margin-bottom: 20px; /* Space between sections */
    }

    h2 {
      font-size: 24px; /* Slightly smaller heading for mobile */
    }

    .pdf-games,
    .map-explorer-games,
    .coming-soon {
      padding: 15px; /* Reduced padding for mobile view */
    }
  }
</style>
