<script>
  import { afterUpdate, onMount } from "svelte";
  import { push, link } from "svelte-spa-router";
  export let params;

  let currentPage;

  $: {
    currentPage = Number(params.page);
  }

  let oldPage = params.page;
  /**
   * @type {any[]}
   */
  let videos = [];
  let totalPages;

  async function fetchData() {
    try {
      const response = await fetch(`/api/video?page=${currentPage}`);
      if (response.ok) {
        const video_data = await response.json();
        videos = video_data.results;
        totalPages = Math.ceil(video_data.count / 9);
      } else {
        console.error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  }

  function prevPage() {
    if (currentPage > 1) {
      goToPage(currentPage - 1);
    }
  }

  function nextPage() {
    if (currentPage < totalPages) {
      goToPage(currentPage + 1);
    }
  }

  function goToPage(newPage) {
    push(`/streams/archive/${newPage}`);
  }

  onMount(() => {
    fetchData();
  });

  afterUpdate(() => {
    if (oldPage != currentPage) {
      oldPage = currentPage;
      fetchData();
    }
  });
</script>

<div class="streams-archive">
  <div class="video-grid">
    {#each videos as video (video.id)}
      <div>
        {#if video.episode_name}
          <h3>{video.title} - {video.episode_name}</h3>
        {:else}
          <h3>{video.title}</h3>
        {/if}
        <div class="video-rectangle">
          <div class="link-container">
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- svelte-ignore element_invalid_self_closing_tag -->
            <iframe
              width="100%"
              height="100%"
              src={video.embed_link}
              frameborder="0"
            />
            <!-- svelte-ignore a11y-missing-content -->
            <!-- svelte-ignore a11y_consider_explicit_label -->
            <!-- svelte-ignore element_invalid_self_closing_tag -->
            <a
              href={`/streams/video/${video.slug}`}
              class="overlay-link"
              use:link
            />
          </div>
        </div>
      </div>
    {/each}
  </div>
  <div class="pagination">
    {#if currentPage > 1}
      <button class="prev-page page-button" on:click={prevPage}>Previous</button
      >
    {/if}
    {#if currentPage > 2}
      <button class="page-button" on:click={() => goToPage(1)}>1</button>
    {/if}
    {#if currentPage > 3}
      <span class="ellipsis">...</span>
    {/if}

    {#if currentPage > 1}
      <button class="page-button" on:click={() => goToPage(currentPage - 1)}
        >{currentPage - 1}</button
      >
    {/if}

    <button class="current-button">{currentPage}</button>

    {#if currentPage < totalPages}
      <button class="page-button" on:click={() => goToPage(currentPage + 1)}
        >{currentPage + 1}</button
      >
    {/if}

    {#if currentPage < totalPages - 2}
      <span class="ellipsis">...</span>
    {/if}
    {#if currentPage < totalPages - 1}
      <button class="page-button" on:click={() => goToPage(totalPages)}
        >{totalPages}</button
      >
    {/if}
    {#if currentPage < totalPages}
      <button class="next-page page-button" on:click={nextPage}>Next</button>
    {/if}
  </div>
</div>

<style>
  .streams-archive {
    width: 85%;
    margin: 10px auto;
    padding: 20px;
    z-index: 1;
  }

  .video-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin: auto;
  }

  .video-grid h3 {
    color: white;
    font-size: 80%;
    margin-left: 10px;
  }

  .video-rectangle {
    width: 300px;
    height: 150px;
    background-color: #000;
    color: #fff;
    margin-left: 5px;
    margin-right: 5px;
    border: 2px solid rgba(192, 192, 192, 1);
  }

  .link-container {
    position: relative;
  }

  .video-rectangle iframe {
    pointer-events: none;
  }

  .overlay-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: transparent;
  }

  .pagination {
    margin-top: 50px;
    text-align: center;
  }

  .page-button {
    padding: 10px 20px;
    margin: 0 7px;
    background-color: white;
    color: #000;
    border: none;
    border-radius: 15px;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;
    cursor: pointer;
  }

  .current-button {
    padding: 10px 20px;
    margin: 0 7px;
    background-color: blueviolet;
    color: #fff;
    border: 1px;
    border-radius: 15px;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;
    cursor: pointer;
  }

  .pagination button:hover {
    background-color: black;
    color: white;
  }

  .ellipsis {
    color: white;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    .video-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 25px;
    }
  }

  @media (max-width: 767px) {
    .video-grid {
      grid-template-columns: repeat(1, 1fr);
    }
    .video-rectangle {
      width: 250px;
      height: 150px;
    }
  }
</style>
