<script>
</script>

<slot>
  <div class="container">
    <div class="hero">
      <div class="welcome-text">Welcome to the World of</div>
      <div class="alterra-text">ALTERRA</div>
      <div class="playground-text">Your Cosmic Playground</div>
    </div>

    <div class="paragraph-text">
      Step into the World of Alterra, where adventure and fantasy meets power
      and mischief! Where the lines between victory and defeat blur with every
      heartbeat, and plans come to rest on the outcome of single dice roll!
      Create your own legendary hero as you embark through the prime realms,
      barter with cosmological beings, and flip of divinities. For better or for
      worst your choices will shape the World, so what will you do? After all,
      this is your cosmic playground!
    </div>
    <div class="paragraph-text">
      <h3>ABOUT ALTERRA</h3>
      Alterra is a planar dyson sphere composing of several prime realms along the
      interior wall, each looking up and inwards towards the astral sea and the celestial
      bodies found within. Watched over by the seven divine patheons, each realm
      is a setting in its own right, with its own power hungry nations, diverse and
      adventurous peoples, and mixed histories. But throughout each of the realms,
      the cosmological facts remain the same. The gods rule distant and supreme.
      The arcane crystal, Arcanite, channels and fuels the magic of both spellcasters
      and items. The imprisoned Altombed and Primordials wage secret wars for freedom.
      And Monsters hunt Noids for their Souls in order to level up.
    </div>
    <div class="paragraph-text">
      Whether it's rebuilding the war-torn continent of Eres, stopping the
      elemental apocalypse on the continent of Geni, or trying to undercover a
      dark mystery in the streets of Amfore, there's bound to be something for
      everyone! So what are you waiting for?
    </div>

    <div class="badges">
      <div class="badge">
        <a href="/#/lore/article/maroon-arcanite"
          ><img
            src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167513/MaroonArcanite_yiqxu1.svg"
            alt="Badge 1"
          /></a
        >
      </div>

      <div class="badge">
        <a href="/#/lore/article/amber-arcanite"
          ><img
            src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167528/AmberArcanite_ocktps.png"
            alt="Badge 2"
          /></a
        >
      </div>

      <div class="badge">
        <a href="/#/lore/article/teal-arcanite"
          ><img
            src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167511/TealArcanite_my16uf.svg"
            alt="Badge 3"
          /></a
        >
      </div>

      <div class="badge">
        <a href="/#/lore/article/avocado-arcanite"
          ><img
            src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167514/AvocadoArcanite_x3lh6s.svg"
            alt="Badge 4"
          /></a
        >
      </div>
    </div>

    <div class="content-container-large">
      <div class="row">
        <div class="column text-column">
          <a href="/#/streams"><h3>STREAMS</h3></a>
          <p>
            Watch our latest streams live on Twitch and influence the story as
            part of the interactive audience. Aid the adventurers to victory and
            glory? Or summon a monster to complicate the plot? The choice is
            yours!
          </p>
          <p>
            Catch all the latest streams <a href="/#/streams">here!</a> Or over
            on the
            <a href="https://www.youtube.com/@worldofalterra">Alterra YouTube</a
            > channel!
          </p>
        </div>
        <div class="column image-column">
          <a href="https://www.twitch.tv/worldofalterra"
            ><img
              src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167512/streams_zxpikp.png"
              alt="Stream Portrait"
            /></a
          >
        </div>
      </div>
      <div class="row">
        <div class="column image-column">
          <img
            src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167503/Stories_rtiz2g.png"
            alt="Stories Portrait"
          />
        </div>
        <div class="column text-column">
          <a href="/#/stories/latest/1"><h3>STORIES</h3></a>
          <p>
            Delve into the heart of Alterra in tales of cosmic wonder and daring
            exploits as you see first hand the life of the average Alterra
            denizen!
          </p>
        </div>
      </div>
      <div class="row">
        <div class="column text-column">
          <a href="/#/games"><h3>GAMES</h3></a>
          <p>
            Explore the world yourself! In Alterra's Games Hub, where pixel art
            RPGs and browser mini-games take center stage. The world is yours
            for the making, or destruction.
          </p>
        </div>
        <div class="column image-column">
          <img
            src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167509/games_pkgqxm.png"
            alt="Games Portrait"
          />
        </div>
      </div>
    </div>

    <div class="content-container-small" style="display: none;">
      <div class="column text-column">
        <a href="/#/streams"><h3>STREAMS</h3></a>
        <p>
          Watch our latest streams live on Twitch and influence the story as
          part of the interactive audience. Aid the adventurers to victory and
          glory? Or summon a monster to complicate the plot? The choice is
          yours!
        </p>
        <p>
          Catch all the <a href="/streams">latest streams here!</a> Or over on
          the
          <a href="https://www.youtube.com/@worldofalterra"
            >Alterra YouTube channel!</a
          >
        </p>
      </div>
      <div class="column image-column">
        <a href="https://www.twitch.tv/worldofalterra"
          ><img
            src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167512/streams_zxpikp.png"
            alt="Stream Portrait"
          /></a
        >
      </div>
      <div class="column text-column">
        <a href="/#/stories/latest/1"><h3>STORIES</h3></a>
        <p>
          Delve into the heart of Alterra in tales of cosmic wonder and daring
          exploits as you see first hand the life of the average Alterra
          denizen!
        </p>
      </div>
      <div class="column image-column">
        <img
          src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167503/Stories_rtiz2g.png"
          alt="Stories Portrait"
        />
      </div>
      <div class="column text-column">
        <a href="/#/games"><h3>GAMES</h3></a>
        <p>
          Explore the world yourself! In Alterra's Games Hub, where pixel art
          RPGs and browser mini-games take center stage. The world is yours for
          the making, or destruction.
        </p>
      </div>
      <div class="column image-column">
        <img
          src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167509/games_pkgqxm.png"
          alt="Games Portrait"
        />
      </div>
    </div>

    <div class="discover-container"></div>
  </div></slot
>

<style>
  .container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 15px;
    max-width: 75%;
    margin: 0 auto;
    text-align: center;
  }

  .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 150px;
    padding-bottom: 50px;
    grid-column: span 12;
  }

  .welcome-text {
    font-size: 48px;
    color: #ffffff;
    margin-left: 160px;
    margin-bottom: -30px;
  }

  a {
    text-decoration: none;
    color: white;
  }

  .alterra-text {
    font-family: "Uncial Antiqua";
    font-size: 150px;
    color: #cee9ff;
    margin-bottom: -30px;
  }

  .playground-text {
    font-size: 25px;
    color: #ffffff;
    margin-left: 450px;
  }

  .paragraph-text {
    font-size: 20px;
    color: #ffffff;
    grid-column: span 12;
    text-align: left;
    margin-top: 20px;
  }

  .paragraph-text h3 {
    color: #cee9ff;
  }

  .badges {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    grid-column: span 12;
    justify-content: space-between;
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .badge {
    width: 100%;
    /* border: 2px solid #c0c0c0;
		border-radius: 10px; */
    overflow: hidden;
  }

  .badge img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 0%;
    max-width: 200px;
    max-height: 200px;
  }

  .content-container-large {
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-column: span 12;
  }

  .content-container-small {
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-column: span 12;
  }

  .row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 50px;
  }

  .column {
    flex: 1;
    padding: 10px;
  }

  .text-column {
    color: white;
    text-align: left;
  }

  .text-column h3 {
    font-weight: bold;
    font-size: 28px;
  }

  .text-column p {
    font-size: 20px;
  }

  .text-column a {
    color: #cee9ff;
  }

  /* .image-column {
	} */

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    /* border-radius: 100%; */
  }

  .discover-container {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    grid-column: span 12;
  }

  /* .discover-container h3 {
		font-size: 24px;
		margin-bottom: 20px;
		color: white;
		font-family: 'Acme', sans-serif;
	}

	.box-container {
		display: flex;
		justify-content: center;
		gap: 30px;
	}

	.box {
		width: 300px;
		height: 280px;
		background-color: white;
		border-radius: 60px;
	} */

  @media (min-width: 768px) and (max-width: 991px) {
    .welcome-text {
      font-size: 36px;
      margin-left: 120px;
      margin-bottom: -22px;
    }

    .alterra-text {
      font-size: 112px;
      margin-bottom: -22px;
    }

    .playground-text {
      font-size: 18px;
      margin-left: 337px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .welcome-text {
      font-size: 27px;
      margin-left: 90px;
      margin-bottom: -16px;
    }

    .alterra-text {
      font-size: 84px;
      margin-bottom: -16px;
    }

    .playground-text {
      font-size: 13px;
      margin-left: 252px;
    }
  }
  @media (max-width: 575px) {
    .container {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: 0px;
      max-width: 100%;
      margin-left: 20px;
      margin-right: 20px;
    }

    .welcome-text {
      font-size: 20px;
      margin-left: 67px;
      margin-bottom: -12px;
    }

    .alterra-text {
      font-size: 63px;
      margin-bottom: -12px;
    }

    .playground-text {
      font-size: 9px;
      margin-left: 189px;
    }

    .content-container-large {
      display: none;
    }

    .content-container-small {
      display: block !important;
    }
  }
</style>
