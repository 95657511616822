<script>
  import * as api from "../../api";
  import { selectedCharacter } from "../../stores/character";
  import { showSnackbar } from "../../stores/snackbar";

  export let recipesList = [];
  const craftingTypes = ["alchemy", "artificery", "cooking"];
  let activeFilterRecipes = "alchemy";

  function setFilterRecipes(filter) {
    activeFilterRecipes = filter;
  }

  async function buyRecipe(recipe) {
    if (!$selectedCharacter) return;

    try {
      const characterId = $selectedCharacter.id;
      const goldRequired = recipe.cost;

      const goldItem = $selectedCharacter.inventory.currency.find(
        (c) => c.item_name.toLowerCase() === "gold"
      );

      if (!goldItem || goldItem.quantity < goldRequired) {
        showSnackbar("Not enough gold to buy this recipe!", "error");
        return;
      }

      const updatedInventory = await api.removeItems(
        [{ id: goldItem.id, quantity: goldRequired }],
        characterId
      );

      if (!updatedInventory) {
        showSnackbar(
          `Failed to purchase ${recipe.name}: Insufficient gold.`,
          "error"
        );
        return;
      }

      const finalInventory = await api.addItem(
        [{ id: recipe.id, model: "Recipes", quantity: 1 }],
        characterId
      );

      if (!finalInventory) {
        showSnackbar(`Failed to add ${recipe.name} to inventory.`, error);
        return;
      }

      // Update inventory and notify user
      $selectedCharacter.inventory = finalInventory;
      showSnackbar(`Successfully Bought ${recipe.name}!`, "success");
    } catch (error) {
      console.error("Error buying recipe:", error);
      showSnackbar(`Error buying ${recipe.name}.`, "error");
    }
  }
</script>

<div class="nav-buttons">
  {#each craftingTypes as type}
    <button
      class:active-filter={type === activeFilterRecipes}
      on:click={() => setFilterRecipes(type)}>{type}</button
    >
  {/each}
</div>

<div class="crafting-recipes-container">
  {#each recipesList.filter((r) => r.type === activeFilterRecipes) as recipe}
    <div class="recipe-card">
      <img
        src={recipe?.image
          ? `https://res.cloudinary.com/dw39fffyv/${recipe.image}`
          : "https://res.cloudinary.com/dw39fffyv/image/upload/v1742167494/placeholder_lzsl1l.gif"}
        alt={recipe.name}
        class="recipe-image"
      />
      <span class="recipe-name">{recipe.name}</span>
      <button
        class="buy-button"
        disabled={recipe.cost >
          ($selectedCharacter?.inventory?.currency.find(
            (e) => e.item_name == "Gold"
          )?.quantity || 0) ||
          $selectedCharacter?.inventory?.recipes.some(
            (r) => r.item_name === recipe.name
          )}
        on:click={() => buyRecipe(recipe)}
      >
        {#if $selectedCharacter?.inventory?.recipes.some((r) => r.item_name === recipe.name)}
          Already Owned
        {:else}
          BUY ({recipe.cost}gp)
        {/if}
      </button>
    </div>
  {/each}
</div>

<style>
  .crafting-recipes-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    width: 100%;
    z-index: 0;
  }

  .recipe-card {
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #3d1752;
    border-radius: 8px;
    font-size: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition:
      transform 0.2s,
      box-shadow 0.2s;
  }

  .recipe-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
  }

  .recipe-image {
    width: 60px;
    height: 60px;
    margin-bottom: 5px;
    border-radius: 50%;
    border: 2px solid #ffcc70;
  }

  .recipe-card img {
    width: 100%;
    height: 100%;
    margin-left: auto;
    border: 4px solid #ffcc70;
    border-radius: 10px;
    background-color: #2a123f;
    pointer-events: none;
    z-index: 0;
  }

  .recipe-name {
    color: #ffcc70;
    font-weight: bold;
    margin-top: 5px;
  }

  .nav-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }

  .nav-buttons button {
    background-color: #3d1752;
    color: #ffcc70;
    border: none;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .nav-buttons button.active-filter {
    background-color: #ffcc70;
    color: #2a123f;
    font-weight: bold;
  }

  .buy-button {
    width: 120px;
    margin-top: 10px;
    padding: 10px 5px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 2px;
    font-size: 1rem;
    cursor: pointer;
  }

  .buy-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
</style>
