<script>
  import { onMount } from "svelte";
  export let params;

  let storyId;

  $: {
    storyId = params.storyId;
  }

  let story = {};

  function formatDate(dateString) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }

  onMount(async () => {
    try {
      const response = await fetch(`/api/stories/${storyId}`);
      if (response.ok) {
        const data = await response.json();
        story = data;
      } else {
        console.error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  });
</script>

<slot>
  <div class="colored-rectangle">
    <h1 class="story-header">{story.title}</h1>
    <div class="story-category-container">
      <h5 class="story-category">{story.tags}</h5>
    </div>
    <p class="story-date">
      <strong>{formatDate(story.published_date)}</strong> - by {story.author}
    </p>
    <p class="story-text">{@html story.content}</p>
  </div>
</slot>

<style>
  .colored-rectangle {
    width: 75%;
    margin: 100px auto;
    padding: 30px; /* Increased padding for better text spacing */
    background-color: rgba(
      215,
      181,
      148,
      0.9
    ); /* Slight transparency for blending */
    border: 5px solid rgba(188, 151, 96, 1); /* Thinner, solid border for contrast */
    min-height: 80%;
    border-radius: 15px;
    font-family: "Acme", sans-serif;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7); /* Darker shadow for depth */
    background-image: url("/media/images/papertexture.jpeg"); /* Texture image URL */
    background-size: cover; /* Ensures the texture covers the entire area */
    background-position: center 10%; /* Moves the texture slightly upwards */
    position: relative; /* Needed for the overlay */
  }

  .colored-rectangle::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(
      215,
      181,
      148,
      0.365
    ); /* Semi-transparent overlay color */
    border-radius: 15px; /* Match border radius */
    z-index: 0; /* Place behind text */
  }

  .story-header,
  .story-date,
  .story-category-container,
  .story-category,
  .story-text {
    position: relative; /* Place text above overlay */
    z-index: 1; /* Ensure text is above the overlay */
  }

  .story-category-container {
    background-color: #ff7a00; /* Bright orange for category */
    color: #fff; /* White text */
    border-radius: 8px;
    text-align: center;
    padding: 5px; /* Increased padding for better spacing */
    max-width: 80%;
    margin: 10px auto; /* Centered with margin */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Shadow for depth */
  }

  .story-category {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold; /* Added bold for emphasis */
  }

  .story-header,
  .story-date {
    text-align: center;
  }
  .story-header {
    font-size: 42px; /* Increased font size for better visibility */
    margin-top: 50px;
    margin-bottom: 5px;
    color: #e0b0ff; /* Light purple for contrast with the background */
    font-weight: bold; /* Make the header bold */
    text-shadow:
      2px 2px 4px rgba(0, 0, 0, 0.6),
      1px 1px 2px rgba(162, 0, 255, 0.6); /* Added shadow with a hint of purple */
  }

  .story-date {
    font-size: 18px; /* Increased font size for better visibility */
    color: #e0b0ff; /* Light purple for the date */
    margin-top: 10px; /* Increased margin for better spacing */
    font-weight: 500; /* Medium weight for clarity */
    text-shadow:
      1px 1px 3px rgba(0, 0, 0, 0.4),
      1px 1px 2px rgba(162, 0, 255, 0.4); /* Added shadow with a hint of purple */
  }

  .story-text {
    font-size: 16px;
    margin-top: 10px;
    color: #333; /* Dark grey for better readability */
  }

  @media (max-width: 768px) {
    .colored-rectangle {
      width: 90%; /* Responsive width on smaller screens */
    }

    .story-header {
      font-size: 20px; /* Responsive font size */
    }

    .story-date {
      font-size: 12px; /* Responsive font size */
    }

    .story-text {
      font-size: 14px; /* Responsive font size */
    }
  }
</style>
