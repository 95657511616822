<script>
  export let show = false;
  export let onClose;

  function closeModal() {
    onClose();
    document.body.style.overflow = "auto";
  }

  function openModal() {
    document.body.style.overflow = "hidden";
  }
</script>

{#if show}
  {@html openModal()}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <div class="modal-backdrop" on:click={closeModal}></div>
  <div class="modal">
    <div class="modal-content">
      <slot></slot>
      <button class="close" on:click={closeModal}>X</button>
    </div>
  </div>
{/if}

<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
  .modal {
    width: 80%;
    max-width: 600px;
    max-height: 80vh;
    overflow-y: auto;
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    z-index: 20;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  .modal-content {
    position: relative;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
  }
</style>
