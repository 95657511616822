<script>
  import { afterUpdate, onMount } from "svelte";
  import { push, link } from "svelte-spa-router";
  export let params;

  let currentQuery;
  let currentPage;

  $: {
    currentQuery = params.query;
    currentPage = Number(params.page);
  }

  let oldQuery = params.query;
  let oldPage = Number(params.page);

  /**
   * @type {any[]}
   */
  let topStorys = [];
  /**
   * @type {any[]}
   */
  let storys = [];
  let totalPages;

  async function fetchTopData() {
    try {
      const response = await fetch(`/api/news-storys-featured`);
      if (response.ok) {
        const topStorys_data = await response.json();
        topStorys = topStorys_data;
      } else {
        console.error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  }

  async function fetchData() {
    try {
      const response = await fetch(
        `/api/news-storys?search=${currentQuery}&page=${currentPage}`
      );
      if (response.ok) {
        const storys_data = await response.json();
        storys = storys_data.results;
        totalPages = Math.ceil(storys_data.count / 9);
      } else {
        console.error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  }

  function formatDate(dateString) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }

  function prevPage() {
    if (currentPage > 1) {
      currentPage -= 1;
    }
  }

  function nextPage() {
    if (currentPage < totalPages) {
      currentPage += 1;
    }
  }

  function goToPage(newPage) {
    push(`/news/search/${currentQuery}/${newPage}`);
  }

  let searchText = "";

  function handleSearch() {
    if (searchText) {
      push(`/news/search/${searchText}/1`);
    }
  }

  function handleKeyPress(event) {
    if (event.key === "Enter") {
      handleSearch();
    }
  }

  onMount(() => {
    fetchTopData();
    fetchData();
  });

  afterUpdate(() => {
    if (oldPage != currentPage) {
      oldPage = currentPage;
      fetchData();
    }
    if (oldQuery != currentQuery) {
      oldQuery = currentQuery;
      fetchData();
    }
  });
</script>

<!-- svelte-ignore element_invalid_self_closing_tag -->
<!-- svelte-ignore a11y-missing-attribute -->
<slot>
  <div class="colored-rectangle">
    <div class="row">
      <div class="row-container" />
      <div class="row-container">
        <div class="section-title">ALL STORIES</div>
      </div>
      <div class="row-container">
        <div class="search-bar-container">
          <input
            type="text"
            class="search-bar"
            placeholder="Search..."
            bind:value={searchText}
            on:keydown={handleKeyPress}
          />
          <button on:click={handleSearch} class="filter-button">Go</button>
        </div>
      </div>
    </div>
    <div class="story-grid">
      {#each storys as story}
        <a class="story-link" href="/news/story/{story.slug}" use:link>
          <div class="story-rectangle">
            <h2 class="story-headers">{story.title}</h2>
            <div class="story-details">
              <div class="category">
                {story.tags}
              </div>
              <h5 class="published-date">
                {formatDate(story.published_date)}
              </h5>
            </div>
            <p class="excerpt">by {story.author}</p>
          </div>
        </a>
      {/each}
    </div>
  </div>
  <div class="pagination">
    {#if currentPage > 1}
      <button class="prev-page page-button" on:click={prevPage}>Previous</button
      >
    {/if}
    {#if currentPage > 2}
      <button class="page-button" on:click={() => goToPage(1)}>1</button>
    {/if}
    {#if currentPage > 3}
      <span class="ellipsis">...</span>
    {/if}

    {#if currentPage > 1}
      <button class="page-button" on:click={() => goToPage(currentPage - 1)}>
        {currentPage - 1}
      </button>
    {/if}

    <button class="current-button">{currentPage}</button>

    {#if currentPage < totalPages}
      <button class="page-button" on:click={() => goToPage(currentPage + 1)}>
        {currentPage + 1}
      </button>
    {/if}

    {#if currentPage < totalPages - 2}
      <span class="ellipsis">...</span>
    {/if}
    {#if currentPage < totalPages - 1}
      <button class="page-button" on:click={() => goToPage(totalPages)}>
        {totalPages}
      </button>
    {/if}
    {#if currentPage < totalPages}
      <button class="next-page page-button" on:click={nextPage}>Next</button>
    {/if}
  </div>
</slot>

<style>
  .colored-rectangle {
    width: 95%;
    margin: 10px auto;
    padding: 30px; /* Increased padding for better text spacing */
    background-color: rgba(
      215,
      181,
      148,
      0.9
    ); /* Slight transparency for blending */
    border: 5px solid rgba(188, 151, 96, 1); /* Thinner, solid border for contrast */
    min-height: 80%;
    border-radius: 15px;
    font-family: "Acme", sans-serif;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7); /* Darker shadow for depth */
    background-image: url("https://res.cloudinary.com/dw39fffyv/image/upload/v1742167495/papertexture_we5bse.jpg"); /* Add a texture image URL here */
    background-size: cover; /* Ensures the texture covers the entire area */
    background-position: center; /* Centers the texture */
  }

  .row {
    display: flex;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  .row-container {
    width: 33.33%;
  }

  .section-title {
    font-family: "Acme", sans-serif;
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    color: #210742; /* Dark purple for visibility */
  }

  .search-bar-container {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: center;
    color: #210742; /* Dark purple for visibility */
  }

  .search-bar {
    width: 180px; /* Increased width for better usability */
    padding: 10px;
    margin-left: 50px;
    border: 1px solid #210742; /* Match the background for better integration */
    text-align: center;
    border-radius: 5px; /* Added radius for consistency */
  }

  .filter-button {
    background-color: rgba(215, 181, 148, 0.9); /* Slightly transparent */
    color: #210742; /* Dark purple text */
    border: 1px solid #210742; /* Dark purple border */
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 5px; /* Added radius for consistency */
  }

  .filter-button:hover {
    background-color: rgba(188, 151, 96, 1); /* Darker shade on hover */
  }

  .story-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px; /* Increased gap for a better visual */
  }

  .story-link {
    text-decoration: none;
  }

  .story-rectangle {
    width: 325px; /* Increased width for wider cards */
    height: 200px; /* Kept height for balance */
    background-color: rgba(
      222,
      228,
      229,
      0.7
    ); /* Semi-transparent for a softer look */
    color: #000; /* Black text for readability */
    margin-left: auto;
    margin-right: auto;
    border-radius: 5%;
    box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.5); /* More noticeable shadow */
    padding: 10px;
  }

  .story-details {
    display: flex;
    flex-direction: column; /* Changed to column for vertical alignment */
    margin-left: 5px;
  }

  .category {
    background-color: #d9534f; /* Red for categories */
    color: #fff;
    border-radius: 10px;
    padding: 7px;
    min-width: 60px;
    text-transform: uppercase;
    font-size: 10px;
    text-align: center;
    font-family: "Acme", sans-serif;
  }

  .published-date {
    font-weight: bold;
    font-size: 14px;
    color: #661778; /* Lighter tan for contrast */
    margin-top: 5px; /* Added margin for spacing */
  }

  .story-headers {
    font-weight: bold;
    margin-top: 15px; /* Adjusted for consistency */
    text-align: center;
    color: #210742; /* Dark purple for headers */
  }

  .excerpt {
    font-size: 14px;
    margin-top: 5px;
    margin-left: 10px;
    color: #333; /* Dark grey for better readability */
  }

  .pagination {
    margin-top: 50px;
    text-align: center;
  }

  .page-button {
    padding: 10px 20px;
    margin: 0 7px;
    background-color: white;
    color: #210742; /* Dark purple text */
    border: 1px solid #210742; /* Border to match */
    border-radius: 15px;
    transition:
      background-color 0.3s ease,
      color 0.3s ease; /* Smooth transition */
    cursor: pointer;
  }

  .current-button {
    padding: 10px 20px;
    margin: 0 7px;
    background-color: blueviolet; /* Current page highlight */
    color: #fff;
    border: 1px solid blueviolet; /* Border to match background */
    border-radius: 15px;
    transition:
      background-color 0.3s ease,
      color 0.3s ease; /* Smooth transition */
    cursor: pointer;
  }

  .pagination button:hover {
    background-color: black;
    color: white;
  }

  .ellipsis {
    color: #210742; /* Adjusted for consistency */
  }

  @media (max-width: 1400px) {
    .search-bar {
      width: 120px; /* Responsive width */
    }

    .story-grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
  }

  @media (max-width: 950px) {
    .search-bar {
      width: 100px; /* Responsive width */
    }

    .filter-button {
      padding: 10px 20px;
    }

    .story-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
    }
  }

  @media (max-width: 900px) {
    .search-bar {
      width: 80px; /* Responsive width */
    }

    .filter-button {
      padding: 10px 20px;
    }
  }

  @media (max-width: 768px) {
    .search-bar {
      width: 70px; /* Responsive width */
    }

    .filter-button {
      padding: 10px 10px;
    }
  }

  @media (max-width: 568px) {
    .story-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
    }
    .story-rectangle {
      width: 250px; /* Adjusted for smaller screens */
    }
    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .search-bar {
      width: 50px; /* Responsive width */
    }

    .filter-button {
      padding: 10px 5px;
    }
  }
</style>
