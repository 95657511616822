<script>
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import { getValidAccessToken } from "../lib/stores/auth";
  import { auth } from "../lib/stores/auth";
  import { get } from "svelte/store";
  import CharacterModal from "../lib/components/CharacterModal.svelte";

  let authState;
  let characters = [];
  let selectedCharacter = null;

  // Lists
  let cultureList = [];
  let heritageList = [];
  let trainingList = [];
  let alignmentList = [];

  onMount(async () => {
    authState = get(auth);
    if (!authState.isAuthenticated) {
      push("/signin");
    } else {
      try {
        await fetchCultures();
        await fetchHeritages();
        await fetchTrainings();
        await fetchAlignments();
        characters = await fetchUserCharacters();
      } catch (error) {
        console.error("Error fetching characters:", error);
      }
    }
  });

  // Fetch cultures
  async function fetchCultures() {
    const response = await fetch("/api/cultures");
    const data = await response.json();
    cultureList = data;
  }

  // Fetch heritages
  async function fetchHeritages() {
    const response = await fetch("/api/heritages");
    const data = await response.json();
    heritageList = data;
  }

  // Fetch trainings
  async function fetchTrainings() {
    const response = await fetch("/api/trainings");
    const data = await response.json();
    trainingList = data;
  }

  // Fetch alignments
  async function fetchAlignments() {
    const response = await fetch("/api/alignments");
    const data = await response.json();
    alignmentList = data;
  }

  async function fetchUserCharacters() {
    const token = await getValidAccessToken();
    const response = await fetch(`/api/characters`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    return data;
  }

  function getCultureNameById(id) {
    const culture = cultureList.find((item) => item.id === id);
    return culture ? culture.name : "Unknown Culture";
  }

  function getHeritageNameById(id) {
    const heritage = heritageList.find((item) => item.id === id);
    return heritage ? heritage.name : "Unknown Heritage";
  }

  function getTrainingNameById(id) {
    const training = trainingList.find((item) => item.id === id);
    return training ? training.name : "Unknown Training";
  }

  function getAlignmentNameById(id) {
    const alignment = alignmentList.find((item) => item.id === id);
    return alignment ? alignment.name : "Unknown Alignment";
  }

  function openCharacterModal(character) {
    selectedCharacter = character;
  }

  function closeCharacterModal() {
    selectedCharacter = null;
  }
</script>

<slot>
  <div class="account-page">
    <section class="top-line">
      <div class="page-buttons">
        <button on:click={() => push("/arg")}>ARG</button>
        <button on:click={() => push("/shop")}>Shop</button>
        <button on:click={() => push("/craft-station")}>Craft Station</button>
        <button on:click={() => push("/auction-house")}>Auction House</button>
        <button on:click={() => push("/character-creation")}
          >Character Creation</button
        >
      </div>
      <div class="license-container" aria-labelledby="license-heading">
        <h2 id="license-heading" class="license-header">
          Adventurer's License
        </h2>
        <div class="profile-info-grid">
          <label for="username">Username:</label>
          <p id="username">{authState?.user ? authState.user.username : ""}</p>

          <label for="email">Email:</label>
          <p class="email-special" id="email">
            {authState?.user ? authState.user.email : ""}
          </p>

          <label for="class">Class:</label>
          <p id="class">Adventurer</p>
        </div>
      </div>
    </section>

    <section class="character-list" aria-labelledby="character-heading">
      <h2 id="character-heading">Your Characters</h2>
      {#if characters.length > 0}
        <div class="character-grid">
          {#each characters as character (character.id)}
            <div class="character-card">
              <button
                class="character-card"
                on:click={() => openCharacterModal(character)}
                aria-label={`View details for ${character.name}`}
              >
                <img
                  class="character-image"
                  src={character.image
                    ? character.image
                    : "https://res.cloudinary.com/dw39fffyv/image/upload/v1742167494/placeholder_lzsl1l.gif"}
                  alt={`Image of ${character.name}`}
                />
                <div class="badge-info">
                  <h3 class="character-name">{character.name}</h3>
                  <p class="heritage">
                    {getHeritageNameById(character.heritage_1)} / {getHeritageNameById(
                      character.heritage_2
                    )}
                  </p>
                  <p class="alignment-training">
                    {getAlignmentNameById(character.alignment)}
                    {getTrainingNameById(character.training)} from {getCultureNameById(
                      character.culture
                    )}
                  </p>
                </div>

                <div class="character-stats">
                  <p class="stat-label">Level</p>
                  <p class="stat-value">{character.level}</p>
                  <p class="stat-label">EXP</p>
                  <p class="stat-value">{character.experience}</p>
                </div>
              </button>
            </div>
          {/each}
        </div>
      {:else}
        <p>No characters created yet.</p>
      {/if}

      {#if selectedCharacter}
        <CharacterModal
          character={selectedCharacter}
          {cultureList}
          {trainingList}
          {heritageList}
          {alignmentList}
          onClose={closeCharacterModal}
        />
      {/if}
    </section>
  </div>
</slot>

<style>
  .account-page {
    margin-top: 50px;
    min-height: 80vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .top-line {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: left;
    width: 100%;
  }

  .page-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Space between buttons */
    margin-left: 20px;
    margin-right: 20px;
    padding: 20px;
    width: 150px; /* Optional: Adjust to your layout */
    background-color: #fdf4e3; /* Light gray background */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }

  .page-buttons button {
    width: 100%; /* Buttons take full width of container */
    padding: 10px 15px;
    font-size: 16px;
    color: #fff; /* White text */
    background-color: #7c005a; /* Primary blue color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .page-buttons button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }

  .page-buttons button:active {
    background-color: #004494; /* Even darker on click */
  }

  .license-container {
    width: 100%;
    max-width: 450px;
    height: auto;
    padding: 15px;
    background-color: rgba(215, 181, 148, 0.9);
    border: 5px solid #bc9760;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    background-image: url("https://res.cloudinary.com/dw39fffyv/image/upload/v1742167495/papertexture_we5bse.jpg");
    background-size: cover;
    background-position: center;
  }

  .license-header {
    font-size: 1.8rem;
    text-align: center;
    color: #333;
    margin-bottom: 15px;
  }

  .profile-info-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    padding: 10px;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
  }

  .profile-info-grid label {
    font-weight: bold;
    color: #333;
  }

  .profile-info-grid p {
    margin: 5px 0;
    color: #555;
  }

  .character-list {
    margin-top: 50px;
    text-align: center;
    width: 100%;
  }

  .character-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 30px;
    justify-content: center;
    margin-top: 20px;
  }

  .character-card {
    cursor: pointer;
    width: 100%;
    max-width: 250px;
    height: 350px;
    border: 5px solid rgb(107, 80, 0);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background: #fdf4e3;
    transition:
      transform 0.2s ease,
      box-shadow 0.2s ease;
    position: relative;
    overflow: hidden;
  }

  .character-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }

  .character-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 12px;
    border: 3px solid #6b5000;
  }

  .badge-info {
    text-align: center;
    flex-grow: 1;
  }

  .character-name {
    font-size: 1.25rem;
    margin: 0 0 8px 0;
    color: #333;
  }

  .heritage,
  .alignment-training {
    font-size: 0.9rem;
    color: #555;
    margin: 4px 0;
  }

  .character-stats {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .stat-label {
    font-size: 0.9rem;
    color: #888;
  }

  .stat-value {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }

  @media (max-width: 768px) {
    .license-container {
      margin: 5px auto;
      width: 100%;
      max-width: 450px;
      height: auto;
      padding: 5px;
      background-color: rgba(215, 181, 148, 0.9);
      border: 5px solid #bc9760;
      border-radius: 10px;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
      background-image: url("https://res.cloudinary.com/dw39fffyv/image/upload/v1742167495/papertexture_we5bse.jpg");
      background-size: cover;
      background-position: center;
    }

    .license-header {
      font-size: 1rem;
      text-align: center;
      color: #333;
      margin-bottom: 15px;
    }

    .profile-info-grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.5rem;
      padding: 5px;
      text-align: start;
      max-width: 90%;
      margin: 0 auto;
    }

    .profile-info-grid label {
      font-weight: bold;
      color: #333;
    }

    .profile-info-grid p {
      margin: 5px 0;
      color: #555;
    }

    .character-grid {
      grid-template-columns: 1fr;
      margin-top: 15px;
    }

    .character-card {
      width: 90%;
      margin: 0 auto 20px;
    }

    .email-special {
      word-wrap: break-word; /* Breaks long words to prevent overflow */
      overflow-wrap: break-word; /* Ensures long strings like email addresses break */
      word-break: break-all; /* Ensures long email addresses break across the line if necessary */
      white-space: normal; /* Ensures that wrapping is allowed */
      word-break: break-word; /* Older browsers might still need this */
    }
  }
</style>
