<script>
  import { onMount } from "svelte";
  let tab = "questlogBackground";

  function changeImage(newSrc) {
    tab = newSrc;

    if (newSrc === "loreBackground" || newSrc === "questlogBackground") {
      document.querySelector(".page-container").style.height = "200%";
    } else {
      document.querySelector(".page-container").style.height = "95vh";
    }
  }

  onMount(() => {
    document.querySelector(".page-container").style.height = "200%";
  });
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<slot>
  <div class="page-container">
    <div class="sidebar">
      <div class="circle-gap"></div>
      <button
        class="circle-button blue-border"
        on:click={() => changeImage("loreBackground")}
      >
        Lore
      </button>
      <button
        class="circle-button green-border"
        on:click={() => changeImage("media/images/Amfore.png")}
      >
        Map
      </button>
      <button
        class="circle-button orange-border"
        on:click={() => changeImage("questlogBackground")}
      >
        Investigation Journal
      </button>
    </div>
    <div class="content-container">
      {#if tab === "media/images/Amfore.png"}
        <img src={tab} alt="Amfore" class="map-image" />
      {:else if tab === "loreBackground"}
        <div class="lore-text">
          <p class="disclaimer">
            Disclaimer: Welcome to our dark and traumatic RPG! We strive to
            create an engaging and inclusive environment for everyone. If you
            have any specific phobias, triggers, or content concerns that the
            Keeper should be aware of, please don't hesitate to reach out to me
            (Chris Mckenzie '@chrisalterradm') directly. We will do our best to
            accommodate your needs and inform you of any potential issues in
            advance to ensure a comfortable and enjoyable experience for all
            players.
          </p>
          <h3>Previously on Amfore</h3>
          <p>
            Two global superpowers dominate Amfore in the aftermath of Heaven's
            Fall: the Federal Regions of Amfore (R.A.) and the Colony Union
            (Jacks). The two nations stand at odds ideologically, engaging in
            proxy wars, espionage, and subversive operations to expand their
            influence. Officially, both governments reject the existence of
            magic and Heavenite’s power, focusing on technological progress and
            military might. However, in secret, both nations are locked in a
            covert race to uncover the potential of Heavenite, believing it may
            hold the key to world domination.
          </p>
          <h4>Key Terms:</h4>
          <li>
            <b>Collony Union (Jacks).</b> The Colony Union is a tightly controlled
            superpower born from a combination of imperialism and state collectivism.
            Its society is rigidly hierarchical, with the government exerting total
            control over its citizens. The Union promotes strict discipline, unity,
            and loyalty to the state, using heavy propaganda to maintain order. The
            capital cities are grand and imposing, with a blend of imperial architecture
            and austere, utilitarian designs, reflecting the state’s control over
            every aspect of life. While the Jacks outwardly dismiss the existence
            of magic, they are obsessed with uncovering the potential of Heavenite.
            The Union’s rulers believe that this material, if harnessed properly,
            could give them the ultimate advantage over the R.A. and secure their
            dominance over Amfore. Secret research programs focus on weaponizing
            Heavenite, while the state continues its aggressive expansion, seeking
            to control valuable resources and colonies.
          </li>
          <li>
            <b>The Federal Regions of Amfore (R.A).</b> A federation of powerful
            industrial cities, the R.A. values individual freedom, capitalism, and
            technological advancement. Its cities are bustling centers of commerce
            and innovation, characterized by towering skyscrapers, roaring factories,
            and a growing middle class. However, the R.A. is also riddled with corruption,
            with crime syndicates, powerful corporations, and influential elites
            pulling the strings of government behind the scenes. While promoting
            the ideals of freedom and opportunity, the country faces increasing social
            inequality and political unrest. The R.A. officially rejects any notion
            of magic, seeing itself as a beacon of progress. It invests heavily in
            research, particularly in weaponry, energy, and industrial technology.
            However, shadowy government agencies quietly investigate Heavenite’s
            properties, fearing that the Jacks may unlock its secrets first.
          </li>
          <li>
            <b>Heaven's Fall (2,000 Years Ago).</b> The event known as Heaven’s Fall
            occurred when a celestial object collided with the Blue Moon, scattering
            fragments of a rare material called Heavenite across the surface of Amfore.
            According to legend, this cataclysm marked the end of several ancient
            civilization, who allegedly wielded magical powers. After the event,
            all traces of magic seemingly vanished, leading the modern world to dismiss
            these ancient stories as myths. However, some suspect that Heavenite
            holds untapped powers that are yet to be understood.
          </li>
          <li>
            <b>The Cold War.</b> The R.A. and the Jacks are locked in a tense, undeclared
            conflict, known as the Cold War. Though no formal battles are fought
            between the two superpowers, they engage in espionage, propaganda wars,
            and proxy conflicts in smaller nations. Both sides secretly seek to control
            deposits of Heavenite, using mercenaries, spies, and loyal factions to
            fight these covert battles. As each side vies for control, the fear of
            direct conflict looms, with the world teetering on the edge of all-out
            war.
          </li>
          <li>
            <b>The Proxy Region</b> The Proxy Region is a fragmented and volatile
            collection of small kingdoms, dictatorships, and emerging republics.
            Once home to ancient empires and long-standing monarchies, the region
            is now a battleground where the Federal Regions of Amfore (R.A.) and
            the Colony Union (Jacks) extend their cold war. These small nations are
            often caught in the crossfire, either aligning with one of the superpowers
            for protection or struggling to maintain their independence amidst the
            constant pressure. The R.A. supports factions that promise democracy
            and free markets, while the Jacks back regimes that promote collectivism
            and authoritarian rule. As these nations fight proxy wars on behalf of
            their larger patrons, the Proxy Region is a hotbed of intrigue, espionage,
            and political instability. Some of these states have their own agendas,
            using the rivalry between the R.A. and Jacks to their advantage. Meanwhile,
            rumors abound that ancient ruins, untouched since the time of Heaven’s
            Fall, lie beneath the region’s crumbling cities and war-torn landscapes—places
            where Heavenite is buried and carefully avoided, for its presence weakens
            any remaining traces of ancient magic.
          </li>
          <li>
            <b>The Wild Frontier.</b> The Wild Frontier is a rugged, lawless expanse
            on the fringes of civilization, where pioneers, outlaws, and fortune
            seekers clash in the pursuit of freedom and fortune. The landscape is
            vast and untamed, filled with arid plains, mountain ranges, and vast
            deserts. Isolated settlements dot the region, many founded by homesteaders
            hoping to carve out new lives far from the political control of the R.A.
            and the Jacks. Though far from the reach of the major powers, both governments
            keep a watchful eye on the Wild Frontier, as the discovery of rare Heavenite
            deposits has turned it into a magnet for private enterprises and rogue
            factions. Rival mining companies, mercenary groups, and criminal syndicates
            vie for control of these resources, leading to constant skirmishes and
            shifting alliances. Life here is harsh, with little law or order, and
            the Frontier's spirit is defined by independence and the pursuit of fortune—often
            at great personal risk. The presence of Heavenite in the Frontier is
            particularly dangerous. Its ability to suppress any lingering magical
            anomalies or ancient powers makes it a sought-after resource, but its
            influence has also created eerie, dead zones where strange phenomena
            have been reported. Some believe that buried beneath the Wild Frontier
            are relics from the time before Heaven’s Fall, locked away by the suppressing
            power of Heavenite.
          </li>
          <li>
            <b>The Frozen North.</b> The Frozen North is a desolate, frozen wasteland
            where few dare to venture. Endless expanses of ice and snow stretch across
            this harsh region, where survival is a constant struggle against bitter
            cold, treacherous terrain, and isolation. However, beneath the ice, ancient
            secrets lie buried. The remnants of forgotten civilizations—ruins, artifacts,
            and strange anomalies—are said to be hidden deep within the glaciers
            and frozen caves, remnants from the time before Heaven’s Fall. The R.A.
            and the Jacks both send expeditions to the Frozen North, hoping to uncover
            whatever secrets the ice may hold, though few return with answers. Some
            believe that the region contains clues to the true nature of Heaven’s
            Fall, while others fear what they might awaken in the frozen depths.
            The environment is treacherous, with sudden storms and vast crevasses
            claiming lives regularly, but it is also one of the only places where
            Heavenite seems to naturally occur in significant quantities, adding
            to the tension between the superpowers. Heavenite’s suppressive properties
            are especially strong in the Frozen North, where any trace of lingering
            ancient power is silenced. Despite this, strange phenomena have been
            reported by explorers—whispers in the wind, shadows that move against
            the light, and inexplicable mechanical ruins of unknown origin. The few
            who survive expeditions into the depths of the Frozen North return haunted,
            convinced that whatever lies buried beneath the ice was never meant to
            be uncovered.
          </li>
          <li>
            <b>Dark Lengeds and Psionic Cults.</b> Beneath the political tensions,
            proxy wars, and the race for control over Heavenite, there are whispered
            rumors of something far darker lurking in the shadows of Amfore. Across
            the world, from the grand cities of the R.A. to the frozen wastes of
            the North, stories persist of ancient, malevolent forces—psionic entities—whose
            influence predates even Heaven’s Fall. These beings are said to exist
            beyond the veil of reality, manipulating minds, twisting perceptions,
            and feeding off the madness they sow. Some claim that the ancient civilizations
            themselves might have fallen not simply due to the impact of Heaven’s
            Fall, but because they attempted to harness these entities' psionic power,
            only to be consumed by it. Despite the rationalism and scientific advances
            of the modern age, cults dedicated to these dark entities thrive in the
            hidden corners of society, drawn by promises of forbidden knowledge and
            power. These cults believe that psionic energy—mental and psychic manipulation—can
            open gateways to these ancient beings, and they seek to awaken them once
            more. In cities like New Helix and Ironminster, strange disappearances,
            unsettling visions, and cases of mass hysteria are attributed to these
            cults, though the authorities publicly deny any connection. In the desolate
            Wild Frontier and the frozen reaches of the North, travelers tell stories
            of strange temples, maddening symbols carved into the earth, and voices
            that call from deep within the earth and ice. The presence of Heavenite
            seems to enhance these entities' power, amplifying their psionic influence
            and fueling their sinister ambitions. This may be why they seek to return—to
            reclaim their dominion over the world, once suppressed, but now bolstered
            by the very material scattered across Amfore after Heaven’s Fall. The
            more Heavenite is unearthed, the stronger these entities become, and
            their dark reach spreads further, drawing cults and followers into their
            fold as they prepare to reassert control over the waking world.. While
            most dismiss these tales as superstitions or the ramblings of the deranged,
            investigators and scholars have quietly documented disturbing patterns.
            Across Amfore, those who probe too deeply into the ancient ruins, cult
            gatherings, or unexplained phenomena often meet grisly ends or descend
            into madness. Even more troubling are the increasing reports of psionic
            abilities manifesting in seemingly ordinary people, often accompanied
            by violent outbursts or mental breakdowns, as if something is pushing
            through the cracks of reality.
          </li>
          <li>
            <b>Ironminster (Capital of the Colony Union).</b> Ironminster is the
            heart of the Colony Union, a city of imposing grandeur and iron discipline.
            Once a symbol of imperial power, its grand architecture now serves as
            a stark reminder of the Union's collectivist regime. Towering, brutalist
            buildings dominate the skyline, with vast government complexes and state-run
            factories at the city’s core. The city pulses with rigid order, where
            every street, monument, and square reflects the Party's iron grip over
            the people. Ironminster’s streets are tightly controlled, filled with
            military parades, propaganda banners, and the watchful eyes of the state’s
            secret police. The city’s residents live in a world of constant surveillance,
            where loyalty to the Union is both a necessity and a virtue. Despite
            the harsh control, the city is a center of advanced research and production,
            with the Party prioritizing industrial output and military development.
            Beneath its stern surface, however, Ironminster is riddled with secrets.
            Rumors circulate of psionic experiments conducted in hidden government
            labs, and cults worshiping dark entities operate in the shadows, exploiting
            the city’s harsh, repressive environment. These cults, drawn by the city’s
            stockpiles of Heavenite, quietly grow in power, their influence spreading
            beneath the state’s strict control. Many believe that Ironminster's rulers
            know of this growing threat, but are either too fearful—or too involved—to
            intervene.
          </li>
          <li>
            <b>New Helix (Capital of the Federal Regions of Amfore, R.A.).</b> New
            Helix is the thriving capital of the Federal Regions of Amfore, a bustling
            metropolis of innovation, wealth, and ambition. Often described as the
            pinnacle of the R.A.’s progress, the city is a symbol of the nation’s
            industrial might, commercial success, and cultural vibrancy. Its skyline
            is defined by towering skyscrapers, art deco buildings, and massive corporate
            headquarters that symbolize the boundless opportunities available to
            its citizens. At the heart of New Helix is Federal Square, the political
            center where the government’s major institutions operate. Although democracy
            and freedom are heavily promoted, the city's true power lies in the hands
            of wealthy industrialists and influential corporate magnates who use
            their resources to shape policy and manipulate the nation's direction.
            Corruption runs deep in the city, with organized crime, political backroom
            deals, and corporate interests often steering the course of the government.
            Despite this, New Helix is a cultural beacon, home to jazz clubs, theaters,
            art galleries, and intellectual movements. It’s a place where fortunes
            can be made or lost in an instant, where innovation thrives alongside
            greed. In this thriving chaos, rumors of dark psionic cults and strange
            phenomena connected to Heavenite circulate beneath the surface. The government
            officially denies any existence of magic or psionic threats, but in secret,
            New Helix harbors those who seek to manipulate Heavenite for power, pushing
            the boundaries of science—and sanity
          </li>
        </div>
      {:else if tab === "questlogBackground"}
        <div class="lore-text">
          <h3>Investigation Journal</h3>
          <p>[Shadows of the Deep]</p>
        </div>
        <div class="quest-text">
          <h4>Part 1: Welcome to Blackwater Cove.</h4>
          <p>
            This is where our investigators story begins... one cold autumn day,
            having just arrived or awoken in the small fishing village of
            Blackwater Cove, on the outskirts of the Colony Union Empire. It was
            a misty Azrealandai morning, the Celestial Lights were only a soft
            blue hint in the sea above, expanding across it like a glacier,
            obscuring the vast cosmic sea for another day. The rumour of several
            missing individuals, gone in the dead of night, piqued each of our
            investigators interest, for one reason or another, and soon the
            group found themselves meeting in The Night Tyrant, the local
            tavern, unaware of just how much their fates had been tied together
            from that one meeting. For better, or for far far worse.
          </p>
          <p>
            The following are a collection of notes, collected or noted by the
            investigators on their first day in Blackwater Cove. (Better
            formatting pending...)
          </p>
          <li>
            It's the beginning of Autumn, and night falls around 6 to 7pm.
          </li>
          <li>
            The Investigator, Lydia, believes they saw an 8ft tall antler-shaped
            shadow on their way into town.
          </li>
          <li>
            The Investigators discover the details of the missing individuals;
          </li>
          <li>
            --- Jenkins Coppersled. Known as the local drunk, Jenkins is the
            uncle to Derek Smith, owner of The Night Tyrant tavern, and is known
            for going out into the cove to have drinks on one of the islands
            that dots the coast.
          </li>
          <li>
            --- The Mackerel Brothers. Liam and Neil Mackerel, known as the
            troublemakers of the town, the pair are known for their early
            fishing, scrapyard business, and all around handyman work, both
            licensed work and unlicensed.
          </li>
          <li>
            --- Juniper Wood and Ash Tanaka. A young couple that rented a
            row-boat for a late evening tryst, and have yet to return days
            later.
          </li>
          <li>
            Magistrate Crowely Inkstain. The Local Magistrate, he is responsible
            for the investigation and prosecution of any unlawful behaviour in
            the village. He maintains the power to summon the local milita, or
            request reinforcement from Soldiers station in Ironminster.
          </li>
          <li>
            Mr Owens. A crotchety Dwarven harbourmaster. Doesn't believe any
            shenanigans are afoot, and has a grudge against Magistrate Inkstain.
            It would appear his butler was recently skinned and is now dead.
          </li>
          <li>
            Notable Mentions: Jenny, the town's gossip. Roger, the town's
            annoying child. Freddie, the town's down on his luck dock hand.
            Alfred, the town's dead butler. Jack Wood, the owner of the Sawmill.
            Mr Tanaka, the town's banking manager. Bill Hintsman and Jeremy
            Springfield, names mentioned on the two land ownership certificates.
            Damien Inkstain, Crowely's late father and the town's previous
            magistrate. Charlie, the town's quiet polite child (has the
            Milkman's ears). William Prime, portrait artest from Ironminster.
          </li>
          <li>
            Notable Locations: The Night Tyrant tavern, the local and only
            tavern. The Sawmill, an important part of the town's economy. The
            Harbour, an important part of the town's economy. Ironvault's Black
            Cove Branch Offices, where the townsfolk go for loans, monetary
            needs, and financial advise. The "Unofficial Scrapyard", the home
            and workplace of the Mackerel Brothers. Notlac Hill, the home and
            audience chamber of the town's magistrate. Vantage Peak, an
            outcropping that extends out over the shore, is the highest point in
            the village. The Storm Caves, a series of hollowed out coves and
            caves beneath Vantage Peak that contain a large quantity of clams,
            and is said to connect to underground tunnels used for smuggling.
          </li>
        </div>
      {/if}
    </div>
  </div>
</slot>

<style>
  * {
    box-sizing: border-box;
  }

  .page-container {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    padding: 15px;
    height: 95vh;
  }

  .sidebar {
    width: 12%;
    box-sizing: border-box;
    padding: 20px;
    border: 10px solid #632d06;
    border-right: none;
    border-radius: 10px 0 0 10px;
    background-color: #2e1713;
  }

  .circle-gap {
    margin-top: 20px;
  }

  .circle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    border-radius: 50%;
    background-color: #282d30;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
  }

  /* .yellow-border {
    border: 2px solid #f1c40f;
  } */

  .orange-border {
    border: 2px solid orange;
  }

  .green-border {
    border: 2px solid #0ff116;
  }

  .blue-border {
    border: 2px solid #0f9af1;
  }

  .disclaimer {
    margin-bottom: 50px;
    color: lightblue;
  }

  .content-container {
    width: 100%;
    border: 10px solid #632d06;
    border-radius: 0 10px 10px 0;
    background: rgba(0, 0, 0, 0.453);
  }

  .content-container img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .lore-text {
    padding: 50px;
    color: white;
    text-align: center;
  }

  .lore-text p {
    text-align: center;
  }

  .lore-text h4 {
    text-align: left;
    margin-top: 50px;
  }

  .lore-text li {
    text-align: left;
    margin-left: 15px;
    margin-bottom: 25px;
    list-style-type: none;
  }

  .quest-text {
    padding: 50px;
    color: white;
    margin-left: 20px;
    text-align: start;
  }

  .quest-text li {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    .page-container {
      flex-direction: column;
    }

    .sidebar {
      width: 100%;
      padding: 10px;
      display: flex;
      border: none;
      background-color: transparent;
      justify-content: space-around;
    }

    .circle-button {
      height: 50px;
    }

    .content-container {
      border: none;
      background: transparent;
    }

    .lore-text,
    .quest-text {
      padding: 20px;
    }

    .lore-text h4 {
      margin-top: 20px;
    }
  }
</style>
