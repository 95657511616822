<script>
  let username = "";
  let email = "";
  let password = "";
  let confirmPassword = "";
  let message = "";

  async function handleSignup() {
    if (password !== confirmPassword) {
      message = "Passwords do not match.";
      return;
    }

    const userData = {
      username,
      email,
      password,
      bio: "Profile Bio",
      exp: 0,
      level: 1,
    };

    try {
      const response = await fetch("/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        message = "Registration successful. You can now log in.";
        window.location.href = "/#/signin";
      } else {
        const data = await response.json();
        message = `Registration failed: ${data.detail}`;
      }
    } catch (error) {
      console.error(`Error: ${error}`);
      message = "An error occurred during registration.";
    }
  }
</script>

<div class="signup-container">
  <div class="signup-box">
    <h2>Sign Up</h2>
    <div class="input-container">
      <label for="username">Username</label>
      <input
        id="username"
        type="text"
        autocomplete="username"
        bind:value={username}
        required
      />
      <p class="requirements">
        Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
      </p>
    </div>
    <div class="input-container">
      <label for="email">Email Address</label>
      <input
        id="email"
        type="email"
        autocomplete="email"
        bind:value={email}
        required
      />
    </div>
    <div class="input-container">
      <label for="password">Password</label>
      <input id="password" type="password" bind:value={password} required />
      <ul class="ul-requirements">
        <li>Your password must contain at least 8 characters.</li>
        <li>
          Your password can’t be too similar to your other personal information.
        </li>
        <li>Your password can’t be a commonly used password.</li>
        <li>Your password can’t be entirely numeric.</li>
      </ul>
    </div>
    <div class="input-container">
      <label for="confirm-password">Password Confirmation</label>
      <input
        id="confirm-password"
        type="password"
        bind:value={confirmPassword}
        required
      />
      <p class="requirements">
        Enter the same password as before, for verification.
      </p>
    </div>
    <button class="signup-button" on:click={handleSignup}>Sign Up</button>
    {#if message}
      <p class="message">{message}</p>
    {/if}
  </div>
</div>

<style>
  .signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: 120px;
    margin-bottom: 50px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .signup-box {
    background-color: white;
    border-radius: 50px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    max-width: 100%;
  }

  .signup-box h2 {
    font-size: 36px;
    font-family: "Acme", sans-serif;
  }

  .input-container {
    margin-top: 35px;
    text-align: center;
  }

  .input-container label {
    display: block;
    font-weight: bold;
  }

  .input-container input {
    padding: 14px;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 1px;
    border: 3px solid #000;
    border-radius: 30px;
    background-color: #f3f2f2;
    font-family: "Acme", sans-serif;
    width: 100%;
    box-sizing: border-box;
  }

  .input-container p {
    margin-top: 5px;
    font-family: "Acme", sans-serif;
    text-align: center;
  }

  .requirements,
  .ul-requirements {
    text-align: left;
    margin-top: 10px;
    font-family: "Acme", sans-serif;
    font-size: 12px;
    padding: 0 10px;
  }

  .ul-requirements li {
    margin: 0;
    padding: 0;
    text-align: left;
    margin-left: 15%;
  }

  .signup-button {
    width: 100%;
    background-color: #0078d4;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 50px;
    margin-top: 50px;
    cursor: pointer;
    font-family: "Acme", sans-serif;
    font-size: 18px;
    font-weight: bold;
  }

  .signup-button:hover {
    background-color: #005daf;
  }

  .message {
    font-family: "Acme", sans-serif;
    font-size: 14px;
    margin-top: 20px;
    color: red;
  }
</style>
