<script>
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import { get } from "svelte/store";
  import { auth } from "../lib/stores/auth";
  import { setCharacter } from "../lib/stores/character";
  import CharacterSelection from "../lib/components/CraftStation/CharacterSelection.svelte";
  import * as api from "../lib/api";
  import CraftTabs from "../lib/components/CraftStation/CraftTabs.svelte";
  import Snackbar from "../lib/components/Snackbar.svelte";

  let authState;

  let reagentsList = [];
  let recipesList = [];
  let baseList = [];
  let powerList = [];
  let characterList = [];

  onMount(async () => {
    authState = get(auth);
    if (!authState.isAuthenticated) {
      push("/signin");
    } else {
      try {
        const [reagents, recipes, baseMaterials, powerSources, characters] =
          await Promise.all([
            api.fetchReagents(),
            api.fetchRecipes(),
            api.fetchBaseMaterials(),
            api.fetchPowerSources(),
            api.fetchUserCharacters(),
          ]);
        reagentsList = reagents;
        recipesList = recipes;
        baseList = baseMaterials;
        powerList = powerSources;
        characterList = characters;
        if (characters.length > 0) {
          setCharacter(characters[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  });
</script>

<slot>
  <div class="page-container">
    <div class="page-header">
      <h1>
        WELCOME {authState?.user?.username.toUpperCase()} TO RAPEL'S ARTIFICERY AND
        CRAFT STUDIO
      </h1>
      <h3>May Artos guide your thoughts and hands.</h3>
    </div>
    <CharacterSelection {characterList} />
    <CraftTabs {reagentsList} {recipesList} />
    <Snackbar />
  </div>
</slot>

<style>
  * {
    box-sizing: border-box;
  }

  .page-container {
    justify-content: center;
    justify-items: center;
    align-items: flex-start;
    padding-top: 80px;
    color: #e0e0ff;
  }

  .page-header {
    justify-content: center;
    justify-items: center;
    align-items: flex-start;
  }

  /* Character Creation Container */
  h3 {
    color: #ffcc70;
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }

  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
</style>
