<script>
  import { writable } from "svelte/store";
  import { onMount } from "svelte";
  import { getValidAccessToken } from "../lib/stores/auth";
  import { push } from "svelte-spa-router";
  import { auth } from "../lib/stores/auth";
  import { get } from "svelte/store";

  let authState;
  let currentView = writable("intro");
  let showRightPanel = false;

  let errorMessage = "";
  let showSnackbar = false;

  // Character Info
  let characterName = "";
  let characterBackstory = "";
  let characterDescription = "";
  let characterTraits = "";
  let characterNotes = "";

  let selectedCulture = null;
  let selectedHeritage1 = null;
  let selectedHeritage2 = null;
  let selectedTraining = null;
  let selectedAlignment = null;

  let selectedCultureName = "";
  let selectedHeritage1Name = "";
  let selectedHeritage2Name = "";
  let selectedTrainingName = "";
  let selectedAlignmentName = "";

  // Stats
  let characterBody = 1;
  let characterMind = 1;
  let characterSoul = 1;
  let characterLuck = 1;

  let remainingAbilityPoints = 9;

  // Lists
  let cultureList = [];
  let heritageList = [];
  let trainingList = [];
  let alignmentList = [];

  $: characterSheetFilledIn =
    characterName &&
    selectedCulture &&
    selectedHeritage1 &&
    selectedHeritage2 &&
    selectedTraining &&
    selectedAlignment &&
    characterBody &&
    characterMind &&
    characterSoul &&
    characterLuck;

  // Fetch data from API on component mount
  onMount(async () => {
    authState = get(auth);
    if (!authState.isAuthenticated) {
      push("/signin");
    } else {
      await fetchCultures();
      await fetchHeritages();
      await fetchTrainings();
      await fetchAlignments();
    }
  });

  function increaseScore(statName) {
    if (statName == "characterBody") {
      if (characterBody < 6 && remainingAbilityPoints > 0) {
        characterBody += 1;
        remainingAbilityPoints -= 1;
      }
    }
    if (statName == "characterMind") {
      if (characterMind < 6 && remainingAbilityPoints > 0) {
        characterMind += 1;
        remainingAbilityPoints -= 1;
      }
    }
    if (statName == "characterSoul") {
      if (characterSoul < 6 && remainingAbilityPoints > 0) {
        characterSoul += 1;
        remainingAbilityPoints -= 1;
      }
    }
    if (statName == "characterLuck") {
      if (characterLuck < 6 && remainingAbilityPoints > 0) {
        characterLuck += 1;
        remainingAbilityPoints -= 1;
      }
    }
  }

  function decreaseScore(statName) {
    if (statName == "characterBody") {
      if (characterBody > 1) {
        characterBody -= 1;
        remainingAbilityPoints += 1;
      }
    }
    if (statName == "characterMind") {
      if (characterMind > 1) {
        characterMind -= 1;
        remainingAbilityPoints += 1;
      }
    }
    if (statName == "characterSoul") {
      if (characterSoul > 1) {
        characterSoul -= 1;
        remainingAbilityPoints += 1;
      }
    }
    if (statName == "characterLuck") {
      if (characterLuck > 1) {
        characterLuck -= 1;
        remainingAbilityPoints += 1;
      }
    }
  }

  // Fetch cultures
  async function fetchCultures() {
    const response = await fetch("/api/cultures");
    const data = await response.json();
    cultureList = data;
  }

  // Fetch heritages
  async function fetchHeritages() {
    const response = await fetch("/api/heritages");
    const data = await response.json();
    heritageList = data;
  }

  // Fetch trainings
  async function fetchTrainings() {
    const response = await fetch("/api/trainings");
    const data = await response.json();
    trainingList = data;
  }

  // Fetch alignments
  async function fetchAlignments() {
    const response = await fetch("/api/alignments");
    const data = await response.json();
    alignmentList = data;
  }

  function selectCulture(data) {
    selectedCulture = data.id;
    selectedCultureName = data.name;
  }

  function selectHeritage1(data) {
    selectedHeritage1 = data.id;
    selectedHeritage1Name = data.name;
  }

  function selectHeritage2(data) {
    selectedHeritage2 = data.id;
    selectedHeritage2Name = data.name;
  }

  function selectTraining(data) {
    selectedTraining = data.id;
    selectedTrainingName = data.name;
  }

  function selectAlignment(data) {
    selectedAlignment = data.id;
    selectedAlignmentName = data.name;
  }

  // Set the current view
  function setView(view) {
    currentView.set(view);
    showRightPanel =
      view == "origins" || view == "training" || view == "details";
  }

  function validCharacter() {
    if (
      characterName &&
      selectedCulture &&
      selectedHeritage1 &&
      selectedHeritage2 &&
      selectedTraining &&
      selectedAlignment &&
      characterBody &&
      characterMind &&
      characterSoul &&
      characterLuck
    ) {
      return true;
    }
    return false;
  }

  async function createNewCharacter() {
    if (!validCharacter()) {
      return;
    }
    const characterData = {
      name: characterName,
      culture: selectedCulture,
      heritage_1: selectedHeritage1,
      heritage_2: selectedHeritage2,
      training: selectedTraining,
      alignment: selectedAlignment,
      body: characterBody,
      mind: characterMind,
      soul: characterSoul,
      luck: characterLuck,
      backstory: characterBackstory,
      decription: characterDescription,
      traits: characterTraits,
      notes: characterNotes,
    };

    const token = await getValidAccessToken();
    try {
      const response = await fetch("/api/characters/post/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(characterData),
      });
      if (!response.ok) {
        throw new Error("Failed to create character");
      } else {
        errorMessage = "";
        resetForm();
        showSnackbar = true;

        // Hide Snackbar after 3 seconds
        setTimeout(() => {
          showSnackbar = false;
        }, 3000);
      }
    } catch (error) {
      errorMessage = error.message;
    }
  }

  function resetForm() {
    characterName = "";
    characterBackstory = "";
    characterDescription = "";
    characterTraits = "";
    characterNotes = "";

    selectedCulture = null;
    selectedHeritage1 = null;
    selectedHeritage2 = null;
    selectedTraining = null;
    selectedAlignment = null;

    characterBody = 1;
    characterMind = 1;
    characterSoul = 1;
    characterLuck = 1;

    remainingAbilityPoints = 9;
  }
</script>

<slot>
  <div class="page-container">
    <div class="character-creation-container">
      <!-- Sidebar Navigation -->
      <div class="sidebar">
        {#each ["intro", "origins", "training", "abilities", "details"] as view}
          <button
            class="sidebar-button {$currentView === view
              ? 'activate'
              : 'deactivate'}"
            on:click={() => setView(view)}
          >
            {#if view === "intro"}Intro{:else if view === "origins"}Origins{:else if view === "training"}Power{:else if view === "abilities"}Abilities{:else}Details{/if}
          </button>
        {/each}
      </div>

      <!-- Main View Section -->
      <div class="view-container content">
        {#if $currentView === "abilities"}
          <div class="full-view-container">
            <h3>Set Abilities</h3>
            <br />

            <!-- Display remaining ability points as yellow circles -->
            <div class="power-circles">
              {#each Array(remainingAbilityPoints) as _, index}
                <span class="power-circle" title="Ability Point"></span>
              {/each}
            </div>

            <div class="ability-row">
              <label for="characterBody">Body:</label>
              <div class="ability-controls">
                <button
                  class="control-button"
                  on:click={() => decreaseScore("characterBody")}>-</button
                >

                <!-- Display circles based on the score -->
                <div class="ability-circles">
                  {#each Array(6) as _, index}
                    <span
                      class="ability-circle {index < characterBody
                        ? 'active'
                        : ''}"
                    ></span>
                  {/each}
                </div>

                <button
                  class="control-button"
                  on:click={() => increaseScore("characterBody")}>+</button
                >
              </div>
            </div>

            <div class="ability-row">
              <label for="characterMind">Mind:</label>
              <div class="ability-controls">
                <button
                  class="control-button"
                  on:click={() => decreaseScore("characterMind")}>-</button
                >

                <!-- Display circles based on the score -->
                <div class="ability-circles">
                  {#each Array(6) as _, index}
                    <span
                      class="ability-circle {index < characterMind
                        ? 'active'
                        : ''}"
                    ></span>
                  {/each}
                </div>

                <button
                  class="control-button"
                  on:click={() => increaseScore("characterMind")}>+</button
                >
              </div>
            </div>

            <div class="ability-row">
              <label for="characterSoul">Soul:</label>
              <div class="ability-controls">
                <button
                  class="control-button"
                  on:click={() => decreaseScore("characterSoul")}>-</button
                >

                <!-- Display circles based on the score -->
                <div class="ability-circles">
                  {#each Array(6) as _, index}
                    <span
                      class="ability-circle {index < characterSoul
                        ? 'active'
                        : ''}"
                    ></span>
                  {/each}
                </div>

                <button
                  class="control-button"
                  on:click={() => increaseScore("characterSoul")}>+</button
                >
              </div>
            </div>

            <div class="ability-row">
              <label for="characterLuck">Luck:</label>
              <div class="ability-controls">
                <button
                  class="control-button"
                  on:click={() => decreaseScore("characterLuck")}>-</button
                >

                <!-- Display circles based on the score -->
                <div class="ability-circles">
                  {#each Array(6) as _, index}
                    <span
                      class="ability-circle {index < characterLuck
                        ? 'active'
                        : ''}"
                    ></span>
                  {/each}
                </div>

                <button
                  class="control-button"
                  on:click={() => increaseScore("characterLuck")}>+</button
                >
              </div>
            </div>
          </div>
        {:else if $currentView === "intro"}
          <!-- Intro View -->
          <div class="full-view-container">
            <h3>
              Welcome to the World of Alterra. Tell us a bit about yourself!
            </h3>
            <br />
            <p>
              Your character in Alterra is a unique mix of past experiences,
              present ambitions, and future destiny. Choose what cards they were
              dealt in life by selecting their Origins, the place where they
              grew up, and the lineage and heritage they inherited. Define the
              source of their Power through their training and alignment. Shape
              their Abilities, which include physical strength, mental acuity,
              magical capacity, and even the amount of luck destiny has granted
              them. Finally, give them a name and bring them to life!
            </p>
            <br />
            <br />
            <p>
              <b>Origins:</b> The Origins tab lets you define your Character’s past
              with three main options: one Culture and two Heritages.
            </p>
            <p>
              <li>
                <b>Culture:</b> Your Culture provides a glimpse into your Character's
                upbringing, traditions, and early influences, whether through alignment
                or rebellion. Did growing up in the floating desert City of Salarin
                grant them a knack for magic? Or did the rigorous weapons training
                of the Elven City of Highgarden allow them to master any weapon?
              </li>
            </p>
            <p>
              <li>
                <b>First and Second Heritage:</b> While Culture shapes the broader
                environment, Heritages reflect their family lineage, traditions,
                and inherent powers. What secrets or powers did their family history
                bestow upon them?
              </li>
            </p>
            <br />
            <br />
            <p>
              <b>Power:</b> The Power tab lets you define the current path of your
              Character through their Training and Alignment.
            </p>
            <p>
              <li>
                <b>Training:</b> This represents the formal education or craft mastery
                your Character has pursued, from the disciplined path of an Arcane
                Academy Wizard to the focused training of a nomadic Monk.
              </li>
            </p>
            <p>
              <li>
                <b>Alignment:</b> This defines how your Character’s power manifests
                and the style they embrace. For instance, are they a Monk expressing
                their martial arts through Celestial forms, or a warrior from the
                Mountain Monasteries, fighting with cold precision?
              </li>
            </p>
            <br />
            <br />
            <p>
              <b>Abilities:</b> The Abilities tab lets you define your Character's
              capabilities: Body, Mind, Soul, and Luck.
            </p>
            <p>
              <li>
                <b>Body:</b> This determines physical strength and agility.
              </li>
              <li>
                <b>Mind:</b> This reflects your Character’s perceptiveness and intelligence.
              </li>
              <li>
                <b>Soul:</b> This represents their magical energy, willpower, and
                resistance to manipulation.
              </li>
              <li>
                <b>Luck:</b> Sometimes, the world favors the bold with an unexpected
                coin or a lucky break. However, Luck is fickle, and even the best
                fortune cannot shield you from fate.
              </li>
            </p>
          </div>
        {:else if $currentView === "origins"}
          <!-- Origins Selection -->
          <div class="culture-view">
            <h3>Select Culture</h3>
            <div class="default-buttons">
              {#each cultureList as culture}
                <button
                  class:selected={culture.name == selectedCultureName}
                  on:click={() => selectCulture(culture)}
                >
                  {culture.name}
                </button>
              {/each}
            </div>
            <br />
            <br />

            <div class="heritage-buttons">
              <h3>First Heritage</h3>
              <div class="default-buttons">
                {#each heritageList as heritage}
                  <button
                    class:selected={heritage.name == selectedHeritage1Name}
                    on:click={() => selectHeritage1(heritage)}
                  >
                    {heritage.name}
                  </button>
                {/each}
              </div>
              <br />
              <br />

              <h3>Second Heritage</h3>
              <div class="default-buttons">
                {#each heritageList as heritage}
                  <button
                    class:selected={heritage.name == selectedHeritage2Name}
                    on:click={() => selectHeritage2(heritage)}
                  >
                    {heritage.name}
                  </button>
                {/each}
              </div>
            </div>
          </div>
        {:else if $currentView === "training"}
          <!-- Training Selection -->
          <div class="training-view">
            <h3>Select Training</h3>
            <div class="default-buttons">
              {#each trainingList as training}
                <button
                  class:selected={training.name == selectedTrainingName}
                  on:click={() => selectTraining(training)}
                >
                  {training.name}
                </button>
              {/each}
            </div>
            <br />
            <br />
            <h3>Select Alignment</h3>
            <div class="alignment-buttons">
              <div class="default-buttons">
                {#each alignmentList as alignment}
                  <button
                    class:selected={alignment.name == selectedAlignmentName}
                    on:click={() => selectAlignment(alignment)}
                  >
                    {alignment.name}
                  </button>
                {/each}
              </div>
            </div>
          </div>
        {:else if $currentView === "details"}
          <!-- Character Details Inputs -->
          <div class="home-view">
            <h2>Character Details</h2>

            <!-- Character Name Input -->
            <div class="input-group">
              <label for="name">Character Name:</label>
              <input
                id="name"
                type="text"
                bind:value={characterName}
                placeholder="Enter your character's name (required)"
                required
              />
            </div>

            <!-- Optional: Character Backstory -->
            <div class="input-group">
              <label for="backstory">Character Backstory:</label>
              <textarea
                id="backstory"
                bind:value={characterBackstory}
                placeholder="Write your character's backstory (optional)"
                rows="4"
              ></textarea>
            </div>

            <!-- Optional: Physical Description -->
            <div class="input-group">
              <label for="description">Physical Description:</label>
              <textarea
                id="description"
                bind:value={characterDescription}
                placeholder="Describe your character's appearance (optional)"
                rows="3"
              />
            </div>

            <!-- Optional: Personality Traits -->
            <div class="input-group">
              <label for="traits">Personality Traits:</label>
              <textarea
                id="traits"
                bind:value={characterTraits}
                placeholder="List your character's traits (optional)"
                rows="3"
              ></textarea>
            </div>

            <!-- Optional: Additional Notes -->
            <div class="input-group">
              <label for="notes">Additional Notes:</label>
              <textarea
                id="notes"
                bind:value={characterNotes}
                placeholder="Add any other details about your character (optional)"
                rows="6"
              ></textarea>
            </div>
          </div>
        {/if}

        <!-- Character Creation Button -->
        <button
          class="character-button"
          disabled={!characterSheetFilledIn}
          on:click={createNewCharacter}
        >
          Create Character
        </button>
        {#if errorMessage}
          <p class="error">{errorMessage}</p>
        {/if}
      </div>

      <!-- Right Panel for Selected Options or Descriptions -->
      {#if showRightPanel}
        <div class="view-container right-panel">
          {#if $currentView === "details"}
            <!-- Display Selected Options Summary -->
            <div class="selected-options">
              <h2>Selected Options</h2>
              {#each [{ label: "Culture", value: selectedCultureName }, { label: "Heritage 1", value: selectedHeritage1Name }, { label: "Heritage 2", value: selectedHeritage2Name }, { label: "Training", value: selectedTrainingName }, { label: "Alignment", value: selectedAlignmentName }] as option}
                <div class="option-item {option.value ? '' : 'missing-option'}">
                  <span class="option-label">{option.label}:</span>
                  <span class="option-value">{option.value || "Missing"}</span>
                </div>
              {/each}
            </div>
            <div class="character-details">
              {#if characterName}
                <h4>Name:</h4>
                <span>{characterName}</span>
              {/if}
              {#if characterBackstory}
                <h4>Backstory:</h4>
                <span>{characterBackstory}</span>
              {/if}
              {#if characterDescription}
                <h4>Description:</h4>
                <span>{characterDescription}</span>
              {/if}
              {#if characterTraits}
                <h4>Traits:</h4>
                <span>{characterTraits}</span>
              {/if}
              {#if characterNotes}
                <h4>Notes:</h4>
                <span>{characterNotes}</span>
              {/if}
            </div>
          {:else if $currentView === "origins"}
            <!-- Origins Descriptions -->
            {#each [{ name: selectedCultureName, list: cultureList, id: selectedCulture }, { name: selectedHeritage1Name, list: heritageList, id: selectedHeritage1 }, { name: selectedHeritage2Name, list: heritageList, id: selectedHeritage2 }] as { name, list, id }}
              {#if id}
                <div class="default-info">
                  <h3>{name}</h3>
                  <p>{list.find((item) => item.id === id).description}</p>
                </div>
              {/if}
            {/each}
          {:else if $currentView === "training"}
            <!-- Training Description -->
            {#if selectedTraining}
              <div class="default-info">
                <h3>{selectedTrainingName}</h3>
                <p>
                  {trainingList.find((c) => c.id === selectedTraining)
                    .description}
                </p>
              </div>
            {/if}
            {#if selectedAlignment}
              <div class="default-info">
                <h3>{selectedAlignmentName}</h3>
                <p>
                  {alignmentList.find((c) => c.id === selectedAlignment)
                    .description}
                </p>
              </div>
            {/if}
          {/if}
        </div>
      {/if}
    </div>
  </div>
  {#if showSnackbar}
    <div class="snackbar">Character Successfully Created</div>
  {/if}
</slot>

<style>
  * {
    box-sizing: border-box;
  }

  .page-container {
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    padding: 15px;
    width: 100%;
    min-height: 95vh;
  }

  .character-creation-container {
    display: flex;
    min-height: 100vh;
    width: 100%;
    font-family: "Arial", sans-serif;
    color: #f0f0f0;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #2d2d2d;
    border-right: 1px solid #444;
    color: #ccc;
    width: 250px; /* Fixed width for sidebar */
    height: 100%; /* Makes sidebar full height of container */
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.2);
  }

  .content {
    flex: 1;
    padding: 40px;
    margin-left: 20px;
    overflow-y: auto;
  }

  .sidebar-button {
    padding: 15px;
    margin: 10px 0;
    background-color: #7c005a;
    color: #f5f5f5;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.2s;
  }

  .sidebar-button:hover {
    background-color: #5a0142;
  }

  .activate {
    background-color: #50053c;
  }

  .character-button {
    margin-top: 50px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #4caf50;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  }

  .character-button:hover {
    background-color: #45a049;
  }

  .character-button:disabled {
    background-color: #777;
    cursor: not-allowed;
    opacity: 0.7;
  }

  h2 {
    font-size: 28px;
    margin-bottom: 24px;
    color: #f5e0ff;
  }

  .error {
    color: red;
  }

  p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 16px;
    color: #dcdcdc;
  }

  label {
    display: block;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input,
  textarea {
    width: 300px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    background-color: #333;
    color: #f5f5f5;
  }

  textarea {
    resize: vertical; /* Allow vertical resizing for textareas */
  }

  .input-group {
    margin-bottom: 15px;
  }

  label {
    display: block;
    font-size: 14px;
    color: #f5f5f5;
    margin-bottom: 5px;
  }

  .home-view {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 350px;
    margin: auto;
  }

  h2 {
    font-size: 24px;
    color: #f5f5f5;
    margin-bottom: 20px;
  }

  .default-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
  }

  .default-buttons button {
    padding: 10px;
    background-color: #7c005a;
    color: #f5f5f5;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }

  .default-buttons button.selected {
    background-color: #480135;
    font-weight: bold;
  }

  .default-buttons button:hover {
    background-color: #54013e;
  }

  .default-info {
    margin-top: 20px;
    padding: 24px;
    border-radius: 5px;
    background-color: #3c3c3c;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }

  .selected-options {
    background-color: #f9f9f9;
    padding: 20px;
    padding-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .selected-options h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }

  .option-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #ddd;
  }

  .option-item:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  .option-label {
    font-weight: 600;
    font-size: 16px;
    color: #555;
  }

  .option-value {
    font-weight: 500;
    font-size: 16px;
    color: #333;
  }

  .missing-option {
    border: 2px solid red;
    padding: 5px;
    border-radius: 4px;
  }

  .character-details {
    margin-top: 20px; /* Add space above the section */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #2c2c2c; /* Matches the form style */
    color: #f5f5f5;
  }

  .character-details h4 {
    font-size: 18px;
    margin: 10px 0 5px 0; /* Adds space above and below headers */
    color: #ffd700; /* Highlighted text color */
  }

  .character-details span {
    display: block;
    font-size: 16px;
    margin-bottom: 15px; /* Space between text blocks */
    line-height: 1.5; /* Better readability */
  }

  .right-panel {
    width: 40%; /* Default width */
    padding: 20px; /* Add padding for aesthetics */
    background-color: #2d2d2d62; /* Match sidebar background */
    border-left: 1px solid #44444474; /* Border to separate from content */
    color: #ccc; /* Text color */
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    min-height: 60%;
  }

  .power-circles {
    display: flex;
    margin-bottom: 20px;
  }

  .power-circle {
    width: 20px;
    height: 20px;
    background-color: yellow; /* Yellow color */
    border-radius: 50%; /* Make it circular */
    margin-right: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .ability-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .ability-controls {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .control-button {
    background-color: #7c005a;
    color: #f5f5f5;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    margin: 0 5px;
    font-size: 16px;
  }

  .control-button:hover {
    background-color: #5a0142;
  }

  .ability-circles {
    display: flex;
    margin: 0 10px;
  }

  .ability-circle {
    width: 30px;
    height: 30px;
    background-color: #ccc; /* Inactive circle color */
    border-radius: 50%;
    margin: 0 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }

  .ability-circle.active {
    background-color: #4caf50; /* Active circle color */
  }

  @media screen and (max-width: 768px) {
    /* On smaller screens, stack the elements vertically */
    .page-container {
      flex-direction: column; /* Stack vertically on small screens */
      padding: 1px; /* Adjust padding for smaller screens */
    }

    .character-creation-container {
      flex-direction: column; /* Ensure everything stacks vertically */
    }

    .sidebar {
      width: 100%; /* Sidebar takes full width on mobile */
      order: 1; /* Sidebar will appear above content */
    }

    .view-container {
      width: 100%; /* Main content area also takes full width */
      order: 2; /* Content comes below sidebar */
    }

    /* Adjust the buttons and controls to be more mobile-friendly */
    .sidebar-button {
      width: 100%; /* Make sidebar buttons full-width for better touch targets */
      margin-bottom: 10px;
    }

    .control-button {
      width: 50px; /* Smaller buttons for mobile */
      height: 50px;
      font-size: 1rem;
    }

    .ability-circles,
    .power-circles {
      display: flex;
      justify-content: space-between;
      width: 100%; /* Ensure the circles stretch across the full width */
    }

    .ability-circle,
    .power-circle {
      width: 20px; /* Adjust size of ability circles */
      height: 20px;
    }

    .input-group {
      width: 100%;
      margin-bottom: 15px;
    }

    .input-group input,
    .input-group textarea {
      width: 100%; /* Inputs and textareas take full width */
      padding: 1px;
    }

    /* Adjust the footer and button layout for mobile */
    .character-button {
      width: 100%; /* Make the create character button full width */
      font-size: 1.5rem;
      padding: 15px;
    }

    .selected-options,
    .character-details {
      width: 100%;
      margin-top: 15px;
    }

    .selected-options .option-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .error {
      font-size: 1rem;
      color: #ff4d4d; /* Red for error messages */
    }
  }

  .snackbar {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #4caf50;
    color: white;
    padding: 16px;
    border-radius: 8px;
    font-size: 16px;
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease;
    display: block;
  }

  .snackbar {
    opacity: 1;
  }
</style>
