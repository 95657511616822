import { getValidAccessToken } from "./stores/auth"

export async function fetchReagents() {
  const response = await fetch("/api/reagents");
  return response.json();
}

export async function fetchRecipes() {
  const response = await fetch("/api/recipes");
  return response.json();
}

export async function fetchBaseMaterials() {
  const response = await fetch("/api/materials");
  return response.json();
}

export async function fetchPowerSources() {
  const response = await fetch("/api/powers");
  return response.json();
}

export async function fetchItems() {
  const response = await fetch("/api/items");
  return response.json();
}

export async function fetchUserCharacters() {
  const token = await getValidAccessToken();
  const response = await fetch("/api/characters", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.json();
}

export async function addItem(resources, characterId) {
  try {
    const token = await getValidAccessToken();
    const response = await fetch(
      `/api/characters/${characterId}/add-resources/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ resources })
      }
    );

    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.error || "Failed to add item");
    }
    return result.inventory;
  } catch (error) {
    console.error("Error adding item:", error);
    return null;
  }
}

export async function removeItems(resources, characterId) {
  try {
    const token = await getValidAccessToken();
    const response = await fetch(
      `/api/characters/${characterId}/remove-resources/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ resources }),
      }
    );

    const result = await response.json();
    if (!response.ok) {
      throw new Error(result.error || "Failed to remove items");
    }
    return result.inventory;
  } catch (error) {
    console.error("Error removing items:", error);
    return null;
  }
}



