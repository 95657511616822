<script>
  import { snackbar } from "../stores/snackbar";
</script>

{#if $snackbar.isVisible}
  <div class="snackbar {$snackbar.type} show">{$snackbar.message}</div>
{/if}

<style>
  .snackbar {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 250px;
    max-width: 400px;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    opacity: 0;
    visibility: hidden;
    transition:
      opacity 0.3s ease,
      visibility 0.3s ease;
    z-index: 1000;
  }

  .snackbar.show {
    opacity: 1;
    visibility: visible;
  }

  .success {
    background-color: #4caf50;
  }

  .error {
    background-color: #f44336;
  }
</style>
