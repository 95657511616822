<slot>
  <div class="rules-container">
    <h2 class="section-title">Crafting System Overview</h2>

    <h3 class="section-subtitle">Crafting Components</h3>
    <p class="intro-text">
      Here are the key components involved in crafting a magical item:
    </p>
    <ul class="component-list">
      <li class="component-item">
        <strong>1. Base Item:</strong>
        <p>
          The <strong>first component</strong>. This is the item to be enchanted
          or used as the base of the magical item, such as a sword, shield,
          ring, etc. The base item determines the overall shape and purpose of
          the magic item.
        </p>
      </li>
      <li class="component-item">
        <strong>2. Exotic Ingredient:</strong>
        <p>
          The <strong>second component</strong>. A rare or unusual material that
          significantly influences the magical effect of the item. The exotic
          ingredient aligns with the desired magical effect and makes crafting
          the magic item easier and cheaper. Examples include:
        </p>
        <ul class="nested-list">
          <li>
            <strong>Cinder Crystals</strong>: For fire-based magic (e.g., Flame
            Tongue Sword).
          </li>
          <li>
            <strong>Celestial Steel</strong>: For protection or radiant effects
            (e.g., Radiant Shields).
          </li>
          <li>
            <strong>Other Exotic Ingredients</strong>: These materials will
            define the magical nature of the item, and their use should align
            with the intended effect of the magic item.
          </li>
        </ul>
      </li>
      <li class="component-item">
        <strong>3. Arcanite:</strong>
        <p>
          The <strong>third component</strong>. Arcanite is the conduit that
          translates the power of the exotic ingredient and the inscribed arcane
          instructions through the Weave. Different colors of Arcanite can
          amplify or modifiy the magical effect in different ways, making the
          magic item more efficient or achieving the desired outcome using
          different subsystems, but all arcanite serves this fundamental role of
          conveying the magic to the world.
        </p>
      </li>
      <li class="component-item">
        <strong>4. Energy Source:</strong>
        <p>
          The <strong>fourth component</strong>. This is the spark that
          intiatiates the item’s magical effect(s) and creates the pathway
          through the arcanite to the weave. For most items, this spark should
          align with the exotic ingredient being used as this will increase the
          chances of a successful initiation. External sources of power, such as
          <strong>Divine Blessings</strong>,
          <strong>Dragon Breath</strong>, or other forms of unique energy, can
          be used to ignite the item’s enchantment. Once the initial spark is
          provided, the item can be recharged either by
          <strong>Soul Energy</strong> (from the user, this process requires the
          item to be attuned to the user's unique Soul frequency) or by passively
          gathering energy from the environment.
        </p>
        <p>
          Some magical items may require <strong
            >unique charging mechanisms</strong
          > based on the power source and the type of magic being stored or channeled.
        </p>
      </li>
    </ul>

    <h3 class="section-subtitle">Crafting Cost Breakdown</h3>
    <p>
      The cost of crafting an item is determined by its rarity, material
      requirements, and the type of ingredients and energy used. The following
      is an overview of how crafting costs increase with rarity and material
      complexity as well as the percentage that each component takes up of the
      total cost:
    </p>
    <ul class="left-indent">
      <li>Base Materia: 10%</li>
      <li>Exotic Ingredient: 35%</li>
      <li>Power Source: 35%</li>
      <li>Arcanite: 20%</li>
    </ul>

    <h4 class="pricing-title">Item Rarity Pricing (Market)</h4>
    <table class="pricing-table">
      <thead>
        <tr>
          <th>Item Rarity</th>
          <th>Market Price (Gold)</th>
          <th>Consumable Price (Gold)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Common</td>
          <td>50</td>
          <td>25</td>
        </tr>
        <tr>
          <td>Uncommon</td>
          <td>500</td>
          <td>250</td>
        </tr>
        <tr>
          <td>Rare</td>
          <td>5,000</td>
          <td>2,500</td>
        </tr>
        <tr>
          <td>Very Rare</td>
          <td>50,000</td>
          <td>25,000</td>
        </tr>
        <tr>
          <td>Legendary</td>
          <td>500,000</td>
          <td>250,000</td>
        </tr>
      </tbody>
    </table>
  </div>
</slot>

<style>
  .left-indent {
    margin-left: 1.5rem;
  }

  /* Pricing */
  .pricing-title {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .pricing-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .pricing-table th,
  .pricing-table td {
    padding: 12px 16px;
    text-align: left;
  }

  .pricing-table th {
    background-color: #4caf50;
    color: white;
    font-size: 1.1rem;
  }

  .pricing-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .pricing-table tbody tr:hover {
    background-color: #ddd;
  }

  .pricing-table td {
    font-size: 1rem;
    color: #555;
  }

  .section-title,
  .section-subtitle {
    color: #ffcc70;
  }

  .intro-text {
    color: #e0e0ff;
  }

  .component-list {
    padding-left: 20px;
    list-style-type: disc;
  }

  .component-item {
    margin-bottom: 15px;
  }

  .nested-list {
    padding-left: 20px;
    list-style-type: circle;
    color: #e0e0ff;
  }

  .component-item strong {
    color: #ffcc70;
  }

  .rules-container {
    width: 100%;
    padding: 20px;
    background-color: #3d1752;
    border-radius: 8px;
    color: #ffcc70;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  h2,
  h3 {
    color: #ffcc70;
  }

  @media (max-width: 768px) {
    .pricing-table {
      font-size: 0.9rem;
    }

    .pricing-table th,
    .pricing-table td {
      padding: 8px 12px;
    }
  }
</style>
