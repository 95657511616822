import App from './App.svelte';
import './global.css';

const link = document.createElement('link');
link.rel = 'stylesheet';
link.href = 'https://fonts.googleapis.com/css2?family=Uncial+Antiqua&display=swap';
document.head.appendChild(link);

const app = new App({
  target: document.body,
});

export default app;
