<script>
  // Additional scripting can be added here if needed in the future.
</script>

<div class="disclaimer">
  <p>
    <strong>Disclaimer:</strong> Welcome to our high magic fantasy tabletop RPG!
    We strive to create a fun and inclusive environment for everyone. If you have
    any specific concerns or content worries, please reach out to me on discord (Chris
    McKenzie '@chrisalterradm') directly.
  </p>
</div>

<div class="lore-text">
  <h1>Alterra Session Rules</h1>
  <p>
    The Alterra sessions at Ancient Robot Games (ARG) primarily utilize the D&D
    5e system, incorporating both the 2014 and 2024 rulesets. Alongside these,
    the sessions feature a selection of homebrew rules developed collaboratively
    by the player community. These rules aim to enhance gameplay and ensure an
    enjoyable experience for all participants.
  </p>
  <br />
  <br />
  <p>
    XP Bonus. Characters under level 9 gain the following xp bonus determined by
    their level during the session.
  </p>
  <br />

  <li>Level 8: +25% Bonus (E.g., 1800xp reward would be 2250xp)</li>
  <li>Level 7: +50% Bonus (E.g., 1800xp reward would be 2700xp)</li>
  <li>Level 6: +100% Bonus (E.g., 1800xp reward would be 3600xp)</li>
</div>

<div class="rules-section">
  <h2>Additional Rules</h2>
  <br />
  <h3>1. Character Creation</h3>
  <p>
    All official books and supplements are allowed. Characters start at level 6
    and may begin with one uncommon magic item. There are no restrictions on
    alignment or backstory, except for the following "gold rule":
  </p>
  <ul>
    <li>
      Characters must want to participate in the quest or objective, whether for
      personal, monetary, or narrative reasons.
    </li>
    <li>
      They must cooperate with other players and contribute to the overall
      enjoyment of the table.
    </li>
    <li>
      Disruptive actions (e.g., stealing from players, killing NPCs) are only
      permitted if all players consent. This is a collaborative storytelling
      experience—characters that do not fit the narrative can be replaced by
      those that do.
    </li>
  </ul>
  <br />
  <h3>2. Spellcasting</h3>
  <p>
    All spellcasting in Alterra is channeled through <em>Arcanite</em>,
    crystallized fragments of reality. Starting spellcasting equipment includes
    Arcanite by default. Higher-quality Arcanite enhances the effects of magical
    foci.
    <br />Note: This rule applies only to spells, not abilities derived from
    Psionics or Elemancy.
  </p>
  <br />
  <h3>3. Spell Scrolls</h3>
  <p>Anyone can use spell scrolls, but mechanics vary:</p>
  <ul>
    <li>
      <strong>Casters:</strong> If the spell is on their list and within their casting
      level, no check is required. Otherwise, they must make a spellcasting check
      (DC = 10 + spell level).
    </li>
    <li>
      <strong>Non-Casters:</strong> Use a raw d20 for the check with the same DC
      as above.
    </li>
  </ul>
  Failed checks cause the scroll to crumble. At DM discretion, failed casts may have
  unintended consequences (e.g., wild magic effects).
  <br />
  <br />
  <h3>4. Levelled Spell Casting</h3>
  <p>
    Any rule that involves tracking bonus action / action spell combinations.
    This restriction is ignored in favor of less book keeping.
  </p>
  <br />
  <h3>5. Heavy Weapons for Small Creatures</h3>
  <p>This restriction is ignored in favor of the "rule of cool."</p>
  <br />
  <h3>6. Encumbrance</h3>
  <p>
    Any rule that involves tracking the weight of items or objects. This
    restriction is ignored in favor of less book keeping. DM's discretion for
    what a character can lift, carry, or drag.
  </p>
  <br />
  <h3>7. Short Rest Attunement</h3>
  <p>
    During a 1-hour short rest, characters can swap all attuned items instead of
    the standard rule of attuning/deattuning one item per hour.
  </p>
  <br />
  <h3>8. Manuals (Stat Improvement)</h3>
  <p>
    Each character can only benefit once from a specific manual. Higher-volume
    editions (e.g., "Volume II") offer further improvements.
  </p>
  <br />
  <h3>9. Magic Item Pricing</h3>
  <p>Magic item prices follow a simple scale:</p>
  <ul>
    <li>Common: 100 gp</li>
    <li>Uncommon: 500 gp</li>
    <li>Rare: 5,000 gp</li>
    <li>Very Rare: 50,000 gp</li>
  </ul>
  Consumables cost half-price. Adventurers with a license receive a 20% discount,
  provided they abide by marketplace rules (e.g., no haggling, threats, or disturbances).
  <br />
  <br />
  <h3>10. Potions</h3>
  <p>Potions can be thrown at allies or enemies:</p>
  <ul>
    <li>
      <strong>Willing Targets:</strong> Automatically succeed within 30 ft unless
      obstacles are present.
    </li>
    <li>
      <strong>Unwilling Targets:</strong> Require an improvised attack roll against
      AC. Failure causes the potion to miss or shatter nearby.
    </li>
  </ul>
  At DM discretion, checks may be required for longer distances or environmental
  challenges (e.g., wind, traps).
</div>

<style>
  * {
    box-sizing: border-box;
  }

  .disclaimer {
    padding: 20px;
    background-color: #f4a261;
    color: #000000;
    text-align: center;
    font-weight: bold;
    font-size: 1.1em;
  }

  .lore-text {
    padding: 50px;
    color: white;
    text-align: center;
    background-color: #2b2b2b;
  }

  .rules-section {
    padding: 20px;
    background-color: #1a1a1a;
    color: #ffffff;
    line-height: 1.6;
  }

  h1,
  h2,
  h3 {
    color: #ffcc00;
  }

  ul {
    margin-left: 20px;
  }

  @media (max-width: 768px) {
    .lore-text {
      padding: 20px;
    }

    .rules-section {
      padding: 10px;
    }
  }
</style>
