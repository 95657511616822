<div class="lore-text">
  This file contains all known information on the Red Baron.
</div>

<div class="dossier">
  <div class="dossier-header">
    <h1>Dossier: Red Baron</h1>

    <div class="portrait">
      <img
        src="https://res.cloudinary.com/dw39fffyv/image/upload/v1742167494/placeholder_lzsl1l.gif"
        alt="Red Baron Portrait"
      />
    </div>
  </div>

  <div class="dossier-section">
    <h2>Profile</h2>
    <p>
      <strong>Name:</strong> The Red Baron<br />
      <strong>Age:</strong> 56<br />
      <strong>Appearance:</strong> Morbidly obese, towering stature, red eyes. Known
      for his elaborate, ostentatious clothing that symbolizes his wealth and power.
    </p>
    <p>
      The Red Baron is a figure of excess and indulgence, gluttonous in his
      appetite for power, food, and violence. His cruel and domineering
      personality is matched only by his cunning intellect. He thrives on
      control and intimidation, with a penchant for orchestrating grandiose
      displays of cruelty in the form of bloodsport and combat tournaments. His
      influence extends across vast industries, making him one of the most
      formidable adversaries.
    </p>
  </div>

  <div class="dossier-section">
    <h2>Public Enterprises</h2>
    <p>The Red Baron oversees several high-profile businesses, including:</p>
    <ul>
      <li>
        <strong>Willstone Industries:</strong> A conglomerate specializing in magic
        item manufacturing and magical defenses. This includes Wrener’s Studio, a
        subsidiary focused on refining Pink Arcanite to create charm immunity rings
        and other enchantments.
      </li>
      <li>
        <strong>Iron Vanguard Foundry:</strong> A weapons production firm supplying
        both legitimate forces and rumored illegal buyers, including enemy states,
        and criminal organisations.
      </li>
      <li>
        <strong>The Underdome:</strong> An infamous gladiatorial arena hosting deadly
        competitions that attract spectators and high-stakes gamblers from across
        the land.
      </li>
    </ul>
  </div>

  <div class="dossier-section">
    <h2>Criminal Activities</h2>
    <p>
      While his public image is that of a shrewd businessman, evidence links the
      Red Baron to numerous illegal and unethical actions:
    </p>
    <ul>
      <li>
        Sabotaged Anchorage Station construction by hiring bandits to attack
        supply convoys.
      </li>
      <li>
        Exploited enslaved dragons and wyrms to harvest dragonfruit at secret
        farms, many abandoned during the Ragnar Crusades.
      </li>
      <li>
        Directed thugs to vandalize a Violet Arcanite obelisk grown by
        Fateweaver.
      </li>
      <li>
        Hired assassins to target economic competitors, such as BS Die Right.
      </li>
      <li>
        Weapon factories exploit indentured laborers under horrific conditions.
      </li>
    </ul>
  </div>

  <div class="dossier-section">
    <h2>Current Objectives</h2>
    <p>
      Ongoing investigation suggests the following priority actions to dismantle
      the Red Baron's influence:
    </p>
    <ul>
      <li>
        <strong>Investigate:</strong> Rumors of illegal arms deals with enemy
        states.<br />
      </li>
      <li>
        <strong>Disrupt:</strong> The Red Baron's fighting tournaments, which
        act as recruitment for his private army.<br />
      </li>
      <li>
        <strong>Expose:</strong> The exploitation of workers in his weapon
        factories to turn public opinion against him.<br />
      </li>
    </ul>
  </div>
</div>

<style>
  * {
    box-sizing: border-box;
  }

  .lore-text {
    padding: 30px;
    color: white;
    text-align: center;
    font-family: "Courier New", Courier, monospace;
  }

  .dossier {
    background-color: #111;
    padding: 20px;
    color: #fff;
    font-family: "Courier New", Courier, monospace;
    border: 1px solid #444;
    max-width: 800px;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  }

  .dossier-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #444;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .dossier-header h1 {
    font-size: 1.5em;
    color: #ff4c4c;
  }

  .portrait {
    width: 120px;
    height: 120px;
    border: 2px solid #ff4c4c;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
  }

  .portrait img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform: scale(1.1);
  }

  .dossier-section {
    margin-bottom: 20px;
  }

  .dossier-section h2 {
    color: #ff4c4c;
    font-size: 1.2em;
    border-bottom: 1px solid #444;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  .dossier-section p,
  .dossier-section ul {
    margin: 10px 0;
  }

  .dossier-section ul {
    list-style-type: none;
    padding-left: 0;
  }

  .dossier-section ul li::before {
    content: "• ";
    color: #ff4c4c;
  }

  @media (max-width: 768px) {
    .dossier-header {
      flex-direction: column;
      align-items: center;
    }
    .portrait {
      margin-top: 10px;
    }
  }
</style>
