<script>
  import { onMount } from "svelte";

  let activeTab = "reagents";
  let activeFilterReagents = "crystals";
  let activeFilterRecipes = "alchemy";
  let tooltipContent = { name: "", description: "", image: "" }; // Tooltip content
  let tooltipVisible = false; // Track tooltip visibility
  let tooltipPosition = { top: "0px", left: "0px" }; // Tooltip position

  // Lists
  let reagentsList = [];
  let recipesList = [];

  const craftingTypes = ["alchemy", "artificery", "cooking"];
  const reagentTypes = ["crystals", "ore", "plants"];

  function switchTab(tab) {
    activeTab = tab;
  }

  function setFilterReagents(filter) {
    activeFilterReagents = filter;
  }

  function setFilterRecipes(filter) {
    activeFilterRecipes = filter;
  }

  // Fetch reagents
  async function fetchReagents() {
    const response = await fetch("/api/reagents");
    const data = await response.json();
    reagentsList = data;
  }

  // Fetch recipes
  async function fetchRecipes() {
    const response = await fetch("/api/recipes");
    const data = await response.json();
    recipesList = data;
  }

  onMount(async () => {
    await fetchReagents();
    await fetchRecipes();
  });

  function showTooltip(event, item) {
    tooltipContent = {
      name: item.name,
      description: item.description,
      image: item.image || "/media/images/placeholder.png", // Use placeholder if no image
    };

    const rect = event.currentTarget.getBoundingClientRect();
    tooltipPosition = {
      top: `${rect.bottom + window.scrollY - 50}px`,
      left: `${rect.left + window.scrollX + rect.width / 2 - 200}px`, // Center the tooltip
    };

    tooltipVisible = true;
  }

  function hideTooltip() {
    tooltipVisible = false;
  }

  // Function to handle tooltip mouse enter
  function handleTooltipMouseEnter() {
    tooltipVisible = true;
  }

  // Function to handle tooltip mouse leave
  function handleTooltipMouseLeave() {
    tooltipVisible = false;
  }
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<slot>
  <div class="page-container">
    <div class="page-header">
      <h1>WELCOME TO RAPEL'S ARTIFICERY AND CRAFT STUDIO</h1>
      <h3>May Artos guide your thoughts and hands.</h3>
    </div>

    <div class="character-creation-container">
      <div class="tab-buttons">
        <button
          class={activeTab === "reagents" ? "active" : ""}
          on:click={() => switchTab("reagents")}
        >
          🧪 Reagents
        </button>
        <button
          class={activeTab === "crafting" ? "active" : ""}
          on:click={() => switchTab("crafting")}
        >
          🛠️ Crafting Recipes
        </button>
        <button
          class={activeTab === "rules" ? "active" : ""}
          on:click={() => switchTab("rules")}
        >
          📜 Rules
        </button>
      </div>

      <div class="tab-content">
        {#if activeTab === "reagents"}
          <div class="nav-buttons">
            {#each reagentTypes as type}
              <button
                class:active-filter={type === activeFilterReagents}
                on:click={() => setFilterReagents(type)}>{type}</button
              >
            {/each}
          </div>

          <div class="reagents-container">
            {#each reagentsList.filter((r) => r.type === activeFilterReagents) as reagent}
              <div
                class="reagent-card"
                on:mouseover={(e) => showTooltip(e, reagent)}
                on:mouseleave={hideTooltip}
              >
                <img
                  src={reagent.image
                    ? reagent.image
                    : "/media/images/placeholder.png"}
                  alt={reagent.name}
                  class="reagent-image"
                />
                <span class="reagent-name">{reagent.name}</span>
              </div>
            {/each}
          </div>
        {/if}

        {#if activeTab === "crafting"}
          <div class="nav-buttons">
            {#each craftingTypes as type}
              <button
                class:active-filter={type === activeFilterRecipes}
                on:click={() => setFilterRecipes(type)}>{type}</button
              >
            {/each}
          </div>

          <div class="crafting-recipes-container">
            {#each recipesList.filter((r) => r.type === activeFilterRecipes) as recipe}
              <div
                class="recipe-card"
                on:mouseover={(e) => showTooltip(e, recipe)}
                on:mouseleave={hideTooltip}
              >
                <img
                  src={recipe.image
                    ? recipe.image
                    : "/media/images/placeholder.png"}
                  alt={recipe.name}
                  class="recipe-image"
                />
                <span class="recipe-name">{recipe.name}</span>
              </div>
            {/each}
          </div>
        {/if}

        {#if activeTab === "rules"}
          <div class="rules-container">
            <h2>Crafting Rules</h2>
            <p>Here are the rules for crafting:</p>
            <ul>
              <li>Rule 1: Always use high-quality ingredients.</li>
              <li>Rule 2: Follow the recipe instructions precisely.</li>
              <li>Rule 3: Use the correct crafting tools.</li>
              <li>
                Rule 4: Store reagents properly to maintain their potency.
              </li>
            </ul>
          </div>
        {/if}
      </div>

      {#if tooltipVisible}
        <div
          class="tooltip"
          style="top: {tooltipPosition.top}; left: {tooltipPosition.left};"
          on:mouseenter={handleTooltipMouseEnter}
          on:mouseleave={handleTooltipMouseLeave}
        >
          <h3>{tooltipContent.name}</h3>
          <img src={tooltipContent.image} alt={tooltipContent.name} />
          <p>{tooltipContent.description}</p>
        </div>
      {/if}
    </div>
  </div>
</slot>

<style>
  * {
    box-sizing: border-box;
  }

  .page-container {
    justify-content: center;
    justify-items: center;
    align-items: flex-start;
    padding-top: 80px;
    color: #e0e0ff; /* Light lavender text color for readability */
  }

  .page-header {
    justify-content: center;
    justify-items: center;
    align-items: flex-start;
  }

  .character-creation-container {
    width: 90%;
    font-family: "Arial", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    background-color: #2a123f; /* Dark purple with slight warmth */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  }

  .tab-buttons {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .tab-buttons button {
    background-color: #3d1752; /* Deep purple */
    color: #ffcc70; /* Gold text color for contrast */
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition:
      background-color 0.3s,
      color 0.3s;
    flex: 1;
    text-align: center;
  }

  .tab-buttons button.active {
    background-color: #ffcc70;
    color: #2a123f; /* Dark purple text on active tab */
  }

  .tab-buttons button:hover {
    background-color: #572366; /* Slightly lighter purple on hover */
  }

  .tab-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 40px;
    background-color: #2a123f;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 0 10px 10px 10px;
  }

  .nav-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }

  .nav-buttons button {
    background-color: #3d1752;
    color: #ffcc70;
    border: none;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .nav-buttons button.active-filter {
    background-color: #ffcc70;
    color: #2a123f;
    font-weight: bold;
  }

  .reagents-container,
  .crafting-recipes-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    width: 100%;
    z-index: 0;
  }

  .reagent-card,
  .recipe-card {
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #3d1752;
    border-radius: 8px;
    font-size: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition:
      transform 0.2s,
      box-shadow 0.2s;
  }

  .reagent-card:hover,
  .recipe-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
  }

  .recipe-image,
  .reagent-image {
    width: 60px;
    height: 60px;
    margin-bottom: 5px;
    border-radius: 50%;
    border: 2px solid #ffcc70;
  }

  .reagent-card img,
  .recipe-card img {
    width: 100%;
    height: 100%;
    margin-left: auto;
    border: 4px solid #ffcc70; /* Gold border */
    border-radius: 10px;
    background-color: #2a123f;
    pointer-events: none;
    z-index: 0;
  }

  .recipe-name,
  .reagent-name {
    color: #ffcc70;
    font-weight: bold;
    margin-top: 5px;
  }

  .tooltip {
    position: absolute;
    padding: 15px;
    background-color: #2a123f;
    border: 2px solid #ffcc70;
    border-radius: 8px;
    color: #e0e0ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    z-index: 100;
    text-align: center;
    max-width: 600px;
    word-wrap: break-word;
  }

  .tooltip h3 {
    color: #ffcc70;
  }

  .tooltip img {
    width: 60px;
    height: 60px;
    margin: 10px 0;
    border-radius: 5px;
    border: 2px solid #ffcc70;
  }

  .tooltip p {
    color: #e0e0ff;
  }

  .rules-container {
    width: 100%;
    padding: 20px;
    background-color: #3d1752;
    border-radius: 8px;
    color: #ffcc70;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  h2,
  h3 {
    color: #ffcc70;
  }

  p {
    color: #e0e0ff;
  }
</style>
