<script>
  import Modal from "./Modal.svelte";
  import { onMount } from "svelte";
  import { getValidAccessToken } from "../stores/auth";

  export let show = false;
  export let quest;
  export let onClose;

  const categoryOptions = ["event", "character", "side", "complete"];

  let title = "";
  let sponsor = "";
  let summary = "";
  let reward = "";
  let outcome = "";
  let classified = "";
  let category = "";
  let gameplayTags = [];
  let locationTags = [];
  let characterTags = [];
  let themeTags = [];
  let newGameplayTag = "";
  let newLocationTag = "";
  let newCharacterTag = "";
  let newThemeTag = "";
  let errorMessage = "";

  onMount(() => {
    title = quest.title || "";
    sponsor = quest.sponsor || "";
    summary = quest.summary || "";
    reward = quest.reward || "";
    outcome = quest.outcome || "";
    classified = quest.classified || "";
    category = quest.category || "";
    gameplayTags = quest.gameplay_tags || [];
    locationTags = quest.location_tags || [];
    characterTags = quest.character_tags || [];
    themeTags = quest.theme_tags || [];
  });

  function addTag(type) {
    switch (type) {
      case "gameplay":
        if (newGameplayTag && !gameplayTags.includes(newGameplayTag)) {
          gameplayTags = [...gameplayTags, newGameplayTag];
          newGameplayTag = "";
        }
        break;
      case "location":
        if (newLocationTag && !locationTags.includes(newLocationTag)) {
          locationTags = [...locationTags, newLocationTag];
          newLocationTag = "";
        }
        break;
      case "character":
        if (newCharacterTag && !characterTags.includes(newCharacterTag)) {
          characterTags = [...characterTags, newCharacterTag];
          newCharacterTag = "";
        }
        break;
      case "theme":
        if (newThemeTag && !themeTags.includes(newThemeTag)) {
          themeTags = [...themeTags, newThemeTag];
          newThemeTag = "";
        }
        break;
    }
  }

  function removeTag(type, tag) {
    switch (type) {
      case "gameplay":
        gameplayTags = gameplayTags.filter((t) => t !== tag);
        break;
      case "location":
        locationTags = locationTags.filter((t) => t !== tag);
        break;
      case "character":
        characterTags = characterTags.filter((t) => t !== tag);
        break;
      case "theme":
        themeTags = themeTags.filter((t) => t !== tag);
        break;
    }
  }

  async function handleSubmit() {
    try {
      const token = await getValidAccessToken();
      const response = await fetch(`/api/quests/${quest.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          title,
          sponsor,
          summary,
          reward,
          outcome,
          classified,
          category,
          gameplay_tags: gameplayTags,
          location_tags: locationTags,
          character_tags: characterTags,
          theme_tags: themeTags,
        }),
      });
      if (!response.ok) {
        throw new Error("Failed to update quest");
      }
      onClose();
    } catch (error) {
      errorMessage = error.message;
    }
  }
</script>

<Modal {show} {onClose}>
  <div class="modal-content">
    <h2>Edit Quest</h2>
    {#if errorMessage}
      <p class="error">{errorMessage}</p>
    {/if}
    <div>
      <label for="title">Title</label>
      <input id="title" type="text" bind:value={title} />
    </div>
    <div>
      <label for="sponsor">Sponsor</label>
      <input id="sponsor" type="text" bind:value={sponsor} />
    </div>
    <div>
      <label for="summary">Summary</label>
      <textarea class="extra-height" id="summary" bind:value={summary}
      ></textarea>
    </div>
    <div>
      <label for="reward">Reward</label>
      <input id="reward" type="text" bind:value={reward} />
    </div>
    <div>
      <label for="outcome">Outcome</label>
      <textarea class="extra-height" id="outcome" bind:value={outcome}
      ></textarea>
    </div>
    <div>
      <label for="classified">Classified</label>
      <textarea class="extra-height" id="classified" bind:value={classified}
      ></textarea>
    </div>
    <div>
      <label for="category">Category</label>
      <select id="category" bind:value={category}>
        <option value="" disabled selected>Select a category</option>
        {#each categoryOptions as option}
          <option value={option}>{option}</option>
        {/each}
      </select>
    </div>
    <div>
      <label for="gameplayTags">Gameplay Tags</label>
      <div>
        <input
          id="newGameplayTag"
          type="text"
          bind:value={newGameplayTag}
          placeholder="Add a tag"
        />
        <button type="button" on:click={() => addTag("gameplay")}
          >Add Tag</button
        >
      </div>
      <div>
        {#each gameplayTags as tag}
          <span class="tag">
            {tag}
            <button type="button" on:click={() => removeTag("gameplay", tag)}
              >x</button
            >
          </span>
        {/each}
      </div>
    </div>
    <div>
      <label for="locationTags">Location Tags</label>
      <div>
        <input
          id="newLocationTag"
          type="text"
          bind:value={newLocationTag}
          placeholder="Add a tag"
        />
        <button type="button" on:click={() => addTag("location")}
          >Add Tag</button
        >
      </div>
      <div>
        {#each locationTags as tag}
          <span class="tag">
            {tag}
            <button type="button" on:click={() => removeTag("location", tag)}
              >x</button
            >
          </span>
        {/each}
      </div>
    </div>
    <div>
      <label for="characterTags">Character Tags</label>
      <div>
        <input
          id="newCharacterTag"
          type="text"
          bind:value={newCharacterTag}
          placeholder="Add a tag"
        />
        <button type="button" on:click={() => addTag("character")}
          >Add Tag</button
        >
      </div>
      <div>
        {#each characterTags as tag}
          <span class="tag">
            {tag}
            <button type="button" on:click={() => removeTag("character", tag)}
              >x</button
            >
          </span>
        {/each}
      </div>
    </div>
    <div>
      <label for="themeTags">Theme Tags</label>
      <div>
        <input
          id="newThemeTag"
          type="text"
          bind:value={newThemeTag}
          placeholder="Add a tag"
        />
        <button type="button" on:click={() => addTag("theme")}>Add Tag</button>
      </div>
      <div>
        {#each themeTags as tag}
          <span class="tag">
            {tag}
            <button type="button" on:click={() => removeTag("theme", tag)}
              >x</button
            >
          </span>
        {/each}
      </div>
    </div>
    <div class="buttons">
      <button on:click={onClose}>Cancel</button>
      <button on:click={handleSubmit}>Submit</button>
    </div>
  </div>
</Modal>

<style>
  label {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }

  input,
  textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .error {
    color: red;
  }

  .extra-height {
    min-height: 80px;
  }

  .tag {
    display: inline-block;
    background-color: #e0e0e0;
    padding: 5px;
    margin: 5px;
    border-radius: 3px;
  }

  .tag button {
    background: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
  }

  .modal-content {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    max-width: 600px;
    margin: auto;
  }

  @media (max-width: 767px) {
    .modal-content {
      max-width: 100%;
      height: calc(100% - 20px); /* Make space for bottom margin */
      overflow-y: auto;
      padding: 5px;
      box-sizing: border-box;
    }
  }
</style>
